import React from "react";
import ConfirmRegisteredSignatureView from "./view";
import { useNavigate } from "react-router-dom";

const ConfirmRegisteredSignatureDocPlace = (props) => {
  const navigate = useNavigate();
  let typePlan = "";
  if (JSON.parse(localStorage.getItem("docplace"))) {
    typePlan = JSON.parse(localStorage.getItem("docplace")).plan.id;
  }
  const close = () => {
    if(props.close){
        navigate("/explorer");
    }

    if (typePlan === "7" || typePlan === "8") {
      navigate("/eSignatureInbox");
    } else {
      navigate("/explorer");
    }
    //props.close();
  };

  const registerSignature = () => {
    navigate("/esignatureRegister");
  };

  return (
    <ConfirmRegisteredSignatureView
      open={props.show}
      close={close}
      ok={registerSignature}
    />
  );
};

export default ConfirmRegisteredSignatureDocPlace;
