import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import CreateTemplateDialog from "../CreateTemplateDialog/CreateTemplateDialog";
import ProcessingDialog from "../ProcessingDialog/ProcessingDialog";
import { uploadTemplateV2File } from "../../../../services/templateV2Service";
import {
	setSelectedFile,
	setUploadResponse,
} from "../../../../store/file/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	appBar: {
		// Ajustes adicionales si lo deseas
	},
	toolbar: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-between",
		padding: 0,
		marginTop: "24px",
	},
	leftSection: {
		display: "flex",
		alignItems: "center",
	},
	tabsContainer: {
		display: "flex",
		alignItems: "center",
		backgroundColor: "#fff",
		borderRadius: "10px",
	},
	title: {
		marginRight: theme.spacing(2),
	},
	tabs: {
		// marginRight: theme.spacing(4),
		// color: '#006ac6',
	},
	tab: {
		fontSize: "14px",
		textTransform: "none",
		fontWeight: 500,
	},
	rightSection: {
		display: "flex",
		alignItems: "center",
	},
	searchInput: {
		marginLeft: theme.spacing(2),
		backgroundColor: "#fff",
		width: "100%",
		height: "36px",
		textTransform: "none",
	},
	searchButton: {
		marginLeft: 4,
		textTransform: "none",
	},
	title: {
		color: "#006ac6",
		fontSize: "32px",
	},
	newTemplateButton: {
		marginLeft: "20px",
		textTransform: "none",
	},
}));

export default function TemplateHeader({ tabValue, onTabChange }) {
	const classes = useStyles();
	const [openDialog, setOpenDialog] = useState(false);
	const [showCreateDialog, setShowCreateDialog] = useState(false);
	const [showProcessing, setShowProcessing] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleChangeTab = (event, newValue) => {
		onTabChange(newValue);
	};

	const handleOpenDialog = () => {
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleUpload = async (params) => {
		setShowCreateDialog(false);
		setShowProcessing(true);

		console.log("Uploading...", params);
		try {
			const response = await uploadTemplateV2File(params.file);
			if (response?.data?.data) {
				dispatch(setSelectedFile(params.file));
				console.log("Response", response.data.data);
				dispatch(setUploadResponse(response.data.data));
			}

			navigateToTemplateBuilder();
		} catch (err) {
			console.error(err);
		} finally {
			setShowProcessing(false);
		}
	};

	const navigateToTemplateBuilder = () => {
		console.log("Navigate to Template Builder");
		navigate("/templates-v2/template-builder");
	};

	return (
		<div>
			<h2 className={classes.title}>DocPlace Forms</h2>
			<header position="static" className={classes.appBar}>
				<div className={classes.toolbar}>
					<div className={classes.leftSection}>
						<div className={classes.tabsContainer}>
							<Tabs
								value={tabValue}
								onChange={handleChangeTab}
								className={classes.tabs}
								indicatorColor="primary"
								textColor="primary"
							>
								<Tab label="My Templates" className={classes.tab} />
								<Tab label="Completed" className={classes.tab} />
								<Tab label="Assigned" className={classes.tab} />
								<Tab label="Archive" className={classes.tab} />
							</Tabs>
						</div>

						<Button
							variant="contained"
							color="primary"
							className={classes.newTemplateButton}
							onClick={handleOpenDialog}
						>
							New Template
						</Button>
					</div>

					<div className={classes.rightSection}>
						<TextField
							variant="outlined"
							size="small"
							placeholder="Search"
							className={classes.searchInput}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon color="primary" />
									</InputAdornment>
								),
							}}
						/>
						<Button
							variant="contained"
							color="primary"
							className={classes.searchButton}
						>
							Search
						</Button>
					</div>
				</div>
			</header>

			<CreateTemplateDialog
				open={openDialog}
				onClose={handleCloseDialog}
				onUpload={handleUpload}
			/>
			<ProcessingDialog open={showProcessing} />
		</div>
	);
}
