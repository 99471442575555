import styled from "styled-components";

export const DPwrapper = styled.section`
  width: 100%;
  height: 100%;
  overflow-x: auto;
`;

export const DPheader = styled.article`
  width: 100%;
  height: 40px;
  margin-top: 10px;
`;

export const DPbody = styled.article`
  width: 100%;
  height: calc(100vh - 218px);
  background-color: #fff;
  border-radius: 5px;
  margin-top: 10px;
`;

export const DPbodyList = styled.article`
  width: 100%;
  height: calc(100vh - 253px);
  overflow-y: auto;
  padding: 0px 16px 0px 15px;
`;

export const DPfooter = styled.article`
  width: 100%;
  height: 30px;
`;

export const DPTitle = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: #1976d2;
  margin: 0;
  padding: 0;
`;

export const DPHeaderFilter = styled.div`
  width: 150px;
  height: 37px;
  display: inline-block;
  float: left;
  
`;

export const DPWrapperReWrapper = styled.section`
  width: 100%;
  height: 828px;
  display: flex;
  margin-top: 20px;
`;

export const DPWrapperReWrapperViewer = styled.article`
  flex: 1;
  min-width: 60%;
  height: calc(100vh - 273px);
  display: inline-block;
  float: left;
  background-color: #fff;
`;

export const DPWrapperReWrapperViewerHeader = styled.div`
  min-width: 762px
  width: 40%;
  height: 32px;
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 5px 5px 0 0;
`;

export const DPWrapperReWrapperViewerPages = styled.div`
  min-width: 150px;
  height: 100%;
  background-color: #fff;
  float: left;
`;
export const DPWrapperReWrapperViewerBody = styled.div`
  min-width: 612px;
  height: 100%;
  background-color: #d9d9d9;
  float: right;
`;

export const DPWrapperReWrapperStepper = styled.article`
  flex: 0.3;
  min-width: 538px;
  height: 804px;
  display: inline-block;
  float: right;
  background-color: #fff;
  padding: 16px;
  margin-left: 20px;
  border-radius: 7px;
  position: relative;
`;

export const DPWrapperReWrapperStepperTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: #1e1e1e;
`;

export const DPWrapperReWrapperStepperStep = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #00c65e;
  position: absolute;
  right: 18px;
`;

export const DPWrapperReWrapperStepperDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #757575;
  margin-top: 5px;
  border-bottom: 1px solid #00c65e;
  padding-bottom: 5px;
`;

export const DPWrapperReWrapperSignBackButton = styled.div`
    float: left;
    width: 60px;
    position: relative;
    display: inline-block;
    background-color: #efefef;
    height: 23px;
    padding: 5px;
    border-radius: 4px;
    margin-right: 3px;
    border: 1px solid #d9d9d9;
    margin-top: 3px;
    color: #1976d2;
    cursor: pointer;
    position: relative;
    font-weight: 600;
`;

export const DPWrapperReWrapperSignButton = styled.div`
    float: right;
    width: 60px;
    position: relative;
    display: inline-block;
    background-color: #efefef;
    height: 23px;
    padding: 5px;
    border-radius: 4px;
    margin-right: 3px;
    border: 1px solid #d9d9d9;
    margin-top: 3px;
    color: #1976d2;
    cursor: pointer;
    font-weight: 600;
`;

export const DPWrapperReWrapperSignButtonImg = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
`;

export const DPWrapperReWrapperSignButtonText = styled.div`
    position: absolute;
    top: 2px;
    left: 22px;
    font-size: 12px;
`;


