import React, { useEffect, useState } from "react";
import { useStyles } from "../../../PlaceSignature/style.js";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  DPWrapperReWrapper,
  DPWrapperReWrapperViewer,
  DPWrapperReWrapperSignButton,
  DPWrapperReWrapperViewerHeader,
  DPWrapperReWrapperSignButtonImg,
  DPWrapperReWrapperSignButtonText,
  DPWrapperReWrapperSignBackButton,
} from "../../style/docplace_sign_module.styles";
import NbStampSignViewerViewBTN from './btn';
import StampSignature  from "../../../StampSignature/labeling";
import eSign from "../../../../assets/svg/Docplace Frame (1).svg";
import eSignD from "../../../../assets/svg/Icon Container Figma.svg";

interface ViewProps {
  id?: string;
  activeSign?:boolean;
  callSign?:boolean;
  back: (value: boolean) => void;
  setCallSign: (value: boolean) => void;
  setActiveSign?:(value: boolean) => void;
}

const NbStampSignViewerView: React.FC<ViewProps> = ({ ...props }) => {
  const b = () =>{
    props.back(true);
  }
  return (
    <DPWrapperReWrapper>
      <DPWrapperReWrapperViewer>
        <DPWrapperReWrapperViewerHeader>
          <DPWrapperReWrapperSignBackButton 
              onClick={b}
                style={{marginLeft: '5px'}} 
              >
                  <ArrowBackIosNewIcon style={{color: 'rgb(2 1 1)', fontSize: '14px', margin: '-1px 0 0 0', position: 'absolute'}} />     
                  <DPWrapperReWrapperSignButtonText  style={{color: 'rgb(2 1 1)'}}>Back</DPWrapperReWrapperSignButtonText>
              </DPWrapperReWrapperSignBackButton>
            
          
          <DPWrapperReWrapperSignButton 
            onClick={() => { props.setCallSign(true) } }
            style={{float:"right", cursor: props.activeSign ? 'pointer' : 'no-drop'}}>
             <DPWrapperReWrapperSignButtonImg src={props.activeSign ? eSign : eSignD} alt="sign"/>
             <DPWrapperReWrapperSignButtonText style={{color: props.activeSign ? '#1976d2' : '#a9a9a9'}}>Sign</DPWrapperReWrapperSignButtonText>
          </DPWrapperReWrapperSignButton>
        </DPWrapperReWrapperViewerHeader>
        <StampSignature setActiveSign={props.setActiveSign} activeSign={props.callSign} setCallSign={props.setCallSign} callSign={props.callSign} id={props.id} />
      </DPWrapperReWrapperViewer>
    </DPWrapperReWrapper>
  );
};

export default NbStampSignViewerView;
