export const FOLDER_NEW_POST = "FOLDER_NEW_POST"
export const FOLDER_NEW_POST_SUCCESS = "FOLDER_NEW_POST_SUCCESS"

export const DOC_MOVE_POST = "DOC_MOVE_POST"
export const DOC_MOVE_POST_SUCCESS = "DOC_MOVE_POST_SUCCESS"

export const DOC_SHARE_POST = "DOC_SHARE_POST"
export const DOC_SHARE_POST_SUCCESS = "DOC_SHARE_POST_SUCCESS"

export const FOLDER_SHARE_POST = "FOLDER_SHARE_POST"
export const FOLDER_SHARE_POST_SUCCESS = "FOLDER_SHARE_POST_SUCCESS"

export const DOC_RENAME_POST = "DOC_RENAME_POST"
export const DOC_RENAME_POST_SUCCESS = "DOC_RENAME_POST_SUCCESS"

export const DOC_REPROCESS_POST = "DOC_REPROCESS_POST"
export const DOC_REPROCESS_POST_SUCCESS = "DOC_REPROCESS_POST_SUCCESS"

export const DOC_RECOVER_POST = "DOC_RECOVER_POST"
export const DOC_RECOVER_POST_SUCCESS = "DOC_RECOVER_POST_SUCCESS"

export const DOC_DELETE_POST = "DOC_DELETE_POST"
export const DOC_DELETE_POST_SUCCESS = "DOC_DELETE_POST_SUCCESS"

export const DOC_ATTRIBUTE_PUT = "DOC_ATTRIBUTE_PUT"
export const DOC_ATTRIBUTE_PUT_SUCCESS = "DOC_ATTRIBUTE_PUT_SUCCESS"

export const DOC_COMMENT_POST = "DOC_COMMENT_POST"
export const DOC_COMMENT_POST_SUCCESS = "DOC_COMMENT_POST_SUCCESS"

export const DOC_DOWNLOAD_POST = "DOC_DOWNLOAD_POST"
export const DOC_DOWNLOAD_POST_SUCCESS = "DOC_DOWNLOAD_POST_SUCCESS"

export const API_ERROR_DOC_DOWNLOAD = "API_ERROR_DOC_DOWNLOAD"
export const API_ERROR_DOC_COMMENT = "API_ERROR_DOC_COMMENT"
export const API_ERROR_DOC_ATTRIBUTE = "API_ERROR_DOC_ATTRIBUTE"
export const API_ERROR_NEW_FOLDER = "API_ERROR_NEW_FOLDER"
export const API_ERROR_DOC_MOVE = "API_ERROR_DOC_MOVE"
export const API_ERROR_DOC_SHARE = "API_ERROR_DOC_SHARE"
export const API_ERROR_FOLDER_SHARE = "API_ERROR_FOLDER_SHARE"

export const API_ERROR_DOC_RENAME = "API_ERROR_DOC_RENAME"
export const API_ERROR_DOC_REPROCESS = "API_ERROR_DOC_REPROCESS"
export const API_ERROR_DOC_RECOVER = "API_ERROR_DOC_RECOVER"
export const API_ERROR_DOC_DELETE = "API_ERROR_DOC_DELETE"

export const EMPTY_TRASH_BIN = "EMPTY_TRASH_BIN"  //DOC-1441
export const EMPTY_TRASH_BIN_SUCCESS = "EMPTY_TRASH_BIN_SUCCESS"
export const API_ERROR_EMPTY_TRASH_BIN = "API_ERROR_EMPTY_TRASH_BIN"

export const FOLDER_DOWNLOAD_ZIP_POST = "FOLDER_DOWNLOAD_ZIP_POST"
export const FOLDER_DOWNLOAD_ZIP_POST_SUCCESS = "FOLDER_DOWNLOAD_ZIP_POST_SUCCESS"
export const FOLDER_DOWNLOAD_ZIP_POST_ERROR = "FOLDER_DOWNLOAD_ZIP_POST_ERROR"
export const FOLDER_DOWNLOAD_ZIP_POST_CLEAR_DATA = "FOLDER_DOWNLOAD_ZIP_POST_CLEAR_DATA"