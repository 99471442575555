import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  WrapperTree,
  TreeUl,
  TreeLi,
  TreeLiText,
  WrapperBackTree,
  WrapperMenuOptionTreeTitleFile,
} from "./style/style";
import "../../Explorer/explorer-style.scss";
import folderClose from "../../../assets/folder@2x.png";
import folderIconTree from "../../../assets/svg/folderIconTree.svg";
import fileIconTree from "../../../assets/svg/fileIconTree.svg";
import arrowClose from "../../../assets/chevron-right-02@2x.png";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { de } from "date-fns/locale";

const mapTree = [];

const TreeItems = (props) => {
  const [listItems, setListItems] = useState(props.tree || []);
  const [item, setItem] = useState({ id: "", name: "" });
  const [mapExplorer, setMapExplorer] = useState([]);
  const [mapExplorerBack, setMapExplorerBack] = useState([]);

  useEffect(() => {
    setListItems(props.tree);
  }, [props.tree]);

  const setChild = (children) => {
    return children.map((item, idx) => {
      if (item.children?.length > 0) {
        setChild(item.children);
      }

      return (
        <TreeUl key={idx}>
          <TreeLi className={item.open ? "openFolder" : "closeFolder"}>
            <span
              className={item.id === item.id ? "activeTree" : ""}
              onClick={() => openChild(item.id, item.name, item.type)}
            >
              <img src={arrowClose} className="imgArrowTree" alt="" />
              <img src={folderClose} className="imgFolderTree" alt="" />
              <TreeLiText>{item.name}</TreeLiText>
            </span>
            {item.children && setChild(item.children)}
          </TreeLi>
        </TreeUl>
      );
    });
  };

  const openChild = (id, name, type) => {
    if (id.folder) {
      props.click({
        label: id.name,
        value: id.id,
        parent: id.parent,
        type: id.type,
      });
    } else {
      props.click({ label: id.name, value: id.id, type: id.type });
    }

    setMap(props.tree, id);
    setItem({ id, name });
    setMapExplorerBack(mapExplorer);
  };

  const backTree = (value) => {
    if (value?.length > 0) {
      props.backTree({
        label: value[0].name,
        value: value[0].parent?.id || "",
      });
    } else {
      props.backTree({
        label: props.treePrevius[props.treePrevius.length - 1].name,
        value: props.treePrevius[props.treePrevius.length - 1].id,
      });
    }
  };

  const setMap = (tree, id) => {
    tree.forEach((item) => {
      if (item.id === id) {
        mapTree.push({ id: item.id, name: item.name });
      }
    });
  };

  return (
    <WrapperTree>
      {props.treePrevius.length > 0 && props.treePrevius[0].name && (
        <WrapperBackTree onClick={() => backTree(props.tree)}>
          <ArrowUpwardIcon
            style={{ float: "left", margin: "-2px 6px 0px 8px" }}
          />
          Back _{" "}
          <span style={{ fontWeight: "900" }}>
            {props.treePrevius[props.treePrevius.length - 1].name}
          </span>
        </WrapperBackTree>
      )}

      {props.tree && props.tree.length > 0 && (
        <TreeUl>
          {props.tree.map((item, idx) => (
            <React.Fragment key={idx}>
              {props.hideTrash && item.nameLower === "trash" ? null : (
                <TreeLi onClick={() => openChild(item)}>
                  {item.type === "Folder" ? (
                    <div>
                      <ArrowForwardIosIcon className="imgArrowTree" />
                      <img
                        src={folderIconTree}
                        className="imgFolderTree"
                        alt=""
                      />
                      <TreeLiText>{item.name}</TreeLiText>
                    </div>
                  ) : (
                    <div>
                      <img
                        style={{
                          marginLeft: "0px",
                          paddingTop: "0px",
                          marginTop: "5px",
                        }}
                        src={fileIconTree}
                        className="imgFolderTree"
                        alt=""
                      />
                      <WrapperMenuOptionTreeTitleFile>
                        {item.name}
                      </WrapperMenuOptionTreeTitleFile>
                    </div>
                  )}
                </TreeLi>
              )}
            </React.Fragment>
          ))}
        </TreeUl>
      )}
    </WrapperTree>
  );
};

TreeItems.propTypes = {
  click: PropTypes.func.isRequired,
  backTree: PropTypes.func.isRequired,
  tree: PropTypes.array.isRequired,
  treePrevius: PropTypes.array.isRequired,
  hideTrash: PropTypes.bool,
};

export default TreeItems;
/*
class TreeItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listItems: this.props.tree,
      item: "",
      click: props.click || (() => {}),
      mapExplorer: [],
      mapExplorerBack: [],
    };
  }

  componentDidMount() {
    console.log("DATA QE RECOP", this.props.tree);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  setChild = (children) => {
    return children.map((item, idx) => {
      if (item.children.length > 0) {
        this.setChild(item.children);
      }

      return (
        <TreeUl key={idx}>
          <TreeLi
            className={item.open === true ? "openFolder" : "closeFolder"}
            key={idx}
          >
            <span
              className={item.id === this.state.item.id ? "activeTree" : ""}
              onClick={this.openChild.bind(this, item.id, item.name)}
            >
              <img src={arrowClose} className="imgArrowTree" alt="" />
              <img src={folderClose} className="imgFolderTree" alt="" />
              <TreeLiText>{item.name}</TreeLiText>
            </span>

            {item.children ? this.setChild(item.children) : null}
          </TreeLi>
        </TreeUl>
      );
    });
  };

  openChild = (id, name) => {
    if (id.folder) {
      this.props.click({ label: id.name, value: id.id, parent: id.parent });
    } else {
      this.props.click({ label: name, value: id });
    }

    this.setMap(this.props.tree, id);
    this.setState({
      listItems: this.props.tree,
      item: { id: id, name: name },
      mapExplorerBack: this.state.mapExplorer,
    });
  };

  backTree = (value) => {
    if (value?.length > 0) {
      this.props.backTree({
        label: value[0].name,
        value: value[0].parent ? value[0].parent.id : "",
      });
    } else {
      this.props.backTree({
        label: this.props.treePrevius[this.props.treePrevius.length - 1].name,
        value: this.props.treePrevius[this.props.treePrevius.length - 1].id,
      });
    }
  };

  recursiveTree = (tree, id) => {
    tree.map((item, idx) => {
      if (item.children.length > 0) {
        this.recursiveTree(item.children, id);
      }
      if (item.id === id) {
        item.open = !item.open;
      }
    });
  };

  setMap = (tree, id) => {
    tree.map((item, idx) => {
      if (item.id === id) {
        mapTree.push({ id: item.id, name: item.name });
      }
    });
  };

  render() {
    return (
      <WrapperTree>
        {this.props.treePrevius.length > 0 && this.props.treePrevius[0].name && (
          <WrapperBackTree onClick={this.backTree.bind(this, this.props.tree)}>
            <ArrowUpwardIcon
              style={{ float: "left", margin: "-2px 6px 0px 8px" }}
            />
            Back _{" "}
            <span style={{ fontWeight: "900" }}>
              {" "}
              {
                this.props.treePrevius[this.props.treePrevius.length - 1].name
              }{" "}
            </span>
          </WrapperBackTree>
        )}

        {this.props.tree && this.props.tree.length > 0 && (
          <TreeUl>
            {this.props.tree.map((item, idx) => {
              return (
                <>
                  {this.props.hideTrash === true &&
                  item.nameLower === "trash" ? null : (
                    <TreeLi onClick={this.openChild.bind(this, item)} key={idx}>
                      {console.log("ITEM", item)}
                      {item.type === "Folder" ? (
                        <div>
                          <ArrowForwardIosIcon className="imgArrowTree" />
                          <img
                            src={folderIconTree}
                            className="imgFolderTree"
                            alt=""
                          />
                          <TreeLiText>{item.name}</TreeLiText>
                        </div>
                      ) : (
                        <div>
                          <img
                            style={{ marginLeft: "-2px" }}
                            src={fileIconTree}
                            className="imgFolderTree"
                            alt=""
                          />
                          <WrapperMenuOptionTreeTitleFile>
                            {item.name}
                          </WrapperMenuOptionTreeTitleFile>
                        </div>
                      )}
                    </TreeLi>
                  )}
                </>
              );
            })}
          </TreeUl>
        )}
      </WrapperTree>
    );
  }
}

export default TreeItems;
*/
