import {
    SET_SELECTED_FILE,
    UPLOAD_FILE_REQUEST,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_FAILURE,
    SET_UPLOAD_RESPONSE,
  } from "./actionTypes";
  
  const initialState = {
    selectedFile: null,
    uploadResponse: null,
    uploading: false,
    error: null,
  };
  
  const fileReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_SELECTED_FILE:
        return {
          ...state,
          selectedFile: action.payload,
        };
      case UPLOAD_FILE_REQUEST:
        return {
          ...state,
          uploading: true,
          error: null,
        };
      case UPLOAD_FILE_SUCCESS:
        return {
          ...state,
          uploading: false,
          uploadResponse: action.payload,
        };
      case UPLOAD_FILE_FAILURE:
        return {
          ...state,
          uploading: false,
          error: action.payload,
        };
        case SET_UPLOAD_RESPONSE:
          return {
            ...state,
            uploadResponse: action.payload,
          };
      default:
        return state;
    }
  };
  
  export default fileReducer;