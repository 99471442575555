import {
  API_ERROR_DOC_ATTRIBUTE,
  API_ERROR_DOC_COMMENT,
  API_ERROR_DOC_DELETE,
  API_ERROR_DOC_DOWNLOAD,
  API_ERROR_DOC_MOVE,
  API_ERROR_DOC_RECOVER,
  API_ERROR_DOC_RENAME,
  API_ERROR_DOC_REPROCESS,
  API_ERROR_DOC_SHARE,
  API_ERROR_EMPTY_TRASH_BIN,
  API_ERROR_NEW_FOLDER,
  API_ERROR_FOLDER_SHARE,
  DOC_ATTRIBUTE_PUT,
  DOC_ATTRIBUTE_PUT_SUCCESS,
  DOC_COMMENT_POST,
  DOC_COMMENT_POST_SUCCESS,
  DOC_DELETE_POST,
  DOC_DELETE_POST_SUCCESS,
  DOC_DOWNLOAD_POST,
  DOC_DOWNLOAD_POST_SUCCESS,
  DOC_MOVE_POST,
  DOC_MOVE_POST_SUCCESS,
  DOC_RECOVER_POST,
  DOC_RECOVER_POST_SUCCESS,
  DOC_RENAME_POST,
  DOC_RENAME_POST_SUCCESS,
  DOC_REPROCESS_POST,
  DOC_REPROCESS_POST_SUCCESS,
  DOC_SHARE_POST,
  DOC_SHARE_POST_SUCCESS,
  FOLDER_SHARE_POST,
  FOLDER_SHARE_POST_SUCCESS,
  EMPTY_TRASH_BIN,
  EMPTY_TRASH_BIN_SUCCESS,
  FOLDER_DOWNLOAD_ZIP_POST,
  FOLDER_DOWNLOAD_ZIP_POST_CLEAR_DATA,
  FOLDER_DOWNLOAD_ZIP_POST_ERROR,
  FOLDER_DOWNLOAD_ZIP_POST_SUCCESS,
  FOLDER_NEW_POST,
  FOLDER_NEW_POST_SUCCESS,
} from "./actionTypes"

export const postDocComment = (comment, navigate) => {
  return {
    type: DOC_COMMENT_POST,
    payload: { comment, navigate },
  }
}

export const postDocDownload = (download, navigate) => {
  return {
    type: DOC_DOWNLOAD_POST,
    payload: { download, navigate },
  }
}

export const putDocAttribute = (attribute, navigate) => {
  return {
    type: DOC_ATTRIBUTE_PUT,
    payload: { attribute, navigate },
  }
}

export const postNewFolder = (newFolder, navigate) => {
  return {
    type: FOLDER_NEW_POST,
    payload: { newFolder, navigate },
  }
}

export const postMoveDoc = (moveDoc, navigate) => {
  return {
    type: DOC_MOVE_POST,
    payload: { moveDoc, navigate },
  }
}

export const postShareDoc = (shareDoc, navigate) => {
  return {
    type: DOC_SHARE_POST,
    payload: { shareDoc, navigate },
  }
}

export const postShareFolder = (shareDoc, navigate) => {
  return {
    type: FOLDER_SHARE_POST,
    payload: { shareDoc, navigate },
  }
}


export const postRenameDoc = (renameDoc, navigate) => {
  return {
    type: DOC_RENAME_POST,
    payload: { renameDoc, navigate },
  }
}
export const postReprocessDoc = (reprocessDoc, navigate) => {
  return {
    type: DOC_REPROCESS_POST,
    payload: { reprocessDoc, navigate },
  }
}
export const postRecoverDoc = (recoverDoc, navigate) => {
  return {
    type: DOC_RECOVER_POST,
    payload: {recoverDoc, navigate},
  }
}
export const postDeleteDoc = (deleteDoc, navigate) => {
  return {
    type: DOC_DELETE_POST,
    payload: { deleteDoc, navigate },
  }
}

export const postDocCommentSuccess = comment => {
  return {
    type: DOC_COMMENT_POST_SUCCESS,
    payload: comment,
  }
}

export const postDocDownloadSuccess = download => {
  return {
    type: DOC_DOWNLOAD_POST_SUCCESS,
    payload: download,
  }
}

export const putDocAttributeSuccess = attribute => {
  return {
    type: DOC_ATTRIBUTE_PUT_SUCCESS,
    payload: attribute,
  }
}


export const postNewFolderSuccess = newFolder => {
  return {
    type: FOLDER_NEW_POST_SUCCESS,
    payload: newFolder,
  }
}


export const postMoveDocSuccess = moveDoc => {
  return {
    type: DOC_MOVE_POST_SUCCESS,
    payload: moveDoc,
  }
}

export const postShareDocSuccess = shareDoc => {
  return {
    type: DOC_SHARE_POST_SUCCESS,
    payload: shareDoc,
  }
}
export const postShareFolderSuccess = shareDoc => {
  return {
    type: FOLDER_SHARE_POST_SUCCESS,
    payload: shareDoc,
  }
}

export const postRenameDocSuccess = renameDoc => {
  return {
    type: DOC_RENAME_POST_SUCCESS,
    payload: renameDoc,
  }
}

export const postReprocessDocSuccess = reprocessDoc => {
  return {
    type: DOC_REPROCESS_POST_SUCCESS,
    payload: reprocessDoc,
  }
}
export const postRecoverDocSuccess = recoverDoc => {
  return {
    type: DOC_RECOVER_POST_SUCCESS,
    payload: recoverDoc,
  }
}
export const postDeleteDocSuccess = deleteDoc => {
  return {
    type: DOC_DELETE_POST_SUCCESS,
    payload: deleteDoc,
  }
}

export const apiErrorDocComment = error => {
  return {
    type: API_ERROR_DOC_COMMENT,
    payload: error,
  }
}

export const apiErrorDocDownload = error => {
  return {
    type: API_ERROR_DOC_DOWNLOAD,
    payload: error,
  }
}


export const apiErrorDocAttribute = error => {
  return {
    type: API_ERROR_DOC_ATTRIBUTE,
    payload: error,
  }
}

export const apiErrorNewFolder = error => {
  return {
    type: API_ERROR_NEW_FOLDER,
    payload: error,
  }
}

export const apiErrorMoveDoc = error => {
  return {
    type: API_ERROR_DOC_MOVE,
    payload: error,
  }
}

export const apiErrorShareDoc = error => {
  return {
    type: API_ERROR_DOC_SHARE,
    payload: error,
  }
}
export const apiErrorShareFolder = error => {
  return {
    type: API_ERROR_FOLDER_SHARE,
    payload: error,
  }
}
export const apiErrorRenameDoc = error => {
  return {
    type: API_ERROR_DOC_RENAME,
    payload: error,
  }
}
export const apiErrorReprocessDoc = error => {
  return {
    type: API_ERROR_DOC_REPROCESS,
    payload: error,
  }
}
export const apiErrorRecoverDoc = error => {
  return {
    type: API_ERROR_DOC_RECOVER,
    payload: error,
  }
}


export const apiErrorDeleteDoc = error => {
  return {
    type: API_ERROR_DOC_DELETE,
    payload: error,
  }
}
export const postEmptyTrashBin = (navigate) => {  //DOC-1441
  return {
    type: EMPTY_TRASH_BIN,
    payload: { navigate },
  }
}

export const postEmptyTrashBinSuccess = emptyTrash => {
  return {
    type: EMPTY_TRASH_BIN_SUCCESS,
    payload: emptyTrash,
  }
}
export const apiErrorEmptyTrashBin = error => {
  return {
    type: API_ERROR_EMPTY_TRASH_BIN,
    payload: error,
  }
}

export const postFolderDownloadZip = (name, data) => {
  return {
    type: FOLDER_DOWNLOAD_ZIP_POST,
    payload: { name, data },
  }
}

export const postFolderDownloadZipSuccess = download => {
  return {
    type: FOLDER_DOWNLOAD_ZIP_POST_SUCCESS,
    payload: download,
  }
}

export const folderDownloadZipPostError = error => {
  return {
    type: FOLDER_DOWNLOAD_ZIP_POST_ERROR,
    payload: error,
  }
}

export const folderDownloadZipPostClearData = () => {
  return {
    type: FOLDER_DOWNLOAD_ZIP_POST_CLEAR_DATA,
    payload: {}
  };
};
