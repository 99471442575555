import React, { useState, useEffect } from 'react';


export default function CompletedView() {

    
        return (
            <div >
                <h2>Completed View</h2>
            </div>
        );
}