import styled from "styled-components";
import { Nb_SearchFiltersProps } from "../type/searchFilters.type";
import { makeStyles } from "tss-react/mui";

interface ExtendedSearchProps extends Nb_SearchFiltersProps {
  isHidden?: boolean;
}

const filterProps = (prop: string) => !["isHidden"].includes(prop);

export const ContainerList = styled.article.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSearchProps>`
  width: 423px;
  display: flex;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
  max-height: ${(props) => (props.isHidden ? "0" : "475px")};
  opacity: ${(props) => (props.isHidden ? "0" : "1")};
  overflow: hidden;
  transition:
    max-height 0.7s ease-in-out,
    opacity 0.7s ease-in-out;
  box-shadow: 0 0 12px 0 rgba(90 89 89 / 20%);
  position: absolute;
  z-index: 99999;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
`;

export const UL = styled.ul.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSearchProps>`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const LiItem = styled.li.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSearchProps>`
  width: 100%;
  min-height: 72px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //align-items: center;
  cursor: pointer;
  padding: 10px 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #c4c4c4;
  &:hover {
    background-color: #eef5fb;
  }
`;

export const LiLastItem = styled.li.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSearchProps>`
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px 15px;
  box-sizing: border-box;
  &:hover {
    background-color: #eef5fb;
  }
`;

export const ContentImageList = styled.div.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSearchProps>`
  width: 30px;
  height: 30px;
`;

export const ImageList = styled.img.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSearchProps>`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const WrapperDocumentInfo = styled.div.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSearchProps>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DocumentName = styled.p.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSearchProps>`
  width: 175px;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;

export const DocumentType = styled.p.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSearchProps>`
  margin: 0;
  padding: 0;
  margin-left: auto;
  font-style: normal;
  color: #c4c4c4;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const WrapperCanonicalPath = styled.div.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSearchProps>`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const CanonicalPath = styled.p.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSearchProps>`
  margin: 0;
  padding: 0;
  font-style: normal;
  color: #c4c4c4;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

export const useStyles = makeStyles()(() => ({
  buttonSearch: {
    fontSize: "12px",
    lineHeight: "16px",
    textTransform: "none",
    backgroundColor: "#1776D2",
    width: "85px",
  },
  buttonFilters: {
    fontSize: "12px",
    lineHeight: "16px",
    textTransform: "none",
    backgroundColor: "#FFFFFF",
    width: "40px",
    minWidth: "16px",
    "&:hover": {
      backgroundColor: "white",
    },
  },
}));
