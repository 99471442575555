import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { expressions } from "../components/theme";
import "./style.css";
import { useStyles } from "./style";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useAxios from "./hook.ts";

import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import * as moment from "moment";
import { getUserIsRoot, userUpdatePassword } from "../services/ocr";
//import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import { NbSidebar } from "../components/sidebar/sidebar";
import NbNotificationIcon from "../components/notificationIcon/notificationIcon";
import IconDocPlace from "../assets/svg/IconCollapsed.svg";
import Documents from "../assets/svg/Docplace Frame (3).svg";
import FormsInboxIcon from "../assets/svg/Docplace Frame (3).svg";
import FormsDocplace from "../assets/svg/Frame.svg";
import FileManagerIcon from "../assets/svg/Frame from Docplace.svg";
import DocAssistantIcon from "../assets/svg/Chat Smile AI Icon.svg";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import SettingIcon from "../assets/svg/Docplace Frame.svg";
import DocumentTypeIcon from "../assets/svg/Docplace Dashboard.svg";
import MasterScannerIcon from "../assets/svg/Document Scanner (1).svg";
import InputMethodIcon from "../assets/svg/Docplace FrameInput.svg";
import ESignatureIcon from "../assets/svg/Docplace Frame (1).svg";
import ReceivedIcon from "../assets/svg/Docplace Frame (2).svg";
import RequestedIcon from "../assets/svg/Image 21 from DocplaceRequested.svg";
import FormsIcon from "../assets/svg/Frame.svg";
import FormsTemplateIcon from "../assets/svg/Frame (1).svg";
import FormsAssignedIcon from "../assets/svg/Docplace Frame (4).svg";
import FormsTrasBinIcon from "../assets/svg/Docplace Icon.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 4,
};

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const PrivateRoute = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  let auth = "";
  const [open, setOpen] = React.useState(true);
  const [openMenu, setOpenMenu] = React.useState(true);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [openMenu3, setOpenMenu3] = React.useState(false);
  const [openMenuSignature, setOpenMenuSignature] = React.useState(true);
  const [openMenuForms, setOpenMenuForms] = React.useState(true);
  const [openMenuWF, setOpenMenuWF] = React.useState(true);
  const [openMenuEsignature, setOpenMenuEsignature] = React.useState(true);
  const [userIsRoot, setUserIsRoot] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [passwordChange, setPasswordChange] = React.useState(false);
  const [passwordRepeatChange, setPasswordRepeatChange] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [passwordRepeat, setPasswordRepeat] = React.useState("");
  const [passwordChanged, setPasswordChanged] = React.useState(false);
  const [passwordUpdating, setPasswordUpdating] = React.useState(false);
  const [fullName, setFullName] = React.useState(false);
  const [lastLogin, setLastLogin] = React.useState(false);
  const [email, setEmail] = React.useState(true);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isOpenSideBar, setIsOpenSideBar] = React.useState(true);
  const [hideSidebar, setHideSidebar] = React.useState(false);
  const [listNotification, setListNotification] = React.useState();

  const openMenuUser = Boolean(anchorEl);
  const handleClickUser = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [activeOption, setActiveOption] = React.useState({
    menu: "Dashboard",
  });

  const {
    notificationResponse,
    notificationLoading,
    notificationError,
    newNotification,
    fetchNotifications,
    setReadNotifications,
  } = useAxios();

  useEffect(() => {
    if (localStorage.getItem("docplace")) {
      setIsAdmin(
        JSON.parse(localStorage.getItem("docplace")).superUser === 1
          ? true
          : false
      );
    }
  }, []);

  useEffect(() => {
    if (notificationResponse?.length > 0) {
      const mappedNotifications = notificationResponse.map((item) => ({
        id: item.id,
        name: item.title,
        createdAgo: item.createdAgo,
        description: item.description,
        nameDocument: item?.documentMetadata?.name,
        idDocument: item?.documentMetadata?.id,
        statusNew: !item.read,
      }));

      setListNotification(mappedNotifications);
    }
  }, [notificationResponse]);

  useEffect(() => {
    if (auth) {
      fetchNotifications("?sortBy=create_at&sortDir=desc");
    }
  }, [auth]);

  if (!JSON.parse(localStorage.getItem("docplace"))) {
    navigate("/login");
  } else {
    auth = JSON.parse(localStorage.getItem("docplace")).token ? true : false;
  }

  const getNotification = () => {
    fetchNotifications("?sortBy=create_at&sortDir=desc");
  };

  const callMenuOption = (opt) => {
    setActiveOption({ menu: opt });
    switch (opt) {
      case "Dashboard": {
        navigate("/dashboard");
        break;
      }
      case "fileManager": {
        navigate("/explorer");
        break;
      }
      case "Search": {
        navigate("/search");
        break;
      }
      case "documentType": {
        navigate("/documentType");
        break;
      }
      case "master-scanner": {
        navigate("/masterTemplate");
        break;
      }
      case "input-method": {
        navigate("/inputMethod");
        break;
      }
      case "user": {
        if (isAdmin) {
          navigate("/user");
        } else {
          navigate("/user");
        }
        break;
      }
      case "role": {
        navigate("/rol");
        break;
      }
      case "signature": {
        navigate("/signature");
        break;
      }
      case "docplace-esignature-id": {
        navigate("/docplaceSign");
        break;
      }

      case "requested-id": {
        navigate("/eSignatureRequested");
        break;
      }
      case "Inbox": {
        navigate("/eSignatureInbox");
        break;
      }
      case "Dashboard eSignature": {
        navigate("/DashboardSignature");
        break;
      }
      case "PlaceSignature": {
        navigate("/place-signature");
        break;
      }
      case "approval-process-id": {
        navigate("/ApprovalProcess");
        break;
      }
      case "docplace-forms": {
        navigate("/docplaceForms");
        break;
      }
      case "FormsTemplates": {
        navigate("/formsTemplate");
        break;
      }
      case "forms-inbox-id": {
        navigate("/forms-inbox");
        break;
      }
      case "templates-id": {
        navigate("/templates");
        break;
      }
      case "templates-v2-id": {
        navigate("/templates-v2");
        break;
      }
      case 'template-builder-id':{
        navigate("/template-builder");
        break;
      }
      case "assigned-id": {
        navigate("/assigned-forms");
        break;
      }
      case "trash-id": {
        navigate("/trash-bin");
        break;
      }
      case "requestedWF-id": {
        navigate("/requested-process");
        break;
      }
      case "recived-id": {
        navigate("/Esignature-requests");
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleReset = () => {
    let systemUserRequest = {
      appId: "string",
      data: {
        confirmPassword: passwordRepeat,
        email: JSON.parse(localStorage.getItem("docplace")).email,
        password: password,
      },
    };
    setPasswordUpdating(true);

    userUpdatePassword(systemUserRequest, (event) => {})
      .then((response) => {
        let successMsg = "";

        if (passwordChanged) {
          successMsg = "Password reset successful";
          setPassword("");
          setPasswordRepeat("");
          setPasswordChanged(false);
          setPasswordUpdating(false);
        }

        if (successMsg)
          toast.success(successMsg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        setPasswordUpdating(false);
        setOpenModal(false);
      })
      .catch((err) => {
        toast.error(
          `There was an error resetting password. Make sure that the password wasn't already used, and try again.`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );

        setPasswordUpdating(false);
      });
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpenMenu(!openMenu);
  };

  const handleClick2 = () => {
    setOpenMenu2(!openMenu2);
  };
  const handleClickMenuSignatures = () => {
    setOpenMenuSignature(!openMenuSignature);
  };

  const handleClickMenuForms = () => {
    setOpenMenuForms(!openMenuForms);
  };

  const handleClickMenuWF = () => {
    setOpenMenuWF(!openMenuWF);
  };

  const handleClickMenuEsignature = () => {
    setOpenMenuEsignature(!openMenuEsignature);
  };

  const handleClick3 = () => {
    setOpenMenu3(!openMenu3);
  };

  const handleLogout = () => {
    localStorage.removeItem("docplace");
    //Todo: replace with redux saga logout method
    localStorage.setItem("docplace-logout", true);
    navigate("/login");
  };
  const handleOpenModal = (label) => {
    setFullName(JSON.parse(localStorage.getItem("docplace")).fullName);
    setEmail(JSON.parse(localStorage.getItem("docplace")).email);
    setLastLogin(JSON.parse(localStorage.getItem("docplace")).lastLogin);
    setOpenModal(true);

    setPassword("");
    setPasswordRepeat("");
    setPasswordChanged(false);
    setPasswordUpdating(false);
  };
  const handleCloseModal = () => setOpenModal(false);

  useEffect(() => {
    if (localStorage.getItem("docplace")) {
      getUserIsRoot(
        JSON.parse(localStorage.getItem("docplace")).userId,
        (event) => {}
      )
        .then((response) => {
          setUserIsRoot(response.data.data);
        })

        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const onStatusSideBar = (isHideSideBar) => {
    setIsOpenSideBar(!isHideSideBar);
    setHideSidebar(isHideSideBar);
  };

  const getPage = (page) => {
    getNotification();
    callMenuOption(page.id);
  };

  const closeSideBar = () => {
    setHideSidebar(false);
  };

  const goDocumentNotification = (item) => {
    const currentPath = window.location.pathname;
    if (currentPath.includes("/docViewer/")) {
      navigate(`/docViewer/${item.idDocument}?t=${Date.now()}`, {
        replace: true,
        state: { forceReload: true },
      });
    } else {
      navigate(`/docViewer/${item.idDocument}`);
    }
    setReadNotifications("/", item.id);
  };

  return auth ? (
    <div className="layout-container-layout">
      <div
        className="sidebar-layout"
        style={{ width: isOpenSideBar ? "240px" : "64px" }}
      >
        <NbSidebar
          hideSidebar={hideSidebar}
          backgroundColor={"#FFFFFF"}
          bgColorHeader={"#1976d2"}
          logoHeader={"https://app.docplace.io/static/media/logo2.c7c1d5fc.svg"}
          iconHeader={IconDocPlace}
          sidebarItemsList={[
            {
              id: "document-1",
              header: {
                id: "documentHeader-1",
                title: "Documents",
                icon: Documents,
                hideList: false,
              },
              children: [
                {
                  id: "fileManager",
                  title: "File Manager",
                  icon: FileManagerIcon,
                  active: true,
                },
                {
                  id: "Search",
                  title: "Search",
                  icon: DocAssistantIcon,
                  active: true,
                },
              ],
            },
            {
              id: "docplace-esignature-id",
              header: {
                id: "docplace-esignature-header-1",
                title: "DocPlace Sign",
                icon: Documents,
                hideList: true,
              },
            },
            {
              id: "setting-id",
              header: {
                id: "settingHeader-1",
                title: "Settings",
                icon: SettingIcon,
                hideList: false,
              },
              children: [
                {
                  id: "documentType",
                  title: "Document Type",
                  icon: DocumentTypeIcon,
                  active: true,
                },
                {
                  id: "master-scanner",
                  title: "Master Scanner",
                  icon: MasterScannerIcon,
                  active: false,
                },
                {
                  id: "input-method",
                  title: "Input Method",
                  icon: InputMethodIcon,
                  active: false,
                },
              ],
            },
            {
              id: "security",
              header: {
                id: "security-1",
                title: "Security",
                icon: SettingIcon,
                hideList: false,
              },
              children: [
                {
                  id: "user",
                  title: "Users",
                  icon: DocumentTypeIcon,
                  active: true,
                },
                {
                  id: "role",
                  title: "Roles",
                  icon: MasterScannerIcon,
                  active: false,
                },
              ],
            },
            {
              id: "forms-id",
              header: {
                id: "formsHeader-1",
                title: "Forms",
                icon: FormsIcon,
                hideList: false,
              },
              children: [
                {
                  id: "forms-inbox-id",
                  title: "Forms Inbox",
                  icon: FormsInboxIcon,
                  active: true,
                },
                {
                  id: "templates-id",
                  title: "Templates",
                  icon: FormsTemplateIcon,
                  active: false,
                },
                {
                  id: "templates-v2-id",
                  title: "Templates V2",
                  icon: FormsTemplateIcon,
                  active: false,
                },
                {
                  id: "assigned-id",
                  title: "Assigned Forms",
                  icon: FormsAssignedIcon,
                  active: false,
                },
                {
                  id: "trash-id",
                  title: "Trash Bin",
                  icon: FormsTrasBinIcon,
                  active: false,
                },
              ],
            },
            {
              id: "workflow-id",
              header: {
                id: "workflowHeader-1",
                title: "Approval process",
                icon: ESignatureIcon,
                hideList: false,
              },
              children: [
                {
                  id: "requestedWF-id",
                  title: "Requested",
                  icon: RequestedIcon,
                  active: false,
                },
              ],
            },
          ]}
          onIsHideSideBar={onStatusSideBar}
          onClickItemList={getPage}
        />
      </div>
      <div className="body-layout">
        <Box>
          <CssBaseline />
          <article className={"header-layout"}>
            <section>
              {isOpenSideBar ? null : (
                <Tooltip title="Open SideBar" style={{ marginTop: "12px" }}>
                  <IconButton
                    onClick={closeSideBar}
                    size="small"
                    sx={{ ml: 2, position: "absolute", left: "0px" }}
                    aria-controls={openMenuUser ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenuUser ? "true" : undefined}
                  >
                    <ArrowForwardIosIcon style={{ color: "#fff" }} />
                  </IconButton>
                </Tooltip>
              )}
              <NbNotificationIcon
                title="Notifications"
                list={listNotification}
                onFocus={getNotification}
                haveNotification={newNotification}
                onClick={goDocumentNotification}
              />
              <Tooltip title="Account settings" style={{ marginTop: "12px" }}>
                <IconButton
                  onClick={handleClickUser}
                  size="medium"
                  sx={{ ml: 2, position: "absolute", right: "40px" }}
                  aria-controls={openMenuUser ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenuUser ? "true" : undefined}
                >
                  <AccountCircle style={{ color: "#fff" }} />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openMenuUser}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 1,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem disableRipple onClick={handleOpenModal}>
                  <Avatar /> Profile
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                  <Logout />
                  &nbsp; Logout
                </MenuItem>
              </Menu>
            </section>
          </article>

          <Box
            style={{ backgroundColor: "#eff3f7", marginTop: "-7px" }}
            component="main"
            sx={{ flexGrow: 1, p: 3 }}
          >
            <Outlet />
          </Box>
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                User profile
              </Typography>

              <Typography
                component={"div"}
                id="modal-modal-format"
                sx={{ mt: 2 }}
              >
                <FormControl fullWidth>
                  <TextField
                    style={{ width: "100%", marginBottom: "8px" }}
                    id="fullName"
                    disabled
                    defaultValue={fullName}
                    label="Full name"
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    style={{ width: "100%", marginBottom: "8px" }}
                    id="email"
                    disabled={true}
                    defaultValue={email}
                    label="Email"
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    style={{ width: "100%", marginBottom: "8px" }}
                    id="lastLogin"
                    disabled
                    defaultValue={moment(lastLogin).format("llll")}
                    label="Last login"
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    style={{ width: "100%", marginBottom: "8px" }}
                    id="passwordUser"
                    type="password"
                    label="New password"
                    onChange={(value) => {
                      setPassword(value.target.value);
                      setPasswordChanged(true);
                      setPasswordChange(expressions.password.test(password));
                    }}
                    value={password}
                  />
                  {passwordChange == false && (
                    <small>
                      Your new password must be at least 8 characters length and
                      contain uppercase, lowercase characters, and at least one
                      number
                    </small>
                  )}

                  <TextField
                    style={{ width: "100%", marginBottom: "8px" }}
                    id="passwordUserRepeat"
                    type="password"
                    label="Password Repeat"
                    onChange={(value) => {
                      setPasswordRepeat(value.target.value);
                      setPasswordRepeatChange(password !== value.target.value);
                    }}
                    value={passwordRepeat}
                  />
                  {passwordRepeatChange && (
                    <small>Password doesn't match</small>
                  )}
                </FormControl>
              </Typography>
              <Button
                variant="contained"
                color="success"
                hidden={!passwordChanged && password === ""}
                disabled={
                  password === "" ||
                  password !== passwordRepeat ||
                  password.length < 4 ||
                  passwordUpdating == true ||
                  expressions.password.test(password) == false
                }
                onClick={handleReset}
                sx={{ mt: 2, mr: 2 }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => setOpenModal(false)}
                sx={{ mt: 2 }}
              >
                Cancel
              </Button>
            </Box>
          </Modal>
        </Box>
      </div>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
