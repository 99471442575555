import { Nb_SearchFiltersProps } from "../type/searchFilters.type";
import { ListView } from "./list.view";
import { useRef } from "react";
import useClickOutside from "../../contexMenu/hooks/clickOutside";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useStyles } from "../style/style";
import { FiltersView } from "./filters.view";

export const SearchFiltersView = (props: Partial<Nb_SearchFiltersProps>) => {
  const { hideListFn = () => {} } = props;
  const { searchText = () => {} } = props;
  const { classes } = useStyles();
  const containerRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(containerRef, () => props.setIsHidden?.(true));
  return (
    <section
      style={{
        width: "fit-content",
        display: "inline-flex",
        gap: "7px",
      }}
    >
      <div style={{ width: "425px" }}>
        <TextField
          id="input-with-icon-textfield"
          size={"small"}
          placeholder={"Search"}
          fullWidth={true}
          onBlur={(e) => {
            props.setInputValue?.(e.target.value);
          }}
          onChange={() => {
            hideListFn(true);
          }}
          onClick={() => {
            hideListFn(false);
          }}
          sx={{
            "& .MuiInputBase-root": {
              paddingLeft: "10px",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
        <div ref={containerRef}>
          <ListView {...props} />
        </div>
      </div>
      <Button
        variant="contained"
        onClick={() => {
          searchText();
        }}
        className={classes.buttonSearch}
      >
        Search
      </Button>

      {props?.isHiddenFilters ? (
        ""
      ) : (
        <Button
          onClick={props.hiddenFilters}
          variant="contained"
          className={classes.buttonFilters}
        >
          <FilterAltIcon sx={{ color: "#1776D2" }} />
        </Button>
      )}

      <FiltersView {...props} />
    </section>
  );
};
