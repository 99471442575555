import styled from "styled-components";
import { NB_HorizontalMenuProps } from "../types/horizontalMenu.type";

interface ExtendedMenuHorizontal extends NB_HorizontalMenuProps {
  isActive?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
}

const filterProps = (prop: string) => !["isActive"].includes(prop);

export const ContainerNav = styled.nav.withConfig({
  shouldForwardProp: filterProps,
})`
  width: fit-content;
  height: 36px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  gap: 8px;
  position: absolute;
  border-radius: 8px;
  background-color: #ffffff;
`;

export const UL = styled.ul.withConfig({
  shouldForwardProp: filterProps,
})`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  list-style: none;
  border-radius: 8px;
  margin: 0;
  padding: 0;
`;

export const Li = styled.li.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedMenuHorizontal>`
  width: auto;
  height: 36px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 14px 16px 12px 16px;
  border-radius: ${(props) =>
    props.isFirst ? "8px 0 0 8px" : props.isLast ? "0 8px 8px 0" : "0"};
  cursor: pointer;
  color: ${(props) => (props.isActive ? "#006ac6" : "#5a5a5a")};
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: 0.1px;
  box-sizing: border-box;
`;

export const Name = styled.p.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedMenuHorizontal>`
  width: auto;
  height: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${(props) => (props.isActive ? "#006ac6" : "#5a5a5a")};
  font-family: sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

export const BorderButton = styled.div.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedMenuHorizontal>`
  width: 100%;
  height: 2px;
  display: flex;
  background-color: ${(props) => (props.isActive ? "#006ac6" : "transparent")};
  border-radius: 4px 4px 0 0;
  position: absolute;
  margin-top: 31px;
`;
