import { BorderButton, ContainerNav, Li, Name, UL } from "../style/style";
import { NB_HorizontalMenuProps } from "../types/horizontalMenu.type";

export const HorizontalMenuView = (props: Partial<NB_HorizontalMenuProps>) => {
  const { onClickMenuFn = () => {} } = props;
  return (
    <ContainerNav>
      <UL>
        {props.menu?.map((item, index: number) => (
          <Li
            key={item.id}
            isFirst={index === 0}
            isLast={index === (props.menu?.length ?? 0) - 1}
            onClick={() => {
              onClickMenuFn(item);
            }}
          >
            <Name isActive={item.active}>{item.name}</Name>
            <BorderButton isActive={item.active}></BorderButton>
          </Li>
        ))}
      </UL>
    </ContainerNav>
  );
};
