import React, { useState, useEffect } from "react";

import TemplateGrid from "./components/TemplateGrid/TemplateGrid";
import TemplateHeader from "./components/TemplateHeader/TemplateHeader";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../components/theme";
import CompletedView from "./components/CompletedView/CompletedView";
import AssignedView from "./components/AssignedView/AssignedView";
import ArchiveView from "./components/ArchiveView/ArchiveView";
import { useDispatch, useSelector } from "react-redux";
import { getSearchForm } from "../../store/search/actions";
import { toast, ToastContainer } from "react-toastify";

import "./explorer-style.scss";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		minHeight: "90vh",
	},
	explorerContainer: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		overflowY: "auto",
		backgroundColor: "#f0f0f0",
		flex: 1,
		height: "100%",
	},
}));
const theme = createMuiTheme({
	palette: {
		primary: {
			main: colors.blue,
		},
		secondary: {
			main: "#009A17",
		},
		green: {
			main: "#009A17",
		},
	},
	typography: {
		fontFamily: ["Roboto", "Helvetica", "sans-serif"].join(","),
	},
});
const TemplatesV2 = () => {
	const classes = useStyles();
	const [startPage] = useState(true);
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(50);
	const [valueSearch, setValueSearch] = useState("");
	const navigate = useNavigate();
	const notify = (msj, event) => toast[event](msj);
	const [loading, setLoading] = useState(false);

	const [mpage, setMPage] = useState(0);
	const [tabValue, setTabValue] = React.useState(0);

	const handleTabChange = (newValue) => {
		setTabValue(newValue);
	};

	useEffect(() => {
		if (startPage) {
			getSearchFile(true);
		}
	}, [startPage]);
	const getSearchFile = (init) => {
		if (init == true) {
			let obj = {
				text: "",
			};

			dispatch(
				getSearchForm(
					{
						page: page,
						pageSize: pageSize,
						data: obj,
					},
					navigate
				)
			);
		} else {
			if (valueSearch) {
				let obj = {
					text: valueSearch.toLowerCase(),
				};

				dispatch(
					getSearchForm(
						{
							page: page,
							pageSize: pageSize,
							data: obj,
						},
						navigate
					)
				);
			} else {
				notify("Enter a value", "error");
			}
		}
	};
	return (
		<div>
			<ThemeProvider theme={theme}>
				<div className={classes.container}>
					<TemplateHeader tabValue={tabValue} onTabChange={handleTabChange} />

					<div className={classes.explorerContainer}>
						{tabValue === 0 && <TemplateGrid setLoading={setLoading} reloadTemplates={getSearchFile} />}
						{tabValue === 1 && <CompletedView />}
						{tabValue === 2 && <AssignedView />}
						{tabValue === 3 && <ArchiveView />}
					</div>
					<Loading show={loading} text={"Processing..."} />
					<ToastContainer autoClose={3000} />
				</div>
			</ThemeProvider>
		</div>
	);
};

export default TemplatesV2;
