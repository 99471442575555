import http from "./http-common";
import axios from "axios";

export const uploadFileManual = (
  file,
  onUploadProgress,
  doctypeId,
  archiveId,
  ignoreDynamicLocation,
  actionType
) => {
  let formData = new FormData();
  let url =
    process.env.REACT_APP_HOST_API +
    "/automation/api/v1/Documents/UploadAsyncV2/";

  if (actionType) {
    url += `?actionType=${actionType}`;
  }

  formData.append("file", file);
  if (archiveId != null) {
    formData.append("archiveId", archiveId);
  }
  formData.append("originId", "Web Ui");
  formData.append("originType", "Web Ui");
  if (doctypeId != null) {
    formData.append("doctypeId", doctypeId);
  }
  formData.append("ignoreDynamicLocation", ignoreDynamicLocation);

  return http.post(url, formData, {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=<calculated when request is sent>",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      userId: JSON.parse(localStorage.getItem("docplace")).userId,
    },
    onUploadProgress,
  });
};

// export const uploadAttachment = (file, documentId) => {
//   let url = `${process.env.REACT_APP_HOST_API}/automation/api/v1.0/DocumentsAttach/AttachFileDocument?documentId=${documentId}`;
//
//   return http.post(url, file, {
//     headers: {
//       "Content-Type": file.type,
//       Authorization:
//         "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
//       customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
//     },
//   });
// };

export const uploadAttachment = (files, documentId) => {
  let url = `${process.env.REACT_APP_HOST_API}/automation/api/v1.0/DocumentsAttach/AttachFileDocument?documentId=${documentId}`;

  const formData = new FormData();

  files.forEach((file) => {
    formData.append(`files`, file);
  });

  return axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
    },
  });
};

export const validateFiles = (archiveId, fileName) => {
  let url = `${process.env.REACT_APP_HOST_API}/automation/api/v1.0/Documents/ValidateUniqueFileName`;
  const queryParams = new URLSearchParams();
  if (archiveId != null) queryParams.append("archiveId", archiveId);
  if (fileName != null) queryParams.append("fileName", fileName);

  url += `?${queryParams.toString()}`;

  return http.post(
    url,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
        customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      },
    }
  );
};

export const uploadAndAppendDocument = (
  file,
  onUploadProgress,
  doctypeId,
  archiveId,
  id
) => {
  let formData = new FormData();
  let url =
    process.env.REACT_APP_HOST_API +
    "/automation/api/v1/Documents/MergePdfToExistingDoc/" +
    id;

  formData.append("file", file);
  if (archiveId != null) {
    formData.append("archiveId", archiveId);
  }
  formData.append("originId", "Web Ui");
  formData.append("originType", "Web Ui");
  if (doctypeId != null) {
    formData.append("doctypeId", doctypeId);
  }
  formData.append("Name", "test");
  formData.append("FileName", "test");
  // formData.append("ignoreDynamicLocation", ignoreDynamicLocation);

  return http.post(url, formData, {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=<calculated when request is sent>",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      userId: JSON.parse(localStorage.getItem("docplace")).userId,
    },
    onUploadProgress,
  });
};

export const uploadTemplate = (
  file,
  onUploadProgress,
  doctypeId,
  archiveId,
  ignoreDynamicLocation
) => {
  let formData = new FormData();
  let url =
    process.env.REACT_APP_HOST_API + "/automation/api/v1/Forms/UploadTemplate";

  formData.append("file", file);
  if (archiveId != null) {
    formData.append("archiveId", archiveId);
  }
  formData.append("originId", "Web Ui");
  formData.append("originType", "Web Ui");
  if (doctypeId != null) {
    formData.append("doctypeId", doctypeId);
  }
  formData.append("Name", "test");
  formData.append("FileName", "test");
  // formData.append("ignoreDynamicLocation", ignoreDynamicLocation);

  return http.post(url, formData, {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=<calculated when request is sent>",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
    },
    onUploadProgress,
  });
};

export const uploadFile = (file, onUploadProgress, doctypeId) => {
  let formData = new FormData();
  let url =
    process.env.REACT_APP_HOST_API + "/automation/api/v1/Doctype/UploadV2";

  formData.append("file", file);

  return http.post(url, formData, {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=<calculated when request is sent>",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      doctypeId: doctypeId,
    },
    onUploadProgress,
  });
};

export const getUploadedFiles = (onUploadProgress, doctypeId) => {
  let url =
    process.env.REACT_APP_HOST_API +
    "/automation/api/v1/Doctype/GetSamp" +
    "leFiles";

  return http.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      doctypeId: doctypeId,
    },
    onUploadProgress,
  });
};

/**UPLOAD signature image PNG */
export const uploadSignature = (file, name, email, onUploadProgress) => {
  let formData = new FormData();
  let url =
    process.env.REACT_APP_HOST_API +
    "/automation/api/v1.0/Signature/User/" +
    JSON.parse(localStorage.getItem("docplace")).userId;
  formData.append("file", file, "image.png"); //upload the blob image
  formData.append("type", "INTERNAL");
  formData.append("acceptTermsAndConditions", "1");
  formData.append("name", name);
  formData.append("email", email);

  return http.post(url, formData, {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=<calculated when request is sent>",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      doctypeId: "",
      userId: JSON.parse(localStorage.getItem("docplace")).userId,
    },
    onUploadProgress,
  });
};
