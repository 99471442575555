import React from "react";
import Select from "react-select";
import { useStyles } from "./style";
import "../../App.css";

const SelectDropdownDocPlace = ({
  field,
  setField,
  label,
  helperText,
  id,
  required,
  defaultList,
  arrayList,
  isMulti,
}) => {
  const classes = useStyles();
  return (
    <>
      <Select
        id={id}
        defaultValue={defaultList}
        isMulti={isMulti}
        styles={{
          zIndex: "9999",
          control: (base) => ({
            ...base,
            height: 30,
            minHeight: 30,
            maxHeight: 30,
            fontSize: "13px", // Reduced font size
            lineHeight: "1", // Tighter line height
            padding: "0 6px", // Adjusted padding
          }),
          indicatorsContainer: (base) => ({
            ...base,
            padding: "2px",
            width: "24px", // Fixed indicator width
          }),
          valueContainer: (base) => ({
            ...base,
            padding: "0 0px", // Reduced inner padding
            margin: 0,
          }),
          dropdownIndicator: (base) => ({
            ...base,
            padding: "0px", // Smaller indicator padding
            width: "50px",
            height: "20px",
          }),
          menuList: (base) => ({
            ...base,
            padding: 0,
            fontSize: "13px", // Match menu font size
          }),
          menu: (base) => ({
            ...base,
            margin: 0, // Remove default margin
            top: "40px", // Stick directly below control
            position: "absolute",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }),
        }}
        name={label}
        options={arrayList}
        required={required}
        placeholder={label}
        onChange={(newValue) => {
          setField({ ...field, value: newValue });
        }}
        className={classes.selectControl + " basic-multi-select"}
        classNamePrefix="select"
      />
      {helperText && <p className={classes.helperText}>{helperText}</p>}
    </>
  );
};

export default SelectDropdownDocPlace;
