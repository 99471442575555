import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Box,
  Stack,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography
} from '@mui/material';

import DeleteForever from '@mui/icons-material/DeleteForever';

import HeightIcon from '@mui/icons-material/Height';
import { colors } from '../../../../components/theme';

export default function FieldsDnDList({
  fields,
  onFieldsChange,
  onDragEnd,
  onRemoveField
}) {
  const handleFieldValueChange = (index, newValue) => {
    const updated = fields.map((f, i) =>
      i === index ? { ...f, fieldValue: newValue } : f
    );
    onFieldsChange(updated);
  };

  const renderFieldContent = (field, index) => {
    const { fieldType, label, fieldValue } = field;

    const handleChange = (e) => {
      if (fieldType === 'checkbox') {
        const checked = e.target.checked ? ':selected:' : ':unselected:';
        handleFieldValueChange(index, checked);
      } else {
        handleFieldValueChange(index, e.target.value);
      }
    };

    switch (fieldType) {
      case 'checkbox':
        return (
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
              {label}
            </Typography>
            <FormGroup>
              <FormControlLabel
                label=""
                control={
                  <Checkbox
                    checked={fieldValue === ':selected:'}
                    onChange={handleChange}
                    color="primary"
                  />
                }
              />
            </FormGroup>
          </Box>
        );

      case 'date':
        return (
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
              {label}
            </Typography>
            <TextField
              type="date"
              variant="outlined"
              value={fieldValue || ''}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Box>
        );

      default:
        // text, phone, etc.
        return (
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
              {label}
            </Typography>
            <TextField
              variant="outlined"
              value={fieldValue || ''}
              onChange={handleChange}
              fullWidth
            />
          </Box>
        );
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="fieldsDroppable">
        {(providedDroppable) => (
          <Box
            ref={providedDroppable.innerRef}
            {...providedDroppable.droppableProps}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
            border={0}
          >
            {fields.map((field, index) => (
              <Draggable
                key={field.fieldName}
                draggableId={field.fieldName}
                index={index}
              >
                {(providedDrag) => (
                  <Box
                    ref={providedDrag.innerRef}
                    {...providedDrag.draggableProps}
                    sx={{
                      p: 2,
                      backgroundColor: '#fff',
                      ":hover": {
                        backgroundColor: 'rgba(0, 106, 198, 0.10)',
                      },
                      ':hover .heigh-icon-color': {
                        color: colors.blue,
                      },
                      ':hover .delete-icon-color': {
                        color: '#EC221F'
                      }
                    }}
                    border={0}
                  >
                    {/* Uso <Stack> horizontal: drag handle a la IZQ, field en el medio, boton eliminar a la DER */}
                    <Stack direction="row" alignItems="center" spacing={2}>
                      
                      {/* Drag handle */}
                      <IconButton
                        {...providedDrag.dragHandleProps}
                     
                        size="small"
                      >
                        <HeightIcon 
                           className="heigh-icon-color"/>
                      </IconButton>

                      {/* Contenido del field (label + input) */}
                      <Box flex={1}>
                        {renderFieldContent(field, index)}
                      </Box>

                      {/* Boton eliminar */}
                      <IconButton
                        size="small"
                        onClick={() => onRemoveField(index)}
                      >
                        <DeleteForever
                        className="delete-icon-color" />
                      </IconButton>
                    </Stack>
                  </Box>
                )}
              </Draggable>
            ))}

            {providedDroppable.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
}
