import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import iconDocTpl from "../../../../assets/templateV2/doc_tpl.svg";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const optionsMenu = [
	{
		label: "Shipping",
		value: "Shipping",
		icon: <LocalShippingIcon />,
	},
	{
		label: "Send",
		value: "Send",
		icon: <CloudUploadIcon />,
	},
	{
		label: "Edit Template",
		value: "EditTemplate",
		icon: <EditIcon />,
	},
	{
		label: "Delete",
		value: "Delete",
		icon: <DeleteIcon />,
	},
];

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: 120,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		position: "relative",
		padding: "26px 16px",
	},
	icon: {
		fontSize: 40,
		color: theme.palette.primary.main,
	},
	title: {
		textAlign: "center",
		color: theme.palette.primary.main,
	},
	cardContent: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		position: "relative",
	},
	moreIconBtn: {
		position: "absolute",
		top: 0,
		right: 0,
	},
	menu: {
		backgroundColor: "#fff",
        borderWidth: 0,
        borderColor: 'transparent',
	},
    menuItem: {
        display: "flex",
        alignItems: "center",
        color: theme.palette.primary.main,
    },
    menuItemText: {
        marginLeft: "10px",
        color: '#0D0D0D',

    },
}));

export default function TemplateCard({ template, onClickMenu }) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget); 
	};

	const handleMenuClose = (menu, template) => {
		console.log("menu", menu);
		setAnchorEl(null); 
		onClickMenu(menu, template);
	};

	const open = Boolean(anchorEl);

	return (
		<div className={classes.root} variant="outlined">
			<IconButton
				className={classes.moreIconBtn}
				aria-controls="simple-menu"
				aria-haspopup="true"
				onClick={handleMenuOpen}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleMenuClose}
                color="primary"
			>
				{optionsMenu.map((option) => (
					<MenuItem className={classes.menuItem} key={option.value} onClick={() => handleMenuClose(option.value, template)}>
						{option.icon}
                        <span className={classes.menuItemText}>
						    {option.label}

                        </span>
					</MenuItem>
				))}
			</Menu>
			<div className={classes.cardContent}>

				<div>
					<img src={iconDocTpl} alt="iconDocTpl" />
				</div>
				<Typography variant="subtitle1" className={classes.title}>
					{template.templateName}
				</Typography>
			</div>
		</div>
	);
}
