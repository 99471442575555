import { useState, useEffect } from "react";
import axios from "axios";

const useAxios = () => {
  const [newNotification, setNewNotification] = useState(false);
  const [notificationResponse, setNotificationResponse] = useState(null);
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [notificationError, setNotificationError] = useState<any>();
  //const API_URL = process.env.REACT_APP_HOST_API;
  const API_URL = "https://api.dev.docplace.io/gw/notification/Api/V1/feeds";

  const setUserId = () => {
    const docplaceData = localStorage.getItem("docplace");
    if (docplaceData) {
      return JSON.parse(docplaceData).userId || "";
    }
    return "";
  };

  const setCustomerId = () => {
    const docplaceData = localStorage.getItem("docplace");
    if (docplaceData) {
      return JSON.parse(docplaceData).customerId || "";
    }
    return "";
  };

  const fetchNotifications = async (url: string) => {
    setNotificationLoading(true);
    try {
      const response = await axios.get(`${API_URL}${url}`, {
        headers: {
          accept: "application/json",
          "x-client-id": setCustomerId(),
          "x-user-id": setUserId(),
        },
      });
      setNotificationResponse(response.data);
      const hasReadNotifications = response.data.some(
        (notification: { read: boolean }) => notification.read === false
      );

      setNewNotification(hasReadNotifications);
    } catch (error) {
      console.log("UPSSSS::: ", error);
      if (error) {
        setNotificationError(error);
      }
    } finally {
      setNotificationLoading(false);
    }
  };

  const setReadNotifications = async (url: string, id: string) => {
    setNotificationLoading(true);
    try {
      const response = await axios.put(
        `${API_URL}${url + id + "/read"}`,
        { read: true },
        {
          headers: {
            accept: "application/json",
            "x-client-id": setCustomerId(),
            "x-user-id": setUserId(),
          },
        }
      );
      return response.data; // Return the response data
    } catch (error) {
      console.log("UPSSSS::: ", error);
      if (error) {
        setNotificationError(error);
      }
    } finally {
      setNotificationLoading(false);
    }
  };

  return {
    fetchNotifications,
    setReadNotifications,
    notificationResponse,
    notificationLoading,
    notificationError,
    newNotification,
  };
};

export default useAxios;
