import styled from "styled-components";

export const DPwrapper = styled.section`
  width: 100%;
  height: 100%;
`;

export const DPheader = styled.article`
  width: 100%;
  height: 40px;
`;

export const DPbody = styled.article`
  width: 100%;
  height: 100%;
`;

export const DPfooter = styled.article`
  width: 100%;
  height: 30px;
`;

export const DPTitle = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: #1976d2;
  margin: 0;
  padding: 0;
`;

export const DPHeaderFilter = styled.div`
  width: 413px;
  height: 37px;
  display: inline-block;
  float: left;
`;
