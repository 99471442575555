import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"
import {
  useStyles,
  Wrapper,
  WrapperLogin,
  LoginFormDocPlace,
  WrapperFormDocPlace,
} from './style';
import {Label } from "reactstrap"
import logoFD from '../../assets/logoColorsDocPlace.png';
import {
  Button,
  Container,
  Paper,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux"
import { forgetPassword, resetApp } from "../../store/actions"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputDocPlace from "../../components/form/input";
import { expressions } from "../../components/theme";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingComponent from "../../components/loading";
import ReCAPTCHA from "react-google-recaptcha"
import "./style.css"

const ForgotPassword = (props) => {
  const classes = useStyles();
  const notify = (msj, event) => toast[event](msj);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [email, setEmail] = useState({ value: state.emailParam, error: false });  //set the email that was in loging, otherwise it is empty
  const { responseForget, errorForget, loading } = useSelector(state => ({  //Hook to have the responses from redux
    responseForget: state.Login.responseForgetPassword,
    errorForget: state.Login.errorForgetPassword,
    loading: state.Login.loadingForgetPassword,
  }));
  const [afterSuccess, setAfter] = useState(false);  //hook for display the distints components before and after a successfull call
  const [hasError, setError] = useState(false);
  const [isRobot, setRobot] = useState(true);
  const [rMessage, setMessage] = useState("A link to reset your password was sent to your email, please click on it. Make sure to also review your spam folder.");//default message

  /**Hook for actions after the success response */
  useEffect(() => {
    if (responseForget || errorForget) setAfter(true);
    if (responseForget) {
      if (responseForget.errors.length > 0) {
        setError(true);
        console.error(responseForget.errors[0].description);
        if (responseForget.errors[0].description.includes("USER OR EMAIL NOT FOUND")) {
          notify("There is no account linked to this email address.", "error");
          setMessage("There is no account linked to this email address.");
        }
        else {
          notify("We were not able to process your request try it later", "error");
          setMessage("We were not able to process your request try it later");
        }
      } else {
        notify("A link to reset your password was sent to your email.", "success");
        setMessage("A link to reset your password was sent to your email, please click on it. Make sure to also review your spam folder.");
      }
    }
  }, [responseForget, errorForget]);



  /**actions to send the link */
  const forgetPasswordLink = (e) => {
    e.preventDefault();
    if (email.value && !email.error) {
      let obj =
      {
        appId: "docPlace",
        data: {
          email: email.value
        }
      }
      dispatch(forgetPassword(obj, navigate));
    } else {
      notify('Please enter all the required information', 'error');
    }
  }

  /**after a successfully email link generation, the user can navigate back to login */
  const navigateLogin = (e) => {
    setAfter(true);
    dispatch(resetApp());  //reset redux auth to default values, clearing up for next time. 
    navigate("/login");
  }
  const recaptchaOnChange = (value) => {
    setRobot(false);
  }

  const recaptchaExpired = (value) => {
    setRobot(true);
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Forgot Password</title>
      </MetaTags>
      <div className="login-container">
        <ToastContainer autoClose={3000} />
        <Paper elevation={3} className="login-box">

          <Container maxWidth="xs" sx={{ textAlign: "center", mt: 1 }}>
            <img alt="docplace" className={classes.imgLogo} onClick={navigateLogin} src={logoFD} />
            <LoginFormDocPlace onSubmit={forgetPasswordLink}>
              <WrapperFormDocPlace>
                {!afterSuccess &&
                  <InputDocPlace
                    id={'email'}
                    field={email}
                    setField={setEmail}
                    required={true}
                    type='email'
                    label="Username"
                    expresionRegular={expressions.email}
                  />}
                {(!afterSuccess && !isRobot) &&
                  <Button sx={{marginBottom: "20px"}} type="submit" className={classes.buttons} variant="contained" disabled={isRobot} color="success">
                    Send me the link
                  </Button>}
                {!afterSuccess &&
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    size="large"
                    onChange={recaptchaOnChange}
                    onExpired={recaptchaExpired}
                  />}
                {afterSuccess &&
                  <Label style={{ color: hasError ? 'red' : '' }}>
                    {rMessage}
                  </Label>
                }
                <br />
                <br />
                {afterSuccess && <Button type="button" onClick={navigateLogin} className={classes.buttons} variant="contained" color="success">
                  Return to login
                </Button>}
              </WrapperFormDocPlace>
            </LoginFormDocPlace>
          </Container>
        </Paper>
      </div>
    </React.Fragment>
  )
}

export default ForgotPassword
