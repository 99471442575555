import React, { useState } from "react";
import { Nb_HorizontalMenu } from "../../../components/horizontalMenu/horizontalMenu";
import { NB_DocPlaceSignModule_props } from "../type/docplace_sign_module.types";
import NbTreeFile from "../tree/treeFile";
import { Nb_SearchFilters } from "../../../components/searchFilters/searchFilters";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import GridDocPlace from "../grid/grid.js";
import Button from "@mui/material/Button";
import NbRequestSign from "../requestSigner/docplace_requet_sign";
import {
  DPwrapper,
  DPTitle,
  DPheader,
  DPbody,
  DPfooter,
  DPHeaderFilter,
  DPbodyList,
} from "../style/docplace_sign_module.styles";
import { set } from "date-fns";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },

  "& .MuiDialog-paper": {
    width: "762px",
    maxWidth: "762px",
  },
}));

const NbFormsModuleView: React.FC<
  NB_DocPlaceSignModule_props & { nameButton: string; pageActive: string }
> = ({ ...props }) => {
  const [showRequest, setShowRequest] = useState(false);
  const [showViewRequest, setShowViewRequest] = useState(false);
  const [listDocuments, setListDocuments] = useState<any>([]);

  const setRequest = (item: any) => {
    setListDocuments(item);
    setShowViewRequest(true);
  };

  return (
    <>
      {showViewRequest ? (
        <NbRequestSign 
          listDocuments={listDocuments} 
          setShowViewRequest={setShowViewRequest} />
      ) : (
        <>
          <BootstrapDialog
            onClose={() => {
              setShowRequest(false);
            }}
            aria-labelledby="customized-dialog-title"
            open={showRequest}
          >
            <NbTreeFile
              onClickClose={() => setShowRequest(false)}
              onClickRequest={setRequest}
            />
          </BootstrapDialog>
          <DPwrapper>
            <DPTitle>{props.title}</DPTitle>
            <DPheader>
              <DPHeaderFilter>
                <Nb_HorizontalMenu
                  menus={props.toolbar}
                  onClickMenu={props.onClickMenu}
                />
              </DPHeaderFilter>
              <div style={{ paddingTop: "3px", float: "left" }}>
                <Button
                  onClick={() => {
                    setShowRequest(true);
                  }}
                  size="small"
                  color="primary"
                  variant="contained"
                >
                  <span>{props.nameButton}</span>
                </Button>
              </div>
              <div style={{ float: "right" }}>
                <Nb_SearchFilters
                  hideList={true}
                  hideFilters={true}
                  isHidden={false}
                />
              </div>
            </DPheader>
            <DPbody>
              <DPbodyList>
                {props.pageActive === "1" ? (
                  <GridDocPlace data={props.inbox} option={props.onClickSign} option2={props.onClickView} />
                ) : (
                  <GridDocPlace data={props.sent} option={props.onClickSign} option2={props.onClickView} />
                )}
              </DPbodyList>
            </DPbody>
          </DPwrapper>
        </>
      )}
    </>
  );
};

export default NbFormsModuleView;
