import { useState, useEffect } from "react";
import axios from "axios";

const useAxios = () => {
  const [callSaveAndClose, setCallSaveAndClose] = useState<boolean>(false);
  const [callRequestSign, setCallRequestSign] = useState<boolean>(false);
  const [callSendRequestSign, setCallSendRequestSign] = useState<boolean>(false);
  const [callCreateRegion, setCallCreateRegion] = useState<any>({call: false,label: ""});
  const [callSelectLabel, setCallSelectLabel] = useState<any>({call: false,label: "", idx:"", text:"", val:""});

  const [treeResponse, setTreeResponse] = useState<any>([]);
  const [documentResponse, setDocumentResponse] = useState<any>([]);
  const [dataTree, setDataTree] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);

  const [treeLoading, setTreeLoading] = useState(false);
  const [treeError, setTreeError] = useState<any>();
  const [parentFolderId, setParentFolderId] = useState<string>("");

  const API_URL = process.env.REACT_APP_HOST_API;

  const setUserId = () => {
    const docplaceData = localStorage.getItem("docplace");
    if (docplaceData) {
      return JSON.parse(docplaceData).userId || "";
    }
    return "";
  };

  const setCustomerId = () => {
    const docplaceData = localStorage.getItem("docplace");
    if (docplaceData) {
      return JSON.parse(docplaceData).customerId || "";
    }
    return "";
  };

  const fetchTree = async (url: string) => {
    setTreeLoading(true);
    try {
      const response = await axios.get(`${API_URL}${url}`, {
        headers: {
          accept: "application/json",
          Customerid: setCustomerId(),
          Userid: setUserId(),
        },
      });
      setTreeResponse(response.data.data);
    } catch (error) {
      if (error) {
        setTreeError(error);
      }
    } finally {
      setTreeLoading(false);
    }
  };

  useEffect(() => {
    if (treeResponse) {
      fetchFiles(
        `/automation/api/v1.0/Documents/ByDetailFolderResource?page=1&pageSize=10000` +
          (parentFolderId ? `&parentFolderId=${parentFolderId}` : "")
      );
    }
  }, [treeResponse]);

  const fetchFiles = async (url: string) => {
    setTreeLoading(true);
    try {
      const response = await axios.get(`${API_URL}${url}`, {
        headers: {
          accept: "application/json",
          Customerid: setCustomerId(),
          Userid: setUserId(),
        },
      });
      let f = filterNonFolders(response.data.data);
      let t = treeResponse;

      f.map((item) =>
        t.push({
          children: null,
          fileSize: null,
          folder: false,
          id: item?.id,
          name: item.fkArchive?.name,
          nameLower: item.fkArchive?.name,
          parent: "",
          type: item.fkArchive?.type,
          updatedAt: item.updatedAt,
        })
      );
      setDataTree(t);
    } catch (error) {
      if (error) {
        setTreeError(error);
      }
    } finally {
      setTreeLoading(false);
    }
  };

  const filterNonFolders = (items: any[]): any[] => {
    return items.filter((item) => {
      return item.fkArchive && item.fkArchive.type !== "Folder";
    });
  };

  const fetchDocument = async (url: string) => {
    setTreeLoading(true);
    try {
      const response = await axios.post(`${API_URL}${url}`, null, {
        headers: {
          accept: "application/json",
          Customerid: setCustomerId(),
          Userid: setUserId(),
        },
      });
      setDocumentResponse(response.data.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setTreeError(error.response?.data || error.message);
      } else {
        setTreeError(error);
      }
    } finally {
      setTreeLoading(false);
    }
  };

  const fetchUsers = async (url: string) => {
    setTreeLoading(true);
    try {
      const response = await axios.get(`${API_URL}${url}`, {
        headers: {
          accept: "application/json",
          Customerid: setCustomerId(),
          Userid: setUserId(),
        },
      });

      let newArray:any[] = [];
      response.data.data.map((item: any) => {
        newArray.push({ label: item.fullName, email: item.email });
      });

      setUsers(newArray);

    } catch (error) {
      if (axios.isAxiosError(error)) {
        setTreeError(error.response?.data || error.message);
      } else {
        setTreeError(error);
      }
    } finally {
      setTreeLoading(false);
    }
  };


  return {
    fetchTree,
    fetchDocument,
    fetchUsers,
    callSaveAndClose,
    callSendRequestSign,
    setCallSendRequestSign,
    setCallSaveAndClose,
    setCallCreateRegion,
    setCallSelectLabel,
    setCallRequestSign,
    callRequestSign,
    callSelectLabel,
    callCreateRegion,
    setParentFolderId,
    treeResponse,
    documentResponse,
    treeLoading,
    treeError,
    dataTree,
    users,
  };
};

export default useAxios;
