import { SidebarContainer, Ul } from "../style/style";
import { Divider } from "@mui/material";
import { ChildItem, SidebarItem } from "../interface";
import { ItemListHeader } from "./itemListHeader";
import { ItemListChild } from "./itemListChild";

export const BodySidebar = (props: {
  isHidden?: boolean;
  backgroundColor?: string;
  itemsSidebar?: SidebarItem[];
  hideList: (id: string) => void;
  isHiddenList: Record<string, boolean>;
  activeItem?: string | null;
  selectedItem?: (item: ChildItem) => void;
}) => {
  return (
    <SidebarContainer
      isHidden={props.isHidden}
      backgroundColor={props.backgroundColor}
      key={"bodySidebar"}
    >
      {props.itemsSidebar?.map((item) => (
        <Ul id={item.id} key={item.id}>
          {item?.header && <ItemListHeader item={item} {...props} />}

          {!props.isHiddenList[item.id] &&
            item?.children?.map((childItem) => (
              <ItemListChild item={item} childItem={childItem} {...props} />
            ))}
          <Divider component="li" style={{ marginTop: "0", padding: "0" }} />
        </Ul>
      ))}
    </SidebarContainer>
  );
};
