import React, { useEffect, useState } from "react";
import { Nav, Ul, useStyles, WrapperLabeling } from "./style";
import MetaTags from "react-meta-tags";
import Title from "../../components/title/title";
import { Container } from "reactstrap";
import LoadingComponent from "../../components/loading";
import { DocumentListDocPlace } from "./documentList";
import { connect, useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import ReactRegion from "./RegionSelectP";
import "./styles.css";
import { saveAs } from "file-saver";

import initialDocument from "../../assets/initialDocument.png";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";

import {
  deleteLabeledData,
  getEsignature,
  getEsignatureData,
  getIdRequestSignatureByDocumentId,
  getWorkFlowData,
  saveLabeledData,
  SignDocumentByRequest,
  updateLabeledData,
} from "../../services/signature";
import { toast, ToastContainer } from "react-toastify";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { getDocumentPageList } from "../../store/document/actions";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Tooltip from "@mui/material/Tooltip";
import AttachFile from "../../assets/AttachfileG.svg";
import AttachFileW from "../../assets/AttachfileW.svg";
import MetaData from "../../assets/metaData.svg";
import MetaDataW from "../../assets/metaDataW.svg";
import { DrawerAttachment } from "./workFlow/drawerAttachment/drawerAttachment";
import { DrawerMetaData } from "./workFlow/drawerMetaData/drawerMetaData";
import { DrawerComplete } from "./workFlow/drawerComplete/drawerComplete";
import {
  deleteAttachment,
  deleteAttachmentClearData,
  deleteMetadata,
  deleteMetadataClearData,
  getAllAttachment,
  getAllMetadata,
  postMetadata,
  postMetadataClearData,
} from "../../store/workFlow/actions";
import { ModalConfirmDelete } from "./workFlow/modalConfirmDelete/modalConfirm";
import { getDepartments } from "../../services/approval";
import { ModalConfirmDeleteMetadata } from "./workFlow/modalConfirmDelete/modalConfirmMetadata";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20%",
  bgcolor: "background.paper",
  p: 4,
};

var firstLoad = true;
var running = false;
var _regions = [];
export function StampSignature(props) {
  let params = useParams();
  const { isWorkFlow } = useParams();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [docTypeID, setDocTypeID] = React.useState("");
  const [regions, setRegions] = React.useState([]);
  const [document, setDocument] = useState("");
  const [startPage] = useState(true);
  const dispatch = useDispatch();
  const [labels, setLabels] = useState([]);
  const [fields, setFields] = useState([]);
  const [current, setCurrent] = useState("Not selected");
  const [documents, setDocuments] = useState([]);
  const [currentDocId, setCurrentDocId] = useState(0);
  const [loadingText, setLoadingText] = useState("Loading documents");
  const [deleteOCR, setDeleteOCR] = useState(false);
  const [counter, setCounter] = useState(0);
  const [currentLabelIndex, setCurrentLabelIndex] = useState(0);

  const [enableTrain, setEnableTrain] = useState(false);

  const [hasSignature, setHasSignature] = useState(false);

  const [order, setOrder] = useState(false);

  const [theDoc, setTheDoc] = useState({});

  const [updating, setUpdating] = useState(false);

  const [ocrShow, setOcrShow] = useState(false);

  const [valueText, setValueText] = useState("");

  const [showSigns, setShowSigns] = useState(false);

  const [currentDocument, setCurrentDocument] = useState(initialDocument);

  const [saving, setSaving] = useState(true);

  const [currentRegion, setCurrentRegion] = useState(0);
  const [signature, setSignature] = useState("");
  const [hashSignature, setHashSignature] = useState("");
  const [ssid, setSSID] = useState("");
  const [attachIcon, setAttachIcon] = useState(AttachFile);
  const [metaDataIcon, setMetaDataIcon] = useState(MetaData);
  const [isBrowser, setIsBrowser] = useState(false);
  const [departmentsList, setDepartmentsList] = useState([]);

  //Contains all regions
  const docsigners = JSON.parse(
    localStorage.getItem("docplace-current-signers") || "[]"
  );
  const [allRegions, setAllRegions] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [openDrawerAttach, setOpenDrawerAttach] = React.useState(false);
  const [openDrawerMetaData, setOpenDrawerMetaData] = React.useState(false);
  const [openDrawerComplete, setOpenDrawerComplete] = React.useState(false);
  const [attachmentFile, setAttachmentFile] = React.useState([]);
  const [metaData, setMetaData] = React.useState([]);
  const [openModalConfirm, setOpenModalConfirm] = React.useState(false);
  const [openModalConfirmMetadata, setOpenModalConfirmMetadata] =
    React.useState(false);
  const [attachmentItem, setAttachmentItem] = React.useState(null);
  const [metadataItem, setMetadataItem] = React.useState(null);
  const [documentSigners, setDocumentSigners] = React.useState(docsigners);
  const [departmentList, setDepartmentList] = React.useState([
    {
      id: "1",
      name: "Department 1",
    },
    {
      id: "2",
      name: "Department 2",
    },
    {
      id: "3",
      name: "Department 3",
    },
    {
      id: "4",
      name: "Department 4",
    },
    {
      id: "5",
      name: "Department 5",
    },
    {
      id: "6",
      name: "Department 6",
    },
  ]);

  const { responseDocumentPages, errorDocumentPages, loadingP } = useSelector(
    (state) => ({
      responseDocumentPages: state.DetailFolder.responseDocumentPages,
      errorDocumentPages: state.DetailFolder.errorDocumentPages,
      loadingP: state.DetailFolder.loadingDocumentPages,
    })
  );

  const { responseGetAttachment, errorGetAttachment, loadingGetAttachment } =
    useSelector((state) => ({
      responseGetAttachment: state.WorkFlowProcess.responseGetAttachment,
      errorGetAttachment: state.WorkFlowProcess.errorGetAttachment,
      loadingGetAttachment: state.WorkFlowProcess.loadingGetAttachment,
    }));

  const {
    responseGetDocumentMetadata,
    errorGetDocumentMetadata,
    loadingGetDocumentMetadata,
  } = useSelector((state) => ({
    responseGetDocumentMetadata:
      state.WorkFlowProcess.responseGetDocumentMetadata,
    errorGetDocumentMetadata: state.WorkFlowProcess.errorGetDocumentMetadata,
    loadingGetDocumentMetadata:
      state.WorkFlowProcess.loadingGetDocumentMetadata,
  }));

  console.log("departmentsList", departmentsList);

  const {
    responseDeleteAttachment,
    errorDeleteAttachment,
    loadingDeleteAttachment,
  } = useSelector((state) => ({
    responseDeleteAttachment: state.WorkFlowProcess.responseDeleteAttachment,
    errorDeleteAttachment: state.WorkFlowProcess.errorDeleteAttachment,
    loadingDeleteAttachment: state.WorkFlowProcess.loadingDeleteAttachment,
  }));

  const { responseDeleteMetadata, errorDeleteMetadata, loadingDeleteMetadata } =
    useSelector((state) => ({
      responseDeleteMetadata: state.WorkFlowProcess.responseDeleteMetadata,
      errorDeleteMetadata: state.WorkFlowProcess.errorDeleteMetadata,
      loadingDeleteMetadata: state.WorkFlowProcess.loadingDeleteMetadata,
    }));

  const {
    responsePostDocumentMetadata,
    errorPostDocumentMetadata,
    loadingPostDocumentMetadata,
  } = useSelector((state) => ({
    responsePostDocumentMetadata:
      state.WorkFlowProcess.responsePostDocumentMetadata,
    errorPostDocumentMetadata: state.WorkFlowProcess.errorPostDocumentMetadata,
    loadingPostDocumentMetadata:
      state.WorkFlowProcess.loadingPostDocumentMetadata,
  }));

  useEffect(() => {
    if (params.id !== "null" && params.id) {
      const documentId = params.id;
      dispatch(getDocumentPageList({ id: params.id }, 1, 10, navigate));
      dispatch(getAllAttachment({ id: documentId }));
      dispatch(getAllMetadata({ id: documentId }));
    } else {
      navigate("/explorer");
    }
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      setIsBrowser(true);
    }

    getDepartments(params.id, (event) => {})
      .then((response) => {
        setDepartmentsList(response.data);
      })
      .catch((error) => {
        toast.error("Error loading departments: " + error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [startPage]);

  useEffect(() => {
    if (responseGetAttachment?.data.length > 0) {
      const attachmentList = responseGetAttachment.data.map((item) => ({
        id: item.id,
        fileName: item.fileName,
        url: item.url,
      }));
      setAttachmentFile(attachmentList);
    }
  }, [responseGetAttachment]);

  useEffect(() => {
    if (responseGetDocumentMetadata?.data.length > 0) {
      const metaDataList = responseGetDocumentMetadata.data.map((item) => {
        const contentKey = Object.keys(item.content)[0];
        const contentValue = item.content[contentKey];

        return {
          id: item.id,
          name: contentKey,
          description: contentValue,
        };
      });
      setMetaData(metaDataList);
    }
  }, [responseGetDocumentMetadata]);

  useEffect(() => {
    const documentId = params.id;
    if (responsePostDocumentMetadata) {
      toast.success("Metadata successfully added.");
      dispatch(postMetadataClearData());
      dispatch(getAllMetadata({ id: documentId }));
    }
  }, [responsePostDocumentMetadata]);

  useEffect(() => {
    if (props.activeSign) {
      props.setCallSign(false);
      stampSignature();
    }
  }, [props.activeSign]);

  useEffect(() => {
    if (props.callSign) {
      props.setCallSign(false);
      requestEsignature();
    }
  }, [props.callSign]);


  useEffect(() => {
    const documentId = params.id;
    if (responseDeleteAttachment) {
      toast.success("Attached document successfully deleted.");
      dispatch(getAllAttachment({ id: documentId }));
      setOpenModalConfirm(false);
      dispatch(deleteAttachmentClearData());
    }
  }, [responseDeleteAttachment]);

  useEffect(() => {
    const documentId = params.id;
    if (responseDeleteMetadata) {
      toast.success("Successfully removed metadata.");
      dispatch(getAllMetadata({ id: documentId }));
      setOpenModalConfirmMetadata(false);
      dispatch(deleteMetadataClearData());
    }
  }, [responseDeleteMetadata]);

  useEffect(() => {
    if (props.id) {
      setDocTypeID(params.id);
    }
    if (startPage && responseDocumentPages && responseDocumentPages.data) {
      //@params props.documentTypeID
      setLoading(true);
      getEsignature(
        JSON.parse(localStorage.getItem("docplace")).userId,
        (event) => {}
      )
        .then((response) => {
          setSignature(response.data.data[0].path);
          setHashSignature(response.data.data[0].id);
          if (!isWorkFlow) {
            getIdRequestSignatureByDocumentId(props.id, (event) => {})
              .then((response) => {
                console.log("response sssid", response.data.id);
                setSSID(response.data.id);
              })
              .catch((error) => {
                toast.error(
                  "There was an error retrieving the request signature id. Please contact your administrator." +
                    error
                );
              }); 
          }
        })
        .catch((error) => {
          toast.error(
            "There was an error retrieving the signatures. Please contact your administrator." +
              error
          );
        });

      const fetchFunction = isWorkFlow ? getWorkFlowData : getEsignatureData;

      fetchFunction(params.id, (event) => {})
        .then((response) => {
          if (Object.keys(response.data).length === 0) {
            setLoading(false);
            console.log("No data");
            toast.error("There is no available data for this document.");

            setUpdating(false);
          } else {
            console.log("Data for this document, has been found.");

            let data = response.data;

            //setLabels(response.data.labels)
            setRegions([]);
            _regions = [];
            setFields(data.labels);

            setDocument(data.documents[0].thumbnail);
            setCurrentDocument(data.documents[0].thumbnail);

            setCurrent("Not selected");
            setDocuments(data.documents);
            setCurrentDocId(-1);
            setCounter(data.counter);
            setCurrentLabelIndex(data.currentLabelIndex);
            //setTheDoc(data.theDoc);
            setAllRegions(data.allRegions);
            setCurrentDocId(0);
            let enable = false;
            let tempDocs = data.documents;

            for (var i = 0; i < data.allRegions.length; i++) {
              let obj = data.allRegions[i];

              let newArray = obj.filter(function (el) {
                return el.type === 1 && el.data.label !== "";
              });
              if (newArray.length > 0) {
                setEnableTrain(true);
                break;
              }
            }

            setUpdating(true);
          }
        })
        .catch((error) => {
          toast.error(
            "There is an error loading the document info. Please contact your administrator." +
              error
          );
        })
        .finally(() => {
          setLoading(false);
        });

      firstLoad = false;
    }
  }, [startPage, responseDocumentPages, isWorkFlow]);

  useEffect(() => {
    try {
      _regions = [...regions];
      if (currentDocId >= 0) {
        let allRegionsTemp = allRegions;
        allRegionsTemp[currentDocId] = regions;
        setAllRegions(allRegionsTemp);
      }
    } catch (error) {
      console.log("Error creating regions from OCR: " + error);
    }
  }, [allRegions, regions]);

  function checkRequestSignature() {
    let electronicMail = JSON.parse(localStorage.getItem("docplace")).email;
    let result = false;
    let temp = [...allRegions];
    let validArray = [];
    let lock = 0;
    let locked = false;

    for (var i = 0; i < temp.length; i++) {
      let xm = temp[i];
      for (var x = 0; x < xm.length; x++) {
        if (xm[x].data.email === electronicMail) {
          if (xm[x].data.text.includes("esignature #")) {
            console.log("Found a signature stamp.", xm[x].data.text);
            lock++;
            locked = true;
            if (xm[x].data.signed && xm[x].data.signed === true)
              validArray.push(electronicMail);
          }
        }
      }
    }

    result = lock === validArray.length && locked === true;
    return result;
  }

  const onChange = (regions) => {
    _regions = [...regions];
    setRegions(regions);
  };

  const selectRegion = (event, id, data, type) => {
    
    var regionP = [...regions];
    switch (event.detail) {
      case 1: {
        if (type === 1) {
          setShowSigns(true);
          setCurrentRegion(id);
          setHasSignature(
            regionP[id].data.image != null ||
              regionP[id].data.image ||
              regionP[id].data.image !== undefined
          );
        }

        break;
      }
      case 2: {
        break;
      }

      default: {
        break;
      }
    }
  };

  const requestEsignature = () => {
    setLoadingText("Requesting eSignature");

    if (checkRequestSignature() === true) {
      //setLoadingText("Requesting eSignature");
      setLoading(true);
      SignDocumentByRequest(ssid, (event) => {})
        .then((response) => {
          toast.success("The document has been signed.");
        })
        .catch((error) => {
          toast.error("Error signing the document: " + error);
        })
        .finally(() => {
          navigate(`/docplaceSign`);
          setLoading(false);
        });
    } else {
      toast.warning("You must fill all  signatures in the document.");
    }
  };

  const regionRenderer = (regionProps) => {
    if (!regionProps.isChanging) {
      return <div></div>;
    }
  };

  const regionStyleStart = {
    background: "rgba(255,255,255, 20%)",
    border: "1px solid black",
    borderRadius: "10px",
  };

  const regionStyle = {
    background: "rgba(255,255,0, 80%)",

    border: "1px solid black",
    borderRadius: "10px",
  };

  const getDocument = (
    document,
    id,
    fileName,
    filePath,
    ocr,
    doc,
    fileOriginalName
  ) => {
    setTheDoc(doc);

    setRegions([]);
    _regions = [];
    setRegions(allRegions[id]);
    _regions = [allRegions[id]];
    setCurrentDocId(id);
    setDocument(document);
    setCurrentDocument(document);
  };

  const deleteData = (opt) => {
    setSaving(false);
    setLoadingText("Deleting data.");
    setLoading(true);

    deleteLabeledData(params.id, (event) => {})
      .then((response) => {
        window.location.reload(false);
      })
      .catch((error) => {
        toast.error("Error deleting the data: " + error);
      })
      .finally(() => {
        setLoading(false);
        setOpen(false);
        checkRequestSignature();
      });
  };

  const stampSignature = (remove = false) => {
    props.setActiveSign(true);
    let id = currentRegion;
    let regionP = [...regions];
    if (remove === true) {
      regionP[id].data = {
        image: null,
        signed: false,
        hash: null,
        label: regionP[id].data.label,
        email: regionP[id].data.email,
        freeText: regionP[id].data.freeText,
        text: regionP[id].data.text,
        boundingBoxes: regionP[id].data.boundingBoxes,
        width: regionP[id].data.width,
        height: regionP[id].data.height,
        regionStyle: regionStyle,
      };
    } else {
      regionP[id].data = {
        image: signature,
        signed: true,
        hash: hashSignature,
        label: regionP[id].data.label,
        email: regionP[id].data.email,
        freeText: regionP[id].data.freeText,
        text: regionP[id].data.text,
        boundingBoxes: regionP[id].data.boundingBoxes,
        width: regionP[id].data.width,
        height: regionP[id].data.height,
        regionStyle: regionStyle,
      };
    }
    setRegions(regionP);
    _regions = [...regionP];
    setShowSigns(false);
  };

  /* Persist data */
  const saveLabels = (docdata, docArray, close, reload) => {
    setLoadingText("Saving data");
    setLoading(true);
    let _documents, _allRegions;
    if (docdata) {
      _documents = docdata;
      _allRegions = docArray;
    } else {
      _documents = [...documents];
      _allRegions = [...allRegions];
    }

    let _document = document;

    if (document == "") {
      setDocument(_documents[0].thumbnail);
      _document = _documents[0].thumbnail;
    }
    let data = {
      region: regions,
      allRegions: _allRegions,
      labels: labels,
      document: _document,
      current: current,
      documents: _documents,
      currentDocId: currentDocId,
      counter: counter,
      currentLabelIndex: currentLabelIndex,
      doc: theDoc,
    };
    if (updating == true) {
      updateLabeledData(params.id, data, (event) => {})
        .then((response) => {
          //toast.success("Document has been updated");

          setSaving(true);
        })
        .catch((error) => {
          toast.error("Error updating the data: " + error);
          setSaving(false);
        })
        .finally(() => {
          setLoading(false);
          if (reload) {
            window.location.reload(false);
          }
        });
    } else {
      saveLabeledData(params.id, data, (event) => {})
        .then((response) => {
          setUpdating(true);
          toast.success("Document changes has been saved");

          setSaving(true);
        })
        .catch((error) => {
          toast.error("Error saving the data: " + error);
          setSaving(false);
        })
        .finally(() => {
          setLoading(false);
          checkRequestSignature();
          if (reload) {
            window.location.reload(false);
          }
        });
    }
  };

  const deleteAttachmentOpenModal = (item) => {
    setOpenModalConfirm(true);
    setAttachmentItem(item);
  };

  const deleteMetadataOpenModal = (item) => {
    setOpenModalConfirmMetadata(true);
    setMetadataItem(item);
  };

  const confirmDeleteAttachment = (item) => {
    dispatch(deleteAttachment({ id: item.id }));
  };

  const confirmDeleteMetadata = (item) => {
    dispatch(deleteMetadata({ id: item.id }));
  };

  const downloadAttachment = async (url, fileName) => {
    if (!isBrowser) {
      console.error("Not running in a browser environment");
      return;
    }

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      saveAs(blob, fileName);
      console.log(`Download initiated: ${fileName}`);
    } catch (error) {
      toast.error(`Failed to download file ${fileName}`);
    }
  };

  const onSendMetadata = (data) => {
    const documentId = params.id;
    dispatch(postMetadata(documentId, data));
  };

  return (
    <>
      <LoadingComponent
        show={loadingDeleteAttachment || loadingDeleteMetadata}
        text={
          loadingDeleteAttachment
            ? "Delete document attachment"
            : "Delete metadata"
        }
      />
      <ModalConfirmDelete
        openModalConfirm={openModalConfirm}
        setOpenModalConfirm={setOpenModalConfirm}
        attachmentItem={attachmentItem}
        confirmDeleteAttachment={confirmDeleteAttachment}
      />
      <ModalConfirmDeleteMetadata
        openModalConfirmMetadata={openModalConfirmMetadata}
        setOpenModalConfirmMetadata={setOpenModalConfirmMetadata}
        metadataItem={metadataItem}
        confirmDeleteMetadata={confirmDeleteMetadata}
      />
      <DrawerAttachment
        openDrawerAttach={openDrawerAttach}
        onClose={() => setOpenDrawerAttach(false)}
        attachmentFile={attachmentFile}
        documentId={params.id}
        deleteAttachment={deleteAttachmentOpenModal}
        downloadAttachment={downloadAttachment}
      />
      <DrawerMetaData
        openDrawerMetaData={openDrawerMetaData}
        onClose={() => setOpenDrawerMetaData(false)}
        metaData={metaData}
        onSendMetadata={onSendMetadata}
        deleteMetadataOpenModal={deleteMetadataOpenModal}
      />
      <DrawerComplete
        openDrawerComplete={openDrawerComplete}
        onClose={() => setOpenDrawerComplete(false)}
        departmentList={departmentList}
      />

      <LoadingComponent show={loading} text={loadingText} />

      {
        // Train OCR MODAL
      }

      <Modal
        open={showSigns}
        onClose={() => setShowSigns(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add your signature
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This action will place your signature in the selected area. <br />
          </Typography>
          <div style={{ width: "100%", height: "200px", textAlign: "center" }}>
            <img
              style={{
                width: "100%",
                height: "200px",
                textAlign: "center",
                cursor: "pointer",
              }}
              src={signature}
            />
          </div>

          <Button
            variant="contained"
            color="success"
            onClick={() => stampSignature()}
            sx={{ mt: 2, mr: 2, float: "left" }}
          >
            Add
          </Button>
          <Button
            variant="contained"
            color="warning"
            disabled={!hasSignature}
            onClick={() => stampSignature(true)}
            sx={{ mt: 2, float: "left" }}
          >
            Remove
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setShowSigns(false)}
            sx={{ mt: 2, float: "right" }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
 <WrapperLabeling>
        <ToastContainer autoClose={3000} />
       
        <Grid container>
          <Grid className={classes.wrapperAside} item xs={2} md={2}>
            <DocumentListDocPlace
              documents={documents}
              deleteOCR={deleteOCR}
              getDocument={getDocument}
            />
          </Grid>
          <Grid className={classes.wrapperAside} item xs={10} md={10}>
            <div className="documentShowcase">
              <ReactRegion
                style={{width: '65%'}}
                maxRegions={1000}
                regions={regions}
                signers={documentSigners}
                onChange={onChange}
                regionStyle={regionStyle}
                regionRenderer={regionRenderer}
                constraint
                onClick={selectRegion}
              >
                <img
                  style={{ width: "100%", pointerEvents: "none" }}
                  src={currentDocument}
                  alt="Current document"
                />
              </ReactRegion>
            </div>
          </Grid>
        </Grid>
      </WrapperLabeling>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loadingTree: state.loadingFolders,
    tree: state.folders,
  };
};

export default connect(mapStateToProps, {})(StampSignature);
