import React, { useEffect, useState } from "react";
import PlaceSignature from "../../../PlaceSignature/labeling.js";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import { useStyles } from "../../../PlaceSignature/style.js";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { toast, ToastContainer } from "react-toastify";
import TagMenu from "@mui/icons-material/KeyboardArrowDown";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  DPWrapperReWrapper,
  DPWrapperReWrapperStepper,
  DPWrapperReWrapperStepperDescription,
  DPWrapperReWrapperStepperStep,
  DPWrapperReWrapperStepperTitle,
  DPWrapperReWrapperViewer,
  DPWrapperReWrapperViewerHeader,
} from "../../style/docplace_sign_module.styles";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import ListItem from "@mui/material/ListItem";
import CreateIcon from "@mui/icons-material/BorderColor";
import Drag from "@mui/icons-material/DragIndicatorSharp";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/AccountCircle";
import { Autocomplete, Button, TextField } from "@mui/material";
import { expressions } from "../../../../components/theme";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Name from "@mui/icons-material/Person";
import Calendar from "@mui/icons-material/Abc";
import TextField2 from "@mui/icons-material/SortByAlpha";
import DateIcon from "@mui/icons-material/DateRange";
import TimeStampIcon from "@mui/icons-material/AccessTime";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import List from "@mui/material/List";
import { rolPutSuccess } from "../../../../store/actions.js";

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  userSelect: "none",
  position: "relative",
  background: isDragging ? "transparent" : "white",
  opacity: isDragging ? "0.1" : "1",
  ...draggableStyle,
});

interface ViewProps {
  document:any;
  users:any;
  setOrder:any;
  order:any;
  regions?:any;
  showOrder:any;
  callSaveAndClose?: boolean;
  callRequestSign?: boolean;
  callCreateRegion?: any;
  callSelectLabel?: any;
  setCallSendRequestSign:(value: boolean) => void;
  callSendRequestSign?: boolean;
  setCallSelectLabel: (params: {call: boolean, label: string, idx: string, text: string, val: string}) => void;
  setShowOrder:(value: boolean) => void;
  setShowRequest:(value: boolean) => void;
  setCallSaveAndClose: (value: boolean) => void;
  setCallCreateRegion: (params: {call: boolean, label: number}) => void;
  setCallRequestSign: (value: boolean) => void;
}

const NbRequestSignViewerView: React.FC<ViewProps> = ({ ...props }) => {
  const [showRequest, setShowRequest] = useState(false);

  const [document, setDocument] = useState("");
  const [colorArray, setColorArray] = useState([
    "255, 225, 25",
    "0, 130, 200",
    "230, 25, 75",
    "60, 180, 75",
    "245, 130, 48",
    "145, 30, 180",
    "70, 240, 240",
    "240, 50, 230",
    "210, 245, 60",
    "250, 190, 212",
    "0, 128, 128",
    "220, 190, 255",
    "170, 110, 40",
    "255, 250, 200",
    "128, 0, 0",
    "170, 255, 195",
    "128, 128, 0",
    "255, 215, 180",
    "0, 0, 128",
    "128, 128, 128",
    "255, 255, 255",
    "0, 0, 0",
  ]);
  const [regions, setRegions] = React.useState<any[]>([]);
  const [step, setStep] = React.useState(1);
  const [fields, setFields] = useState([]);
  const [counter, setCounter] = useState(0);
  const [deleteLabels, setDeleteLabels] = useState(false);
  const [currentDocId, setCurrentDocId] = useState(0);
  const [labels, setLabels] = useState([{ label: "", orderSend: false }]);
  const [label, setLabel] = useState("");
  const [open, setOpen] = React.useState(false);
  const [current, setCurrent] = useState("Not selected");
  const [currentLabel, setCurrentLabel] = useState("");
  const [tempSignatures, setTempSignatures] = React.useState<any[]>([]);
  const [signatures, setSignatures] = React.useState([
    { order: 1, name: "", email: "", external: false, cc: "1" },
  ]);

  const classes = useStyles();

  const getLabels = (labels: any) => {
    setLabels(labels);
  };

  useEffect(() => {
    getLabels(labels);
    if (labels.length > 0) {
      if ("orderSend" in labels[0] && labels[0].orderSend === true) {
        props.setShowOrder(true);
      }
    }
  }, [labels]);

  useEffect(() => {
    setLabels(fields);
    if (fields.length > 0) {
      let temp: any = [];
      fields.map((item: any, i: number) => {
        let order = item.order ? item.order : i;
        temp.push({
          order: order,
          name: item.label,
          email: item.email,
          external: item.external,
          cc: item.cc,
        });
      });
      console.log("temp *********", temp);
      setSignatures(temp);
    }
  }, [fields]);

  const deleteText = (text: any, label: any) => {
    //props.deleteText(text, label);
  };

  const handleChangeShowOrder = (event: any) => {
    props.setShowOrder(event.target.checked);
    //props.order(event.target.checked);
  };

  const setOrder = (i: any, event: any) => {
    let signs = [...signatures];
    signs[i].order = event.target.value;

    let sorted = signs.sort((p1, p2) =>
      p1.order > p2.order ? 1 : p1.order < p2.order ? -1 : 0
    );
    //setSignatures(signs);
  };

  const setEmail = (i: any, event: any) => {
    let signs = [...signatures];
    signs[i].email = event.target.value;
    //setSignatures(signs);
  };

  const setType = (i: any, type: any) => {
    let signs = [...signatures];
    signs[i].external = type;
    //setSignatures(signs);
  };

  const setCC = (i: any, event: any) => {
    let signs = [...signatures];
    signs[i].cc = event.target.value;
    setSignatures(signs);
  };

  const deleteAllTags = (label: any) => {
    try {
      if (props.regions && props.regions.length > 0) {
        let result = props.regions.filter(function (item: any) {
          return item.data.label === label;
        });
        result.map((item: any, i: any) => {
          deleteText(item.data.text, label);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isSelected = (text:any) => {
    try {
      if (props.regions && props.regions.length > 0) {
        let result = props.regions.filter(function (freelancer:any) {
          return freelancer.data.label === text;
        });
        //We found the match, hence it has been selected
        return typeof result[0] !== "undefined";
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addSignature = () => {
    let signs = [...signatures];

    signs.push({
      order: signs.length + 1,
      name: "",
      email: "",
      external: true,
      cc: "1",
    });

    setSignatures(signs);
  };

  const handleClose = () => setOpen(false);

  const deleteLabel = (txt: any) => {
    let newLabel = labels;
    newLabel = newLabel.filter((e) => e.label !== txt);
    setLabels(newLabel);
    setLabel("");
    handleClose();

    deleteAllTags(txt);
    //props.deleteRegion(txt);

    let newS = signatures;
    newS = newS.filter((e) => e.name !== txt);
    setSignatures(newS);
  };

  const removeSignature = (i: any) => {
    let signs = [...signatures];
    deleteLabel(signs[i].name);
    signs.splice(i, 1);
    setSignatures(signs);
  };

  const setName = (i: any, event: any) => {
    let signs = [...signatures];
    if (event.target.value === 0) {
      signs[i].name = event.target.innerText;

      let us = [...props.users];

      let item = us.filter(
        (e) => e.label.trim() === event.target.innerText.trim()
      );

      if (item.length > 0) {
        let email = {
          target: {
            value: item[0].email,
          },
        };
        setEmail(i, email);
        setType(i, false);
      }
    } else {
      signs[i].name = event.target.value;
    }
    setSignatures(signs);
    //addLabel(i, signs[i].name, signs[i].email)
  };

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    if (props.showOrder === false) {
      return;
    }
    if (!result.destination) {
      return;
    }

    const items = reorder(
      signatures,
      result.source.index,
      result.destination.index
    );

    items.map((item: any, i: number) => {
      item.order = i + 1;
    });
    setSignatures(
      items as {
        order: number;
        name: string;
        email: string;
        external: boolean;
        cc: string;
      }[]
    );
  };

  useEffect(() => {
    getLabels(labels);
    if (labels[0]?.orderSend === true) {
      props.setShowOrder(true);
    }
  }, [labels]);

  const validSigners = () => {
    let signs = [...signatures];
    let email = signs.filter((e, i) => expressions.email.test(e.email));
    let name = signs.filter((e, i) => e.name.length > 2);

    return email.length === signs.length && name.length === signs.length;
  };

  const addLabel = (i:any, signature: any, email:any, type:any, cc:any) => {
    let newLabel = labels;
    let data = {
      label: signature,
      email: email,
      order: i,
      external: type,
      cc: cc,
      orderSend: props.showOrder,
    };
    newLabel[i] = data;
    setLabel("");
    getLabels(labels);
  };

  const updateRecipients = () => {
    if(step===1){
        if (validSigners() === true) {
              signatures.map((item, i) => {
                addLabel(i, item.name, item.email, item.external, item.cc);
              }); 
              props.setCallSaveAndClose(true);
            } else {
              toast.error(
                "Please check the values. The email must be valid and the name should contain more at least 3 characters."
              );
            }
            setStep(2);
    }else if(step===2){
      props.setCallRequestSign(true);
      setStep(3);
    } else if(step===3) {
      props.setCallSendRequestSign(true);
    }
  };

  const handleText = () => {};

  const showSigners = () => {
    let temp = [];
    temp = signatures.map((object) => ({ ...object }));

    if (temp.length === 1 && temp[0].name === "" && temp[0].email === "") {
      setTempSignatures([
        { order: 1, name: "", email: "", external: false, cc: "1" },
      ]);
    } else {
      setTempSignatures(temp);
    }
  };

 
  return (
    <DPWrapperReWrapper>
      <DPWrapperReWrapperViewer>
        <DPWrapperReWrapperViewerHeader></DPWrapperReWrapperViewerHeader>
        {props.document.id && (
          <PlaceSignature
            idDocument={props.document.id}
            colorArray={colorArray}
            setRegions={setRegions}
            setFields={setFields}
            fields={fields}
            regions={regions}
            setDeleteLabels={setDeleteLabels}
            deleteLabels={deleteLabels}
            labels={labels}
            setStep={setStep}
            setCallSendRequestSign={props.setCallSendRequestSign}
            callSendRequestSign={props.callSendRequestSign}
            setCallRequestSign={props.setCallRequestSign}
            callRequestSign={props.callRequestSign}
            setCallSaveAndClose={props.setCallSaveAndClose}
            callSaveAndClose={props.callSaveAndClose}
            callCreateRegion={props.callCreateRegion}
            setCallCreateRegion={props.setCallCreateRegion}
            callSelectLabel={props.callSelectLabel}
            setCallSelectLabel={props.setCallSelectLabel}
            setCurrent={setCurrent}
            setdocument={setDocument}
          />
        )}
      </DPWrapperReWrapperViewer>
      <DPWrapperReWrapperStepper>
        <DPWrapperReWrapperStepperTitle>
          Signers
          <DPWrapperReWrapperStepperStep>
            Step {step} of 3
          </DPWrapperReWrapperStepperStep>
        </DPWrapperReWrapperStepperTitle>
        <DPWrapperReWrapperStepperDescription>
        {(() => {
            switch (step) {
              case 1:
                return "Add the people who will sign the document.";
              case 2:
                return "Add all relevant fields for each recipient.";
              default:
                return "Preparation of the document for signature."; 
            }
          })()}
        </DPWrapperReWrapperStepperDescription>

        <article>
          {
            step === 1 && (
                  <>
                              <FormControl fullWidth sx={{ mt: 2, ml:3 }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultValue={props.showOrder ? "true" : "false"}
                              checked={props.showOrder}
                              onChange={handleChangeShowOrder}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Enable signing order"
                        />
                      </FormGroup>
                    </FormControl>
                    
                      <DragDropContext onDragEnd={onDragEnd}>
                      <List
                          sx={{
                            width: "100%",
                            bgcolor: "background.paper",
                            height: "fit-content",
                          }}
                        >
                          <Droppable
                            droppableId="droppable"
                            
                          >
                            {(provided, snapshot) => (
                              <div {...provided.droppableProps} ref={provided.innerRef}>
                                {signatures.map((item, i) => {
                                  return (
                                    <Draggable
                                      key={i}
                                      draggableId={`item-${i}`}
                                      isDragDisabled={!props.showOrder}
                                      index={i}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <ListItem key={i}>
                                            {props.showOrder && (
                                              <>
                                                <input
                                                  type="number"
                                                  min="1"
                                                  value={signatures[i].order}
                                                  onChange={(event) => setOrder(i, event)}
                                                  style={{
                                                    width: "40px",
                                                    textAlign: "center",
                                                    padding: "4px",
                                                    border: "1px solid lightgray",
                                                    borderRadius: "4px",
                                                  }}
                                                ></input>
                                                <Drag />
                                              </>
                                            )}
                                            {signatures[i].external === false ? (
                                              <ListItemAvatar>
                                                <Avatar
                                                  style={{
                                                    backgroundColor: "rgb(0, 106, 198)",
                                                  }}
                                                >
                                                  <ImageIcon />
                                                </Avatar>
                                              </ListItemAvatar>
                                            ) : (
                                              <ListItemAvatar>
                                                <Avatar>
                                                  <ImageIcon />
                                                </Avatar>
                                              </ListItemAvatar>
                                            )}
                                            <Autocomplete
                                              disablePortal
                                              sx={{ ml: 1, flex: 2 }}
                                              id="doctype-id"
                                              freeSolo
                                              options={props.users}
                                              disableClearable
                                              inputValue={signatures[i].name}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  required
                                                  error={signatures[i].name.length < 3}
                                                  onChange={(event) => setName(i, event)}
                                                  value={signatures[i].name}
                                                  label="Add Name"
                                                />
                                              )}
                                              onChange={(value) => setName(i, value)}
                                            />
                                            <Autocomplete
                                              disablePortal
                                              sx={{ ml: 1, flex: 2 }}
                                              id="doctype-ids"
                                              freeSolo
                                              options={[{ label: "" }]}
                                              disableClearable
                                              inputValue={signatures[i].email}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  required
                                                  onChange={(event) => setEmail(i, event)}
                                                  error={
                                                    !expressions.email.test(
                                                      signatures[i].email
                                                    )
                                                  }
                                                  label="Add Email"
                                                />
                                              )}
                                              onChange={(value) => setEmail(i, value)}
                                            />
                                            <FormControl sx={{ ml: 1, flex: 1.5 }}>
                                              <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                required
                                                value={signatures[i].cc}
                                                defaultValue={signatures[i].cc ?? 1}
                                                variant="outlined"
                                                onChange={(event) => setCC(i, event)}
                                              >
                                                <MenuItem value={1}>Signer</MenuItem>
                                                <MenuItem value={2}>CC</MenuItem>
                                              </Select>
                                            </FormControl>

                                            <IconButton
                                              onClick={() => removeSignature(i)}
                                              color="primary"
                                              sx={{ p: "2px" }}
                                            >
                                              <DeleteIcon sx={{ p: "2px" }} key={i + 200} />
                                            </IconButton>
                                          </ListItem>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                })}
                              </div>
                            )}
                          </Droppable>
                        </List>
                      </DragDropContext>
                      <Button
                          variant="contained"
                          color="info"
                          onClick={() => addSignature()}
                          sx={{ mt: 2, ml: 2, float: "left", width: "150px" }}
                        >
                          Add Signer
                      </Button>
                  </>
            )
          }
        </article>

        <article>
        {
            step === 2 && (
              <div>
                <Grid container style={{border:'none', marginTop: '15px', marginBottom: '15px'}} className="toolbarLabeling">
                  <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
                        <Button
                          color="primary"
                          component="label"
                          onClick={() => props.setCallCreateRegion({call: true,label: 1})}
                          style={{ marginLeft: "10px", width: "20px", textAlign: "left" }}
                          variant="contained"
                          size="small"
                          title="Add eSignature"
                          disabled={
                            current === "Not selected" ||
                            document === "" ||
                            currentDocId === -1
                          }
                        >
                          <CreateIcon />
                        </Button>
                        <Button
                          color="primary"
                          component="label"
                          onClick={() => props.setCallCreateRegion({call: true,label: 2})}
                          style={{ marginLeft: "10px", width: "20px", textAlign: "left" }}
                          variant="contained"
                          size="small"
                          title="Add Initials"
                          disabled={
                            current === "Not selected" ||
                            document === "" ||
                            currentDocId === -1
                          }
                        >
                          <TextField2 />
                        </Button>

                        <Button
                          color="primary"
                          component="label"
                          onClick={() => props.setCallCreateRegion({call: true,label: 4})}
                          style={{ marginLeft: "10px", width: "20px", textAlign: "left" }}
                          variant="contained"
                          size="small"
                          title="Add full name"
                          disabled={
                            current === "Not selected" ||
                            document === "" ||
                            currentDocId === -1
                          }
                        >
                          <Name />
                        </Button>
                        <Button
                          color="primary"
                          component="label"
                          onClick={handleText}
                          style={{ marginLeft: "10px", width: "20px", textAlign: "left" }}
                          variant="contained"
                          size="small"
                          title="Add text"
                          disabled={
                            current === "Not selected" ||
                            document === "" ||
                            currentDocId === -1
                          }
                        >
                          <Calendar />
                        </Button>
                        <Button
                          color="primary"
                          component="label"
                          onClick={() => props.setCallCreateRegion({call: true,label: 8})}
                          style={{ marginLeft: "10px", width: "20px", textAlign: "left" }}
                          variant="contained"
                          size="small"
                          title="Add date (mm-dd-yyyy)"
                          disabled={
                            current === "Not selected" ||
                            document === "" ||
                            currentDocId === -1
                          }
                        >
                          <DateIcon />
                        </Button>
                        <Button
                          color="primary"
                          component="label"
                          onClick={() => props.setCallCreateRegion({call: true,label: 9})}
                          style={{ marginLeft: "10px", width: "20px", textAlign: "left" }}
                          variant="contained"
                          size="small"
                          title="Add Time stamp (mm-dd-yyyy hh:mm:ss)"
                          disabled={
                            current === "Not selected" ||
                            document === "" ||
                            currentDocId === -1
                          }
                        >
                          <TimeStampIcon />
                        </Button>
                  </Grid>
                  <Grid item xs={12} md={12} sx={{ textAlign: "right" }}> 
                  <div className="labelsList" key={96856} style={{marginTop:'30px'}}>
                      {labels.map(
                        (text:any, i: any) =>
                          text.label !== "" && (
                            <div key={i + 100} style={{}}>
                              <Paper
                                component="form"
                                style={{
                                  
                                }}
                                className={
                                  currentLabel === text.label
                                    ? 'ltLabelSelected'
                                    : 'ltLabelUnselected'
                                }
                                key={i}
                                variant="outlined"
                                square
                                sx={{ p: "1px 1px", display: "flex", alignItems: "left" }}
                              >
                                <div
                                  className="bm"
                                  style={{
                                    backgroundColor: `rgb(${colorArray[text?.order]})`,
                                  }}
                                ></div>
                                <div
                                  title={`${text.label} [${text.type} - ${text.fmt}]`}
                                  onClick={() => {props.setCallSelectLabel({call:true,label:text.label, idx:i, text:text, val:text});  setCurrentLabel(text.label);}}
                                  className={
                                    isSelected(text.label)
                                      ? 'ltSelected'
                                      : 'ltUnSelected'
                                  }
                                  key={i}
                                >
                                  {text.label}
                                </div>

                                <IconButton
                                  onClick={showSigners}
                                  color="primary"
                                  sx={{ p: "2px" }}
                                >
                                  <TagMenu sx={{ p: "2px" }} key={i + 200} />
                                </IconButton>
                              </Paper>
                              <div className={'textList'} key={i + 800}>
                                {text.label} ik
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            )
        }
        </article>  
        
        <article>
          {
            step === 3 && (
              <div style={{
                fontSize: '18px',
                margin: '226px auto',
                width: '315px',
                fontWeight: '100'}}>Your document is correctly configured for signature requests. Press 'Send' to request all signatures </div>
            )
          }
        </article>
        
        <div style={{width: '100%',
                      display: 'inline-block',
                  position: 'absolute',
                      bottom: '20px',
    left: '13px'}}>
          <Button
              variant="contained"
              style={{backgroundColor: '#F5F5F5',color: '#444', border: 0, boxShadow: 'none',}}
              color="info"
              onClick={() => props.setShowRequest(false)}
              sx={{ mt: 2, ml: 2, float: "left", width: "230px" }}
            >
              Go Back
          </Button>
          <Button
              variant="contained"
              color="info"
              onClick={() => updateRecipients()}
              sx={{ mt: 2, ml: 2, float: "left", width: "230px" }}
            >
            {
              step === 3 ? "Send" : "Continue"
            }  
          </Button>
        
        </div>
      </DPWrapperReWrapperStepper>
    </DPWrapperReWrapper>
  );
};

export default NbRequestSignViewerView;
