import { Nb_SearchFiltersProps } from "../type/searchFilters.type";
import {
  CanonicalPath,
  ContainerList,
  ContentImageList,
  DocumentName,
  DocumentType,
  ImageList,
  LiItem,
  LiLastItem,
  UL,
  WrapperCanonicalPath,
  WrapperDocumentInfo,
} from "../style/style";
import PdfDocument from "../../../assets/svg/Docplace Menu (4).svg";

export const ListView = (props: Partial<Nb_SearchFiltersProps>) => {
  const { onClickViewAll = () => {} } = props;
  const { onClickList = () => {} } = props;
  const { hideListFn = () => {} } = props;
  return (
    <ContainerList isHidden={props.isHidden}>
      {props.documentList && props.documentList?.length > 0 && (
        <UL>
          {props.documentList?.map((doc) => (
            <LiItem
              onClick={() => {
                onClickList?.(doc);
                hideListFn(true);
              }}
            >
              <WrapperDocumentInfo>
                <ContentImageList>
                  <ImageList src={PdfDocument} alt={"logo"} />
                </ContentImageList>
                <DocumentName>{doc.documentName}</DocumentName>
                <DocumentType>{doc.documentType}</DocumentType>
              </WrapperDocumentInfo>
              <WrapperCanonicalPath>
                <CanonicalPath>{doc.path}</CanonicalPath>
              </WrapperCanonicalPath>
            </LiItem>
          ))}
          <LiLastItem
            onClick={(event) => {
              onClickViewAll?.(event);
              hideListFn(true);
            }}
          >
            View All
          </LiLastItem>
        </UL>
      )}
    </ContainerList>
  );
};
