import { Container } from "../style/style";
import { ChildItem, SidebarItem } from "../interface";
import { HeaderSidebarComponent } from "./headerSidebar";
import { BodySidebar } from "./bodySidebar";

export const NbSidebarView = (props: {
  backgroundColor?: string;
  bgColorHeader?: string;
  hideDrawer: () => void;
  hideList: (id: string) => void;
  isHidden?: boolean;
  isHiddenList: Record<string, boolean>;
  logoHeader?: string;
  iconHeader?: string;
  itemsSidebar?: SidebarItem[];
  activeItem?: string | null;
  selectedItem?: (item: ChildItem) => void;
}) => {
  return (
    <Container>
      <HeaderSidebarComponent {...props} />
      <BodySidebar key={"bodyS"} {...props} />
    </Container>
  );
};
