import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import store from "./store";
import './Fonts/AlexBrush/AlexBrush-Regular.ttf';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './DocPlace/LoginSAML/authConfig';
const msalInstance = new PublicClientApplication(msalConfig);



const app = (

  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </Provider>
)

if (window.location.hash !== '') {
  console.log("hash found" + window.location.hash);
}
else {

  ReactDOM.render(app, document.getElementById("root"))
}