import React, { useState, useEffect, useRef } from "react";
import "./Agent.css";
import up from "../../assets/up.png";
import DocAssistantIcon from "../../assets/assistantIcon.png";
import CachedIcon from "@mui/icons-material/Autorenew";
import IconButton from "@mui/material/IconButton";
import { toast, ToastContainer } from "react-toastify";

export const AgentChat = (props) => {
    const [input, setInput] = useState("");
    const [answered, setAnswered] = useState(false);
    const [showInfo, setShowInfo] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [info] = useState(JSON.parse(localStorage.getItem("docplace")));
    const [chatHistory, setChatHistory] = useState([{ question: "", answer: "", docReferences: [] }]);
    
    const notify = (msj, event) => toast[event](msj);

    const TypingResponse = ({ text, speed = 10, onDone }) => {
        const [displayedText, setDisplayedText] = useState("");
        const [isTyping, setIsTyping] = useState(false);

        useEffect(() => {
            let index = 0;
            setIsTyping(true);
            const interval = setInterval(() => {
                if (index < text.length) {
                    setDisplayedText((prev) => prev + text[index]);
                    index++;
                } else {
                    clearInterval(interval);
                    setIsTyping(false);
                    if (onDone) onDone();
                }
            }, speed);

            return () => clearInterval(interval);
        }, [text, speed, onDone]);

        useEffect(() => {
            const container = document.querySelector(".agent-container");
            if (container) {
                container.scrollTop = container.scrollHeight;
            }
        }, [displayedText]);

        return <div dangerouslySetInnerHTML={{ __html: displayedText }} />;
    };

    const setCustomerId = () => {
        const docplaceData = localStorage.getItem("docplace");
        if (docplaceData) {
            return JSON.parse(docplaceData).customerId || "";
        }
        return "";
    };

    const handlePhraseClick = (ref) => {
        props.searchPhrase(ref.phrase);
    };

    const [time, setTime] = useState(0); // Time in milliseconds
    const timerRef = useRef(null);
    const startTimeRef = useRef(null);
  
    const startTimer = () => {
      if (timerRef.current) return; // Prevent multiple intervals
  
      startTimeRef.current = Date.now() - time;
      timerRef.current = setInterval(() => {
        setTime(Date.now() - startTimeRef.current);
      }, 10); // Update every 10ms
    };
  
    const stopTimer = () => {
      clearInterval(timerRef.current);
      timerRef.current = null;
    };
  
    const formatTime = (ms) => {
      const minutes = Math.floor(ms / 60000);
      const seconds = Math.floor((ms % 60000) / 1000);
      const milliseconds = ms % 1000;
  
      return `${String(seconds).padStart(2, '0')}:${String(milliseconds).padStart(3, '0')}`;
    };
   

    const sendPostRequest = async (custom) => {
        console.log("Sending request to the agent...", input, custom);
        startTimer();
        setIsLoading(true);
        
        try {
            const response = await fetch('https://api.dev.docplace.io/gw/ai/Api/v1/Conversations/Shorts', {
                method: 'POST',
                headers: {
                    'accept': '*/*',
                    'x-Client-Id': setCustomerId(),
                    'appId': 'e1dcbba4-2123-4155-a198-421130c683e7',
                    'documentId': props.id,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    data: {
                        prompt: custom ? custom : input
                    }
                })
            });

            const result = await response.json();
            let jsonResponse = result.data.response;
            jsonResponse = typeof result.data.response === "string" ? JSON.parse(result.data.response) : result.data.response;

            let finalAnswer = "Sorry, I can't answer to that question at this moment.";

            if (jsonResponse.responseText) {
                finalAnswer = jsonResponse.responseText;
            }

            console.log("Response from the agent:", jsonResponse);

            setIsLoading(false);
            setAnswered(true);
            setInput("");
            stopTimer();
            setChatHistory((prev) => [
                ...prev,
                { question: custom ? custom : input, answer: finalAnswer, docReferences: jsonResponse.docReferences || [] }
            ]);

        } catch (error) {
            stopTimer();
            setIsLoading(false);
            setAnswered(true);
            setInput("");
            setChatHistory((prev) => [
                ...prev,
                { question: custom ? custom : input, answer: "Sorry, I can't answer to that question at this moment.", docReferences: [] }
            ]);
            console.error("Error:", error);
        }
    };

    const sendInput = (custom) => {
        setShowInfo(false);
        sendPostRequest(custom);
    };

    const clearAgent = () => {
        setAnswered(false);
        setInput("");
        setIsLoading(false);
        setShowInfo(true);
        setChatHistory([{ question: "", answer: "", docReferences: [] }]);
        
    };

    const customPrompt = (custom) => {
        setInput(custom);
        sendInput(custom);
    };

    const ShowDocReferences = (data) => {
        if (data.docReferences.length > 0) {
            return data.docReferences.map((ref, index) => (
                <>
                {index === 0 && <div className="doc-references-title">Document References:</div>}
                <div key={index} className="doc-reference" onClick={() => handlePhraseClick(ref)}>
                    <span title="Search in document"><b>[Page {ref.page}]</b>  {ref.phrase}</span>
                </div>
                </>
            ));
        }
        return null;
    }

    return (
        <>
            <ToastContainer autoClose={3000} />
            <div className="agent-header">
                <img src={DocAssistantIcon} alt={"img"} />
                <span>Chat</span>
                <IconButton aria-label="reset" onClick={clearAgent}>
                    <CachedIcon />
                </IconButton>
            </div>
            <div className="agent-container">
                {chatHistory.map((item, index) => (
                    item.question !== "" && item.answer !== "" && (
                        <div key={index} className="chat-item">
                            <div className="agent-chat">
                                <div className="user-message">
                                    <div className="user-message-text">
                                        {item.question}
                                    </div>
                                </div>
                            </div>
                            {index === chatHistory.length - 1 && answered ? (
                                <div className="agent-message-text">
                                
                                    <TypingResponse
                                        text={item.answer}
                                        onDone={() => setAnswered(false)}
                                    />
                                      <div className="doc-references">
                                    <ShowDocReferences docReferences= {item.docReferences} />
                                    </div>
                                </div>
                            ) : (
                                <div className="agent-message-text">
                                    <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                                    <div className="doc-references">
                                        
                                    <ShowDocReferences docReferences= {item.docReferences} />
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                ))}


               
               {isLoading && 
                    <div className="chat-item">
                        <div className="agent-loading">
                            <span>[AI is responding]</span>
                            <div className="timer">
                                        <span className="timer-text">{formatTime(time)}</span>
                            </div>
                        </div>
                    </div>
                }
              

                <div className="agent-content">
                    {showInfo && (
                        <div className="agent-greeting">
                            <img src={DocAssistantIcon} height={44} width={44} alt="Assistant" />
                            <p className="greeting">Hello, <span className="highlight">{info.userName || "Carlos M."}</span></p>
                            <p className="subtext">How can I help you today?</p>
                        </div>
                    )}
                </div>

                <div className="agent-footer">
                    {showInfo && (
                        <div className="agent-options">
                            <button className="option" onClick={() => customPrompt("Summarize this document.")}>
                                <b>Summarize</b><br />
                                <span>Summarize this legal document.</span>
                            </button>
                            <button className="option" onClick={() => {
                                customPrompt("Extract the key points from this document.")
                            }}>
                                <b>Ask Questions</b><br />
                                <span>What are the key takeaways of a document?</span>
                            </button>
                        </div>
                    )}
                    <div className="agent-input">
                        <input
                            type="text"
                            placeholder="Chat with your documents..."
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    sendInput();
                                }
                            }}
                        />
                        <img
                            src={up}
                            alt="Send"
                            onClick={sendInput}
                            className="send"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AgentChat;
