import { combineReducers } from "redux";
import Login from "./auth/reducer";
import User from "./user/reducer";
import DocType from "./docType/reducer";
import Dashboard from "./dashboard/reducer";
import Tree from "./tree/reducer";
import DetailFolder from "./document/reducer";
import Explorer from "./explorer/reducer";
import InputMethod from "./inputMethod/reducer";
import Search from "./search/reducer";
import Rol from "./rol/reducer";
import UserValidateSignature from "./eSignature/reducer";
import AllFormsRequested from "./Forms/reducer";
import WorkFlowProcess from "./workFlow/reducer";
import fileReducer from "./file/reducer";

const rootReducer = combineReducers({
  Login,
  User,
  DocType,
  Dashboard,
  Tree,
  DetailFolder,
  Explorer,
  Search,
  InputMethod,
  Rol,
  UserValidateSignature,
  AllFormsRequested,
  WorkFlowProcess,
  fileReducer,
});

export default rootReducer;
