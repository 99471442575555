import { call, put, takeEvery } from "redux-saga/effects";
import {
  DETAIL_FOLDER_LIST,
  DOCUMENT_PAGES_LIST,
  DOCUMENT_PERMISSIONS,
  LIST_REQUIRE_SIGNATURE_DOCUMENT,
  LIST_REQUIRE_SIGNATURE_DOCUMENT_CLEAR_DATA,
  RESEND_SIGNATURE_REQUEST,
  RESEND_SIGNATURE_REQUEST_CLEAR_DATA,
  SEARCH_ARCHIVES_LIST,
  DOCUMENT_VERSIONS,
  DOCUMENT_VERSIONS_CLEAR_DATA,
  RESTORE_DOCUMENT_VERSION,
  RESTORE_DOCUMENT_VERSION_CLEAR_DATA
} from "./actionTypes";
import {
  apiErrorDetailFolder,
  apiErrorDocumentPage,
  apiErrorSearchArchives,
  detailFolderSuccess,
  documentPageSuccess,
  getDocumentPermissionsError,
  getDocumentPermissionsSuccess,
  getListRequireSignatureDocumentError,
  getListRequireSignatureDocumentSuccess,
  resendSignatureRequestError,
  resendSignatureRequestSuccess,
  searchArchivesSuccess,
  getDocumentVersionsSuccess,
  getDocumentVersionsError,
  restoreDocumentVersionSuccess,
  restoreDocumentVersionError,
  restoreDocumentVersionClearData,
} from "./actions";
import { toast } from "react-toastify";

import {
  getDetailFolder,
  getDocumentPermissionsAPI,
  getListRequireSignatureDocumentAPI,
  postDocumentPages,
  postSearchArchives,
  resendSignatureRequestEmailAPI,
  getDocumentVersions,
  postRestoreDocumentVersion,
} from "../../helpers/call_api";

function* getDocumentPageList({
  payload: { documentPages, page, size, navigate },
}) {
  try {
    console.log("documentPages--------->", documentPages);
    const response = yield call(
      postDocumentPages,
      documentPages.id,
      page,
      size
    );
     console.log("response-------->", response);
    yield put(documentPageSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    yield put(apiErrorDocumentPage(error));
    //DOC-1837 add messages on empty information and extension not supported
    if (
      error.response?.data?.error?.messages[0].message
        .toLowerCase()
        .includes("document doesn't exists")
    ) {
      toast.error("Document cannot be opened, reason: Document is empty.", {
        toastId: "document-empty",
      });
    } else if (
      error?.response?.data?.error?.messages[0].message
        .toLowerCase()
        .includes("extension not supported")
    ) {
      toast.error(
        "Document cannot be opened, reason: Extension is not supported.",
        { toastId: "extension-notsupported" }
      );
    }
  }
}

function* getDetailFolderList({ payload: { detailFolder, navigate } }) {
  try {
    const response = yield call(
      getDetailFolder,
      detailFolder.parentArchiveId,
      detailFolder.page,
      detailFolder.pageSize
    );
    yield put(detailFolderSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    yield put(apiErrorDetailFolder(error));
  }
}

function* getSearchArchivesList({ payload: { searchArchives, navigate } }) {
  try {
    const response = yield call(
      postSearchArchives,
      searchArchives.parentArchiveId,
      searchArchives.page,
      searchArchives.pageSize,
      searchArchives.obTerm
    );
    yield put(searchArchivesSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    yield put(apiErrorSearchArchives(error));
  }
}

function* getDocumentPermissions({ payload: { id, navigate } }) {
  try {
    const response = yield call(getDocumentPermissionsAPI, id);

    yield put(getDocumentPermissionsSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    yield put(getDocumentPermissionsError(error));
  }
}

function* getDocumentVersionsList({ payload: { id, navigate } }) {
  try {
    const response = yield call(getDocumentVersions, id);
    yield put(getDocumentVersionsSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    yield put(getDocumentVersionsError(error));
  }
}

// List require signature for document
function* getListRequireSignatureDocument({ payload: { id } }) {
  try {
    const response = yield call(getListRequireSignatureDocumentAPI, `?DocumentId=${id}`);
    yield put(getListRequireSignatureDocumentSuccess(response));
  } catch (error) {
    yield put(getListRequireSignatureDocumentError(error));
  }
}

function* listRequireSignatureDocumentClearData({payload: {}}) {
  try {
    yield put(listRequireSignatureDocumentClearData(true))
  } catch (error) {
    yield put(getListRequireSignatureDocumentError(error))
  }
}
// END

// Resend Signature request email
function* resendSignatureRequest({ payload: { data } }) {
  try {
    const response = yield call(resendSignatureRequestEmailAPI, data.obj, data.documentId);
    yield put(resendSignatureRequestSuccess(response));
  } catch (error) {
    yield put(resendSignatureRequestError(error));
  }
}

function* resendSignatureRequestClearData() {
  try {
    yield put(listRequireSignatureDocumentClearData(true))
  } catch (error) {
    yield put(getListRequireSignatureDocumentError(error))
  }
}

function* restoreDocumentVersion({ payload: { id } }) {
  try {
    const response = yield call(postRestoreDocumentVersion, id);
    yield put(restoreDocumentVersionSuccess(response));
  } catch (error) {
    yield put(restoreDocumentVersionError(error));
  }
}
// END
function* authSaga() {
  yield takeEvery(DETAIL_FOLDER_LIST, getDetailFolderList);
  yield takeEvery(SEARCH_ARCHIVES_LIST, getSearchArchivesList);
  yield takeEvery(DOCUMENT_PAGES_LIST, getDocumentPageList);
  yield takeEvery(DOCUMENT_PERMISSIONS, getDocumentPermissions);
  yield takeEvery(LIST_REQUIRE_SIGNATURE_DOCUMENT, getListRequireSignatureDocument);
  yield takeEvery(LIST_REQUIRE_SIGNATURE_DOCUMENT_CLEAR_DATA, listRequireSignatureDocumentClearData);
  yield takeEvery(RESEND_SIGNATURE_REQUEST, resendSignatureRequest);
  yield takeEvery(RESEND_SIGNATURE_REQUEST_CLEAR_DATA, resendSignatureRequestClearData);
  yield takeEvery(DOCUMENT_VERSIONS, getDocumentVersionsList);
  yield takeEvery(RESTORE_DOCUMENT_VERSION, restoreDocumentVersion);
  yield takeEvery(RESTORE_DOCUMENT_VERSION_CLEAR_DATA, restoreDocumentVersionClearData);
}

export default authSaga;
