import {
  ChildListItemContainer,
  ContainerIconList,
  LiItem,
  LogoImg,
  TitleItem,
} from "../style/style";
import { ChildItem, SidebarItem } from "../interface";

export const ItemListChild = (props: {
  isHiddenList: Record<string, boolean>;
  item: SidebarItem;
  childItem: ChildItem;
  activeItem?: string | null;
  selectedItem?: (item: ChildItem) => void;
  isHidden?: boolean;
}) => {
  return (
    <ChildListItemContainer
      isHiddenList={props.isHiddenList[props.item.id]}
      key={props.childItem.id}
    >
      <LiItem
        isHidden={props.isHidden}
        isActive={props.activeItem === props.childItem.id}
        onClick={() => {
          props.selectedItem?.(props.childItem);
        }}
      >
        <ContainerIconList>
          <LogoImg src={props.childItem.icon} alt={"img"} />
        </ContainerIconList>
        <TitleItem isHidden={props.isHidden}>{props.childItem.title}</TitleItem>
      </LiItem>
    </ChildListItemContainer>
  );
};
