import {
  ContainerIconList,
  LiItem,
  LogoImg,
  TitleIconHeaderContainer,
  TitleItemHeader,
} from "../style/style";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ChildItem, SidebarItem } from "../interface";

export const ItemListHeader = (props: {
  hideList: (id: string) => void;
  selectedItem?: (item: ChildItem) => void;
  item: SidebarItem;
  isHiddenList: Record<string, boolean>;
  isHidden?: boolean;
}) => {
  return (
    <LiItem
      isHidden={props.isHidden}
      onClick={() => {
        props.item.children?.length > 0
          ? props.hideList(props.item.id)
          : props.selectedItem?.(props.item);
      }}
    >
      <ContainerIconList>
        <LogoImg src={props.item?.header?.icon} alt={"img"} />
      </ContainerIconList>
      <TitleIconHeaderContainer isHidden={props.isHidden}>
        <TitleItemHeader>{props.item?.header?.title}</TitleItemHeader>
        {props.item.children?.length > 0 &&
          (props.isHiddenList[props.item.id] ? (
            <KeyboardArrowDownIcon
              fontSize={"small"}
              sx={{ color: "#1976d2" }}
            />
          ) : (
            <KeyboardArrowUpIcon fontSize={"small"} sx={{ color: "#1976d2" }} />
          ))}
      </TitleIconHeaderContainer>
    </LiItem>
  );
};
