import React, { useState, useEffect } from 'react';


export default function AssignedView() {


    return (
        <div >
            <h2>AssignedView View</h2>
        </div>
    );
}