import { Nb_SearchFiltersProps } from "../type/searchFilters.type";
import {
  ContainerList,
  FooterFilters,
  HeaderFilters,
  TitleHeader,
} from "../style/style.filtersView";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";

export const FiltersView = (props: Partial<Nb_SearchFiltersProps>) => {
  const { hiddenFilters = () => {} } = props;
  return (
    <ContainerList isHiddenFilters={props.isHiddenFilters}>
      <HeaderFilters>
        <TitleHeader>Filters</TitleHeader>
        <CloseIcon
          onClick={hiddenFilters}
          sx={{ color: "#1776D2", cursor: "pointer" }}
        />
      </HeaderFilters>
      <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={null}
            onChange={(newValue) => {}}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>

      <FooterFilters>
        <Button variant="contained">APPLY FILTERS</Button>
      </FooterFilters>
    </ContainerList>
  );
};
