import React, { useState, useRef } from "react";
import { NB_notificationIcon_props } from "../type/notificationIcon.types";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import notificationSVG from "../../../assets/notificationSVG.svg";
import notificationUnreadSVG from "../../../assets/notificationsUnreadf.svg";
import Nicon from "../../../assets/NotificationsOffIcon.svg";
import useAxios from "../../../routes/hook";
import useUnreadNotifications from "../hook/notificationIcon.hook";
import {
  NotificationLI,
  NotificationLIDescription,
  NotificationLITitle,
  NotificationTitle,
  NotificationWrapperNotNews,
  NotificationUL,
  NotificationWrapper,
  NotificationDate,
} from "../style/notificationIcon.styles";

const NbNotificationIconView: React.FC<NB_notificationIcon_props> = ({
  onClick,
  onFocus,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {
    fetchNotifications,
    notificationResponse,
    notificationLoading,
    notificationError,
  } = useAxios();

  const handleMouseEnter = () => {
    setIsOpen(true);
    onFocus && onFocus();
    fetchNotifications("?sortBy=user_id&sortDir=asc");
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div style={{}}>
      <IconButton
        size="medium"
        sx={{
          ml: 2,
          position: "absolute",
          right: "40px",
          margin: "12px 40px 0px 0px",
        }}
        aria-haspopup="true"
        onMouseEnter={handleMouseEnter}
        onClick={() => setIsOpen(!isOpen)}
      >
        {props.haveNotification ? (
          <img
            src={notificationUnreadSVG}
            alt="NotificationIcon"
            style={{
              width: 20,
              height: 20,
              marginTop: 2,
              filter: "brightness(0) invert(1)",
            }}
          />
        ) : (
          <img
            src={notificationSVG}
            alt="NotificationIcon"
            style={{
              width: 20,
              height: 20,
              marginTop: 2,
              filter: "brightness(0) invert(1)",
            }}
          />
        )}
      </IconButton>

      {isOpen && (
        <div
          style={{
            position: "absolute",
            top: "53px",
            right: "42px",
            zIndex: 9999,
          }}
          ref={wrapperRef}
        >
          <NotificationWrapper>
            <NotificationTitle>{props.title}</NotificationTitle>
            <NotificationUL>
              {(props.list ?? []).length > 0 ? (
                props.list?.map((item, idx) => {
                  return (
                    <NotificationLI
                      className={item.statusNew ? "NoReadNotification" : ""}
                      key={idx}
                      onClick={() => {
                        onClick && onClick(item);
                        setIsOpen(false);
                      }}
                    >
                      <NotificationLITitle>{item.name}</NotificationLITitle>
                      <NotificationDate>{item.createdAgo}</NotificationDate>
                      <NotificationLIDescription>
                        {item.description}
                      </NotificationLIDescription>
                    </NotificationLI>
                  );
                })
              ) : (
                <NotificationWrapperNotNews>
                  <img
                    style={{ width: "500px" }}
                    src={Nicon}
                    alt="noNotification"
                  />
                  You have no new notifications at the moment. Check back later!
                </NotificationWrapperNotNews>
              )}
            </NotificationUL>
          </NotificationWrapper>
        </div>
      )}
    </div>
  );
};

export default NbNotificationIconView;
