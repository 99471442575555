import { useState, useEffect } from "react";
import axios from "axios";

const useAxios = () => {
  
  const [getResponse, setGetResponse] = useState<any>([]);
  const [getResponseLoading, setGetResponseLoading] = useState(false);
  const [getResponseError, setGetResponseError] = useState<any>();
  const [callSign, setCallSign] = useState<boolean>(false);
  const [activeSign, setActiveSign] = useState(false);

  const API_URL = process.env.REACT_APP_HOST_API;

  const setUserId = () => {
    const docplaceData = localStorage.getItem("docplace");
    if (docplaceData) {
      return JSON.parse(docplaceData).userId || "";
    }
    return "";
  };

  const setCustomerId = () => {
    const docplaceData = localStorage.getItem("docplace");
    if (docplaceData) {
      return JSON.parse(docplaceData).customerId || "";
    }
    return "";
  };



  return {
    activeSign,
    callSign,
    setCallSign,
    setActiveSign,
  };
};

export default useAxios;
