import React, { useEffect, useState } from "react";
import { NB_DocPlaceStampSign_props } from "./type/docplace_stamp_sign.types";
import NbStampSignView from "./view/docplace_stamp_sign.view";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const NbRequestSign: React.FC<NB_DocPlaceStampSign_props & {setShowViewRequest:(id: any) => void }> = (props) => {
  const dispatch = useDispatch();
  const [dataSign, setDataSign] = useState([]);
  const [pageActive, setPageActive] = useState("1");

  let params = useParams();
  

  useEffect(() => {}, []);

  const callSetShowOrder = (e: any) => {
    props.setShowViewRequest(e);
  };


  return (
    <NbStampSignView
      title="Sign the Document"
      listDocuments={props.listDocuments}
      id={params.id}
    />
  );
};

export default NbRequestSign;
