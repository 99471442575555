import React from "react";
import { Viewer, Worker } from '@react-pdf-viewer/core';


import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';


////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
function createFieldsPlugin(fields, pageDimensions) {
	return {
		renderPageLayer(props) {
			console.log('props', props);
			const {
				pageIndex, 
				scale,
				rotation, 
				width, 
				height, 
				canvasLayer,
			} = props;

			const pageFields = fields.filter((f) => f.bounds.page === pageIndex + 1);
			const pageDef = pageDimensions.find((p) => p.page_number === (pageIndex + 1));

			const inchToPxX = width  / pageDef.width; 
			const inchToPxY = height / pageDef.height; 
			return (
				<>
					{pageFields.map((field, i) => {
						const { x, y, width, height } = field.bounds;

						const leftPx = x * inchToPxX;
						const widthPx = width * inchToPxX;

						const heightPx = height * inchToPxY;
						const topPx = height - (y + height) * inchToPxY;

						const style={
							position: "absolute",
							left: `${leftPx}px`,
							top: `${topPx}px`,
							width: `${widthPx}px`,
							height: `${heightPx}px`,
							outline: "2px solid red",
							boxSizing: "border-box",
							pointerEvents: "none",
						};

						return (
							<div
								key={i}
								style={style}
								title={field.fieldName} 
							>
							</div>
						);
					})}
				</>
			);
		},
	};
}

////////////////////////////////////////////////////////////////////////////////
export default function PdfViewerWithFields({ pdfUrl, fields, pageDimensions }) {
	const defaultLayoutPluginInstance = defaultLayoutPlugin();


    console.log('pdfUrl', pdfUrl);
	return (
		<div style={{ height: "100vh", width: "100%" }}>
			{/* Worker para PDF.js */}
			<Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
				<Viewer
					fileUrl={pdfUrl}
					plugins={[defaultLayoutPluginInstance]}
				/>
			</Worker>
		</div>
	);
}
