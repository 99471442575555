import React, { useEffect, useState } from "react";

import { NB_sidebar_props, SidebarItem } from "./interface";
import { NbSidebarView } from "./view/sidebarView";

export const NbSidebar = ({
  onClickItemList = () => {},
  onIsHideSideBar = () => {},
  ...props
}: NB_sidebar_props) => {
  const [isHidden, setIsHidden] = useState(props.hideSidebar);
  const [itemsSidebar, setItemsSidebar] = useState<SidebarItem[]>(
    props.sidebarItemsList || []
  );
  const [activeItem, setActiveItem] = useState<string | null>(
    itemsSidebar.flatMap((item) => item.children).find((child) => child.active)
      ?.id || null
  );
  const [isHiddenList, setIsHiddenList] = useState<Record<string, boolean>>(
    itemsSidebar?.reduce((acc: any, item) => {
      acc[item.id] = item.header?.hideList ?? false;
      return acc;
    }, {})
  );

  useEffect(() => {
    setItemsSidebar(props.sidebarItemsList || []);
  }, [props.sidebarItemsList]);

  useEffect(() => {
    setIsHidden(props.hideSidebar);
  }, [props.hideSidebar]);

  useEffect(() => {
    onIsHideSideBar(isHidden || false);
  }, [isHidden]);

  const hideDrawer = () => {
    setIsHidden((prev) => !prev);
  };

  const hideList = (id: string) => {
    setIsHiddenList((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const selectedItem = (childItem: any) => {
    setActiveItem(childItem.id);
    onClickItemList?.(childItem);
  };

  return (
    <NbSidebarView
      backgroundColor={props.backgroundColor}
      bgColorHeader={props.bgColorHeader}
      isHidden={isHidden}
      isHiddenList={isHiddenList}
      logoHeader={props.logoHeader}
      iconHeader={props.iconHeader}
      itemsSidebar={itemsSidebar}
      activeItem={activeItem}
      hideList={hideList}
      hideDrawer={hideDrawer}
      selectedItem={selectedItem}
    />
  );
};
