import React, { useEffect, useState } from "react";
import { NB_DocPlaceRequestSign_props } from "../type/docplace_request_sign.types";
import { DPTitle, DPwrapper } from "../../style/docplace_sign_module.styles";
import SelectDropdownDocPlace from "../../../../components/form/selectDropdown";
import NbRequestSignViewerView from "../view/docplace_request_sign_viewer.view";

import useAxios from "../../tree/hook/hook";
import LoadingComponent from "../../../../components/loading";
import { de } from "date-fns/locale";
const NbRequestSignView: React.FC<NB_DocPlaceRequestSign_props & {setShowViewRequest:(id: any) => void }> = ({
  ...props
}) => {
  const [showRequest, setShowRequest] = useState(false);
  const [pagesTH, setPagesTH] = useState<any>([]);
  const [showOrder, setShowOrder] = React.useState(false);
  const [order, setOrder] = useState(false);
  const { 
      fetchDocument, 
      treeLoading, 
      documentResponse, 
      fetchUsers, 
      users,
      callSendRequestSign,
      setCallSendRequestSign,
      setCallRequestSign,
      callRequestSign,
      setCallSelectLabel,
      callSelectLabel,
      setCallCreateRegion,
      callCreateRegion,
      setCallSaveAndClose,
      callSaveAndClose  } = useAxios();
  const [document, setDocument] = useState({
    value:
      props.listDocuments && props.listDocuments?.length > 0
        ? [
            {
              id: props.listDocuments[0].id,
              label: props.listDocuments[0].label,
            },
          ]
        : "",
    error: false,
  });

  useEffect(() => {
    if (
      Array.isArray(document.value) &&
      document.value[0] &&
      "id" in document.value[0]
    ) {
      fetchDocument(
        `/automation/api/v1.0/Documents/RequestPages/${
          typeof document.value === "string" ? "" : document.value[0]?.id
        }?page=1&pageSize=10`
      );
      fetchUsers(`/security/api/v1.0/SystemUser/All`);
    }
  }, [document]);

  useEffect(() => {
    if (documentResponse) {
    }
  }, [documentResponse]);

  const handleChangePage = (e: any) => {
    setDocument({
      value: e.value,
      error: false,
    });
  };

  const callSetShowOrder = (e: any) => {
    props.setShowViewRequest(e);
  };

  return (
    <DPwrapper>
      <LoadingComponent show={treeLoading} text={"Loading..."} />
      <DPTitle>{props.title}</DPTitle>
      <div style={{ width: "304px", marginTop: "15px" }}>
        <SelectDropdownDocPlace
          id={"originType"}
          field={document}
          setField={handleChangePage}
          defaultList={document.value}
          required={true}
          label="Documents to send for signature"
          helperText=""
          arrayList={props.listDocuments}
          isMulti={true}
        />
      </div>
      <NbRequestSignViewerView
        document={documentResponse}
        users={users}
        setOrder={setOrder}
        order={order}
        showOrder={showOrder}
        setCallSendRequestSign={setCallSendRequestSign}
        callSendRequestSign={callSendRequestSign}
        setShowOrder={setShowOrder}
        setShowRequest={callSetShowOrder}
        setCallSaveAndClose={setCallSaveAndClose}
        callCreateRegion={callCreateRegion}
        setCallCreateRegion={setCallCreateRegion}
        callSaveAndClose={callSaveAndClose}
        setCallSelectLabel={setCallSelectLabel}
        callSelectLabel={callSelectLabel}
        setCallRequestSign={setCallRequestSign}
        callRequestSign={callRequestSign}
      />
    </DPwrapper>
  );
};

export default NbRequestSignView;
