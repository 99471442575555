import React, { useEffect, useState } from "react";
import { NB_DocPlaceSignModule_props } from "./type/docplace_sign_module.types";
import NbFormsModuleView from "./view/docplace_sign_module.view";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../components/loading";
import ConfirmRegisteredSignatureDocPlace from "../AssignedForms/resouerce/confirm/confirmRegisteredSignature";
import useAxios from "./hook/hook";
import {
  getESignatureRequests,
  getESignatureRequestsClearData,
} from "../../store/eSignature/actions";
import { de } from "date-fns/locale";
import { MenuItem } from "../../components/horizontalMenu/types/horizontalMenu.type";

const NbSignModule: React.FC<
  NB_DocPlaceSignModule_props & { nameButton: string }
> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { 
    fetchEsign, getResponse, getResponseLoading, getUserSign  } = useAxios();
  const [dataSign, setDataSign] = useState([]);
  const [dataS, setDataS] = useState([]);
  const [pageActive, setPageActive] = useState("1");
  const { responseESignatureRequests, loadingESignatureRequests } = useSelector(
    (state) => ({
      responseESignatureRequests: (state as any).UserValidateSignature
        .responseESignatureRequests,
      loadingESignatureRequests: (state as any).UserValidateSignature
        ?.loadingESignatureRequests,
    })
  );

  useEffect(() => {
    if (!responseESignatureRequests) {
      dispatch(getESignatureRequests());
    }
      fetchEsign('/automation/api/v1.0/RequiredEsignature/Documents');
  }, []);

  useEffect(() => {
    if (getUserSign) {
      console.log("USER SIGN", getUserSign);
    }
  }, [getUserSign]);

  useEffect(() => {
    let newObject: any[] = [];
    if (getResponse) {
      getResponse.map(
        (signatureRequests: any, i: number) => {
          const S = setSortSigners(signatureRequests.signers);
          const signersList =
            S?.map((signer: any) => signer.fullName)?.join(", ") || "";

          const nextUser = getNextSigner(signatureRequests.signers);
          const isStamp = isSignerStamp(signatureRequests.signers);

          newObject.push({
            name: signatureRequests?.document_name,
            status: signatureRequests?.status,
            lastUpdated: new Date(
              signatureRequests?.requestDate
            ).toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            }),
            id: signatureRequests?.document_id,
            signersList: signersList,
            signers: signatureRequests?.signers,
            nextSigner: nextUser,
            isStamp: isStamp,
          });
        }
      );
      setDataS(newObject as any);
    }
  }, [getResponse]);



  useEffect(() => {
    let newObject: any[] = [];
    if (responseESignatureRequests) {
      responseESignatureRequests?.data?.map(
        (signatureRequests: any, i: number) => {
          const S = setSortSigners(signatureRequests.signers);
          const signersList =
            S?.map((signer: any) => signer.fullName)?.join(", ") || "";

          const nextUser = getNextSigner(signatureRequests.signers);
          const isStamp = isSignerStamp(signatureRequests.signers);

          newObject.push({
            name: signatureRequests?.document_name,
            status: signatureRequests?.status,
            lastUpdated: new Date(
              signatureRequests?.requestDate
            ).toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            }),
            id: signatureRequests?.document_id,
            signersList: signersList,
            signers: signatureRequests?.signers,
            nextSigner: nextUser,
            isStamp: isStamp,
          });
        }
      );
      setDataSign(newObject as any);
    }
    dispatch(getESignatureRequestsClearData());
  }, [responseESignatureRequests]);

  const getNextSigner = (signers: any) => {
    const sorted = setSortSigners(signers);
    let nextSigner = null;
    for (const signer of sorted) {
      if (signer.have_signed !== 1) {
        nextSigner = signer;
        break;
      }
    }
    return nextSigner ? `${nextSigner.fullName}` : "";
  };

  const isSignerStamp = (signers: any) => {
    const sorted = setSortSigners(signers);
    let nextSigner = null;
    for (const signer of sorted) {
      const docplaceData = localStorage.getItem("docplace") ? JSON.parse(localStorage.getItem("docplace") || '') : null;
      const email = docplaceData?.email;
      if (signer.signatory_email === email) {
        nextSigner = signer;
        if(signer.have_signed === 1){
          return 'signed';
        }else{
          return 'noSigned';
        }
      }
    }
  };

  const setSortSigners = (signers: any) => {
    const sorted = [...signers].sort((a, b) => a.order - b.order);
    return sorted;
  };

  const handleSign = (id: any) => {
    navigate(`/stampSign/${id.id}`);
  };

  const handleView = (id: any) => {
    navigate(`/docViewer/${id.id}`);
  };

  const setMenu = (id: MenuItem) => {
    setPageActive(id.id);
  };

  return (
    <>
      <LoadingComponent show={getResponseLoading} text={"Loading"} />
      <ConfirmRegisteredSignatureDocPlace show={!getUserSign.isValid} close="/docplaceSign"/>
      <NbFormsModuleView
        nameButton={"Request eSignature"}
        toolbar={[
          { id: "1", name: "Inbox", active: true, route: "" },
          { id: "2", name: "Sent", active: false, route: "" },
        ]}
        title={"DocPlace Sign"}
        inbox={dataS}
        sent={dataSign}
        onClickSign={handleSign}
        onClickView={handleView}
        onClickMenu={setMenu}
        pageActive={pageActive}
      />
    </>
  );
};

export default NbSignModule;
