import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import http from "../../services/http-common";

import { initializeApp } from "firebase/app";
import { signInWithPopup, GoogleAuthProvider, getAuth } from "firebase/auth";

import {
  CountDown,
  CountDownNumber,
  CountDownText,
  ResendCodeContent,
  ResendCodeContentOpt,
  ResendCodeTitle,
  ResendCodeWrapper,
  ResendEmail,
  useStyles,
} from "./style";
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  Link,
  IconButton,
  Paper,
} from "@mui/material";
import GoogleIcon from "../../assets/google.svg";
import MicrosoftIcon from "../../assets/microsoft.svg";

import Visibility from "@mui/icons-material/VisibilityOutlined";
import VisibilityOff from "@mui/icons-material/VisibilityOffOutlined";
//import { Container } from "reactstrap";
//import Button from "@mui/material/Button";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import logoFD from "../../assets/Docplace_Logo.svg";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { useDispatch, useSelector } from "react-redux";
import { apiError, customerUser, loginUser } from "../../store/actions";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputDocPlace from "../../components/form/input";
import LoadingComponent from "../../components/loading";
import { useNavigate, useSearchParams } from "react-router-dom";
import SelectDropdown from "../../components/form/selectDropdown";
import "./style.css";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { callMsGraph } from "./graph";

const Login = (props) => {
  const classes = useStyles();
  const notify = (msj, event) => toast[event](msj);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userName, setUserName] = useState({ value: "", error: false });
  const [code, setCode] = useState({ value: "", error: false });

  const provider = new GoogleAuthProvider();
  const firebaseConfig = {
    apiKey: "AIzaSyATCOHHERruC0pkfKjx9PwiQAyoj11ZPLs",
    authDomain: "docplace-29524.firebaseapp.com",
    projectId: "docplace-29524",
    storageBucket: "docplace-29524.firebasestorage.app",
    messagingSenderId: "547875569112",
    appId: "1:547875569112:web:60a92eefaf25600cae46ad",
    measurementId: "G-MHX8LHFD2J",
  };
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  const [password, setPassword] = useState({ value: "", error: false });
  const [mfaId, setMfaId] = useState("");
  const [mfaIsRequired, setMfaIsRequired] = useState(false);
  const [customer, setCustomer] = useState({
    value: "MONTH",
    label: "Monthly",
  });
  const [customers, setCustomers] = useState([]);
  const [activationMsg, setActivationMsg] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [showHashInvalid, setShowHashInvalid] = useState(false);
  const [resend, setResend] = useState(false);
  const [sending, setSending] = useState(false);
  const [tiempoRestante, setTiempoRestante] = useState(120);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState(null); // Estado para almacenar los datos del usuario

  
  const postData = async (url, data) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      return await response.json();
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        setUser(user); // Almacena los datos del usuario en el estado
        //setUserName({ value: user.email, error: false });
        formData.email = user.email;
        // Llama a la función para enviar los datos del usuario al backend
        postData(
          "https://api.dev.docplace.io/gw/sso/Api/Validate/Google/Token",
          { accessToken: user.accessToken }
        )
          .then((data) => {
           
            if (data.statusCode === 500) {
              notify(
                "Authentication failed. Please try again later or use a different login method.",
                "error"
              );
              return;
            }

            doLogin(data);
          })
          .catch((error) => {
            notify("Error de autenticación", error);
          });
      })
      .catch((error) => {
        notify("Error de autenticación", error);
      });
  };

  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);

  const handleLogin = () => {
  

      // Silently acquires an access token which is then attached to a request for MS Graph data
    
    if (accounts.length === 0) {
      console.log("************No accounts detected***********");
      instance
      .loginPopup(loginRequest)
      .then((response) => {
        instance
          .acquireTokenSilent({
            ...loginRequest,
            account: response.account,
          })
          .then((tokenResponse) => {
            callMsGraph(tokenResponse.accessToken).then((graphResponse) => {
              postData(
                "https://api.dev.docplace.io/gw/sso/Api/Validate/MS/Token",
                { accessToken: tokenResponse.accessToken }
              )
                .then((data) => {
                  if (data.statusCode === 500 || data.statusCode === 401 || data.statusCode === 400) {
                    notify(
                      "Authentication failed. Please try again later or use a different login method.",
                      "error"
                    );
                    return;
                  }
                  setGraphData(graphResponse);
                  const userEmail =
                    graphResponse.mail || graphResponse.userPrincipalName;
                  //setUserName({ value: userEmail, error: false });
                  formData.email = userEmail;

                  doLogin(data);
                })
                .catch((error) => {
                  notify("Authentication error: ", error);
                });
             
            });
          });
      })
      .catch((e) => {
        console.log("Login Error:", e);
      });
    }

    if (accounts.length > 0){
      console.log("*****Accounts detected*****:", accounts[0]);
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((tokenResponse) => {
          callMsGraph(tokenResponse.accessToken).then((graphResponse) => {
            postData(
              "https://api.dev.docplace.io/gw/sso/Api/Validate/MS/Token",
              { accessToken: tokenResponse.accessToken }
            )
              .then((data) => {
                if (data.statusCode === 500 || data.statusCode === 401 || data.statusCode === 400) {
                  notify(
                    "Authentication failed. Please try again later or use a different login method.",
                    "error"
                  );
                  return;
                }
                setGraphData(graphResponse);
                const userEmail =
                  graphResponse.mail || graphResponse.userPrincipalName;
                //setUserName({ value: userEmail, error: false });
                formData.email = userEmail;

                doLogin(data);
              })
              .catch((error) => {
                notify("Authentication error: ", error);
                console.error("Authentication Error:", error);
              });
           
          });
        });
    }
    
   
 

    
  };

  
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === "email") {
      setUserName({ value: value, error: false });
    }
    if (name === "password") {
      setPassword({ value: value, error: false });
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev);
  };
  const [showLogin, setShowLogin] = useState(false);

  const { responseLogin, errorLogin, loading } = useSelector((state) => ({
    responseLogin: state.Login.responseLogin,
    errorLogin: state.Login.errorLogin,
    loading: state.Login.loadingLogin,
  }));

  const planIds = ["1", "2", "4", "6", "", null];

  const { responseCustomerUser, errorCustomerUser, loadingCustomerUser } =
    useSelector((state) => ({
      responseCustomerUser: state.Login.responseCustomerUser,
      errorCustomerUser: state.Login.errorCustomerUser,
      loadingCustomerUser: state.Login.loadingCustomerUser,
    }));
  const { responseHashValidate, errorHashValidate, loadingHashValidate } =
    useSelector((state) => ({
      //Hook to have the responses from redux
      responseHashValidate: state.Login.responseHashValidate,
      errorHashValidate: state.Login.errorHashValidate,
      loadingHashValidate: state.Login.loadingHashValidate,
    }));
  const handleValidSubmit = (e) => {
    e.preventDefault();
    if (!userName.error && !password.error) {
      let obj = {
        email: userName.value,
        password: password.value,
      };
      dispatch(loginUser(obj, props.history));
    } else {
      notify("Please enter all the required information", "error");
    }
  };
  /**navigates to the forgot password page and sen the current email on username */
  const navigateForgot = (e) => {
    e.preventDefault();
    if (userName.value) {
      navigate("/forgot-password", { state: { emailParam: userName.value } });
    } else {
      notify("Please enter your email", "error");
    }
  };

  const doLogin = (responseLogin) => {
    
    if (responseLogin.mfaRequired) {
      setMfaId(responseLogin?.mfaId);
      setMfaIsRequired(responseLogin?.mfaRequired);
    } else {
      
      
      if (
        responseLogin.superUser === 1 &&
        (responseLogin.customerId === null || responseLogin.customerId === "")
      ) {
        localStorage.setItem("docplace", JSON.stringify(responseLogin));
        localStorage.removeItem("docplace-logout");
        dispatch(customerUser("", props.history));
      } else {
        notify("Welcome, you are being redirected.", "success");
        localStorage.setItem("docplace", JSON.stringify(responseLogin));
        localStorage.removeItem("docplace-logout");
        window.location.href = planIds.includes(responseLogin?.plan?.id)
          ? "/explorer"
          : "/eSignatureInbox";

      }
    }
  };
  useEffect(() => {
    dispatch(apiError(""));
  }, [dispatch]);

  useEffect(() => {
    if (tiempoRestante > 0) {
      const intervalId = setInterval(() => {
        setTiempoRestante(tiempoRestante - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [tiempoRestante]);

  useEffect(() => {
    //Todo: replace with redux saga logout method
    if (responseLogin && localStorage.getItem("docplace-logout") === null) {
      //setResend(true)
      //alert("responseLogin");
      doLogin(responseLogin);
    } else {
      localStorage.removeItem("docplace-logout");
    }
  }, [responseLogin, userName.value]);

  useEffect(() => {
    //Todo: replace with redux saga logout method
    if (JSON.parse(localStorage.getItem("docplace"))) {
      if (responseCustomerUser && responseCustomerUser.data.length > 0) {
        let customerL = [];
        responseCustomerUser.data.map((cp) => {
          customerL.push({ value: cp, label: cp });
        });

        setCustomers(customerL);
      } else {
        localStorage.removeItem("docplace-logout");
      }
    }
  }, [responseCustomerUser]);

  useEffect(() => {
    if (errorLogin) {
      if (
        errorLogin.response.data.error.messages[0].message ===
        "User is not Active!"
      ) {
        setResend(true);
      }
      notify(errorLogin.response.data.error.messages[0].message, "error");
    }
  }, [errorLogin]);

  useEffect(() => {
    if (responseHashValidate?.success == false) {
      setShowHashInvalid(true);
    }
  }, [responseHashValidate]);

  useEffect(() => {
    let hash = searchParams.get("hash");
    setResend(false);

    if (hash && hash.length > 5) {
      let obj = {
        appId: "Docplace",
        data: {
          hashCode: hash,
        },
      };

      //dispatch(hashValidate(obj, props.history));

      let url = `${process.env.REACT_APP_HOST_API}/billing/Api/V1/Public/login?hash=${hash}`;

      http
        .get(url, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response) {
            notify(
              "You account was successfully activated, you are ready to login and enjoy the benefits of DocPlace",
              "success"
            );
          } else {
            notify("The activation link you used has already expired", "error");
          }
        })
        .catch((err) => {
          notify("The activation link you used has already expired", "error");
        });
    }
  }, []);

  const enterCustomer = () => {
    let ls = JSON.parse(localStorage.getItem("docplace"));
    ls["customerId"] = customer.value.value;
    localStorage.setItem("docplace", JSON.stringify(ls));
    setCustomers([]);
    window.location.href = "/explorer";
    //navigate("/explorer");
  };
  const resendEmail = () => {
    let url =
      process.env.REACT_APP_HOST_API +
      "/billing/Api/V1/Public/SendResetPasswordLinkDocPlace";
    let body = {
      appId: "DocPlace",
      data: {
        email: userName.value,
      },
    };
    setShowLogin(true);
    http
      .post(url, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((respData) => {
        if (respData.data.success == true) {
          notify("The email has been sent", "success");
        } else {
          notify(respData.data.errors[0].description, "error");
        }
        setShowLogin(false);
      })
      .catch((response) => {
        console.log("Error: " + response);
        setShowLogin(false);
        notify("Error sending the email", "error");
      })
      .finally(() => {
        setShowLogin(false);
      });
  };

  const sendCode = () => {
    let body = {
      mfaId: mfaId,
      code: code.value,
    };

    http
      .post(
        `${process.env.REACT_APP_HOST_API}/security/api/v1.0/Account/MfaVerfication`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response) {
          if (
            response.data.superUser === 1 &&
            response.data.customerId === null
          ) {
            localStorage.setItem("docplace", JSON.stringify(response.data));
            localStorage.removeItem("docplace-logout");
            dispatch(customerUser("", props.history));
          } else {
            notify("Welcome " + userName.value, "success");
            localStorage.setItem("docplace", JSON.stringify(response.data));
            localStorage.removeItem("docplace-logout");
            window.location.href = planIds.includes(response.data?.plan?.id)
              ? "/explorer"
              : "/eSignatureInbox";
          }
        } else {
          notify("The activation link you used has already expired", "error");
        }
      })
      .catch((err) => {
        notify(err.response?.data?.error?.messages[0].message, "error");
      });
  };

  const resendCodeEmail = (type) => {
    let body = {
      mfaId: mfaId,
      sendBy: type,
    };

    setTiempoRestante(120);
    setShowLogin(true);

    http
      .post(
        `${process.env.REACT_APP_HOST_API}/security/api/v1.0/Account/MfaResend`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response) {
          setMfaId(response.data.mfaId);
          notify("New Code sended", "success");
        }

        setShowLogin(false);
      })
      .catch((err) => {
        setShowLogin(false);
        notify(err.response?.data?.error?.messages[0].message, "error");
      });
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login</title>
      </MetaTags>
      <div className="login-container">
        <ToastContainer autoClose={3000} />
        <LoadingComponent
          show={loading || showLogin}
          text={"Login to DocPlace..."}
        />
        <Paper elevation={3} className="login-box">
          <Container maxWidth="xs" sx={{ textAlign: "center", mt: 1 }}>
            {showHashInvalid ? (
              <div
                style={{
                  color: "red",
                  fontWeight: "bold",
                  marginLeft: "20px",
                }}
              >
                The link you are using has already expired
              </div>
            ) : (
              <>
                <img alt="docplace" src={logoFD} />
                <Typography variant="h5" sx={{ mt: 3 }} gutterBottom>
                  Sign In
                </Typography>

                <Box
                  component="form"
                  onSubmit={handleValidSubmit}
                  sx={{ mt: 2 }}
                >
                  {!mfaIsRequired && (
                    <>
                      <Typography
                        variant="body2"
                        className="labelText"
                        align="left"
                      >
                        Email
                      </Typography>
                      <TextField
                        fullWidth
                        label=""
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={handleChange}
                        margin="normal"
                        required
                      />
                      <Typography
                        variant="body2"
                        className="labelText"
                        align="left"
                        sx={{ mt: 2 }}
                      >
                        Password
                      </Typography>
                      <TextField
                        placeholder="Enter your password"
                        fullWidth
                        label=""
                        name="password"
                        type={showPassword ? "text" : "password"}
                        value={formData.password}
                        onChange={handleChange}
                        margin="normal"
                        required
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              style={{ color: "#a0a0a0" }}
                              onClick={handleTogglePassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          ),
                        }}
                      />
                    </>
                  )}
                  {mfaIsRequired && customers.length <= 0 && (
                    <>
                      <Typography
                        variant="body2"
                        sx={{ mt: 2, mb: 2 }}
                        className="labelText"
                        align="left"
                      >
                        Enter the 2FA code
                      </Typography>
                      <InputDocPlace
                        id={"code"}
                        field={code}
                        setField={setCode}
                        required={true}
                        type="text"
                        label="Code"
                      />
                    </>
                  )}

                  {mfaIsRequired && customers.length <= 0 && (
                    <>
                      <Button
                        onClick={sendCode}
                        className={classes.buttons}
                        variant="contained"
                        style={{ marginBottom: "25px" }}
                        color="success"
                      >
                        Enter Code
                      </Button>
                      {tiempoRestante > 0 ? (
                        <CountDown>
                          <CountDownText>
                            you can request a new code in:
                            <CountDownNumber>{tiempoRestante}</CountDownNumber>
                            seconds
                          </CountDownText>
                        </CountDown>
                      ) : (
                        <ResendCodeWrapper>
                          <ResendCodeTitle>
                            Resend code verification
                          </ResendCodeTitle>
                          <ResendCodeContent style={{ textAlign: "left" }}>
                            <ResendCodeContentOpt
                              onClick={resendCodeEmail.bind(this, "EMAIL")}
                            >
                              <MarkEmailReadIcon style={{ color: "#006ac6" }} />{" "}
                              <span
                                style={{
                                  display: "inline-block",
                                  margin: "4px 0 0 12px",
                                  position: "absolute",
                                }}
                              >
                                Email
                              </span>
                            </ResendCodeContentOpt>
                            <ResendCodeContentOpt
                              onClick={resendCodeEmail.bind(this, "PHONE")}
                            >
                              <MarkUnreadChatAltIcon
                                style={{ color: "#006ac6" }}
                              />{" "}
                              <span
                                style={{
                                  display: "inline-block",
                                  margin: "0px 0 0 12px",
                                  position: "absolute",
                                }}
                              >
                                {" "}
                                Send a text message{" "}
                              </span>
                            </ResendCodeContentOpt>
                          </ResendCodeContent>
                        </ResendCodeWrapper>
                      )}
                    </>
                  )}

                  {customers.length > 0 && (
                    <section>
                      <SelectDropdown
                        id={"customer"}
                        field={customer}
                        arrayList={customers}
                        defaultList={[]}
                        setField={setCustomer}
                        label="Customer"
                        helperText="Choose a Customer"
                      />
                      <Button
                        onClick={enterCustomer.bind(this)}
                        className={classes.buttons}
                        variant="contained"
                        color="success"
                      >
                        Enter
                      </Button>
                    </section>
                  )}
                  {customers.length <= 0 && !mfaIsRequired && (
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ textTransform: "none" }}
                      size={"large"}
                      fullWidth
                      sx={{ mt: 2 }}
                    >
                      Log in
                    </Button>
                  )}
                </Box>

                {customers.length <= 0 && !mfaIsRequired && (
                  <>
                    <Typography variant="body2" sx={{ mt: 3, mb: 3 }}>
                      Or Continue with:
                    </Typography>
                    <Button
                      variant="outlined"
                      onClick={signInWithGoogle}
                      fullWidth
                      startIcon={
                        <IconButton>
                          <img
                            src={GoogleIcon}
                            alt="Google Icon"
                            style={{ width: 24, height: 24 }}
                          />
                        </IconButton>
                      }
                      sx={{
                        mt: 1,
                        borderColor: "#000",
                        borderStyle: "solid",
                        borderWidth: 1,
                      }}
                    >
                      <Typography className="buttonText">
                        Sign In With Google
                      </Typography>
                    </Button>

                    <Button
                      variant="outlined"
                      onClick={() => handleLogin()}
                      sx={{
                        mt: 1,
                        borderColor: "#000",
                        borderStyle: "solid",
                        borderWidth: 1,
                      }}
                      className="buttonText"
                      fullWidth
                      startIcon={
                        <IconButton>
                          <img
                            src={MicrosoftIcon}
                            alt="Microsoft Icon"
                            style={{
                              width: 24,
                              height: 24,
                            }}
                          />
                        </IconButton>
                      }
                    >
                      <Typography className="buttonText">
                        Sign In With Microsoft
                      </Typography>
                    </Button>
                  </>
                )}
                {customers.length <= 0 && !mfaIsRequired && (
                  <>
                    <Link
                      onClick={navigateForgot}
                      variant="body2"
                      sx={{ display: "block", mt: 2, mb: 4, cursor: "pointer" }}
                    >
                      Forgot password?
                    </Link>
                    <ResendEmail onClick={resendEmail}>
                      {resend == true && sending == false && (
                        <div> Resend activation email </div>
                      )}
                      {resend == true && sending == true && (
                        <div
                          style={{
                            color: "lightgray",
                            pointerEvents: "none",
                          }}
                        >
                          {" "}
                          Resend activation email{" "}
                        </div>
                      )}
                    </ResendEmail>
                  </>
                )}
              </>
            )}{" "}
          </Container>
        </Paper>
      </div>
    </React.Fragment>
  );
};

export default Login;