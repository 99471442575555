import { useState, useEffect } from "react";
import axios from "axios";

const useAxios = () => {
  
  const [getResponse, setGetResponse] = useState<any>([]);
  const [getUserSign, setGetUserSign] = useState<any>([]);
  const [getResponseLoading, setGetResponseLoading] = useState(false);
  const [getResponseError, setGetResponseError] = useState<any>();
  const API_URL = process.env.REACT_APP_HOST_API;

  const setUserId = () => {
    const docplaceData = localStorage.getItem("docplace");
    if (docplaceData) {
      return JSON.parse(docplaceData).userId || "";
    }
    return "";
  };

  const setCustomerId = () => {
    const docplaceData = localStorage.getItem("docplace");
    if (docplaceData) {
      return JSON.parse(docplaceData).customerId || "";
    }
    return "";
  };

  const fetchEsign = async (url: string) => {
    setGetResponseLoading(true);
    try {
      const response = await axios.get(`${API_URL}${url}`, {
        headers: {
          accept: "application/json",
          Customerid: setCustomerId(),
          Userid: setUserId(),
        },
      });
      setGetResponse(response.data.data);
    } catch (error) {
      if (error) {
        setGetResponseError(error);
      }
    } finally {
      fetchUserSign('/automation/api/v1.0/Signature/User/ValidateUser');
      setGetResponseLoading(false);
    }
  };

  const fetchUserSign = async (url: string) => {
    setGetResponseLoading(true);
    try {
      const response = await axios.get(`${API_URL}${url}`, {
        headers: {
          accept: "application/json",
          Customerid: setCustomerId(),
          Userid: setUserId(),
          'x-user-id': setUserId(),
        },
      });
      setGetUserSign(response.data.data);
    } catch (error) {
      if (error) {
        setGetResponseError(error);
      }
    } finally {
      setGetResponseLoading(false);
    }
  };


  return {
    fetchEsign,
    fetchUserSign,
    getUserSign,
    getResponse,
    getResponseLoading,
    getResponseError,
  };
};

export default useAxios;
