import React, { useEffect } from "react";
import { SearchFiltersView } from "./view/searchFiltersView";
import { Nb_SearchFiltersProps } from "./type/searchFilters.type";

export const Nb_SearchFilters = ({
  onClickSearch = () => {},
  ...props
}: Nb_SearchFiltersProps) => {
  const [isHiddenFilters, setIsHiddenFilters] = React.useState(
    props.isHiddenFilters,
  );
  const [isHidden, setIsHidden] = React.useState(props.hideList);
  const [inputValue, setInputValue] = React.useState("");

  useEffect(() => {
    setIsHiddenFilters(props.hideFilters);
  }, [props.hideFilters]);

  useEffect(() => {
    setIsHidden(props.hideList);
  }, [props.hideList]);

  const hideListFn = (shouldHide: boolean) => {
    setIsHidden(shouldHide);
  };

  const hiddenFilters = () => {
    setIsHiddenFilters((prev) => !prev);
  };

  const searchText = () => {
    onClickSearch(inputValue);
  };

  return (
    <SearchFiltersView
      {...props}
      isHidden={isHidden}
      setIsHidden={setIsHidden}
      hideListFn={hideListFn}
      isHiddenFilters={isHiddenFilters}
      setIsHiddenFilters={setIsHiddenFilters}
      hiddenFilters={hiddenFilters}
      searchText={searchText}
      setInputValue={setInputValue}
    />
  );
};
