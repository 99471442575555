import {
    SET_SELECTED_FILE,
    UPLOAD_FILE_REQUEST,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_FAILURE,
      SET_UPLOAD_RESPONSE
  } from "./actionTypes";
  
  export const setSelectedFile = (file) => ({
    type: SET_SELECTED_FILE,
    payload: file,
  });
  
  export const uploadFileRequest = (params) => ({
    type: UPLOAD_FILE_REQUEST,
    payload: params,
  });
  
  export const uploadFileSuccess = (response) => ({
    type: UPLOAD_FILE_SUCCESS,
    payload: response,
  });
  
  export const uploadFileFailure = (error) => ({
    type: UPLOAD_FILE_FAILURE,
    payload: error,
  });

  
export const setUploadResponse = (response) => ({
  type: SET_UPLOAD_RESPONSE,
  payload: response,
});