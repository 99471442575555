import styled from "styled-components";

export const DPwrapperTree = styled.section`
  width: 762px;
  height: 600px;
  background-color: #fff;
  border-radius: 7px;
  overflow: hidden;
`;

export const DPwrapperTreeHeader = styled.article`
  width: 762px;
  height: 44px;
  border-bottom: 1px solid #d9d9d9;
  font-size: 18px;
  font-weight: 700;
  color: #303030;
  padding: 10px;
`;

export const DPwrapperTreeBodyL = styled.section`
  width: 293px;
  height: 100%;
  border-right: 1px solid #d9d9d9;
  float: left;
  background-color: #f5f5f5;
`;

export const DPwrapperTreeBodyR = styled.section`
  width: 469px;
  float: right;
  height: 100%;
  padding: 15px;
`;

export const DPwrapperTreeBodyRTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

export const DPwrapperTreeBodyRUL = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  padding-top: 10px;
  height: 100%;
  overflow-y: auto;
`;

export const DPwrapperTreeBodyRLI = styled.li`
  width: 100%;
  height: 30px;
  position: relative;
`;
