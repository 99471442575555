import http from "./http-common";
import axios from "axios";

export const uploadTemplateV2File = (
  file
) => {
  let formData = new FormData();
  let url =
    process.env.REACT_APP_HOST_API +
    `/forms/api/v1/forms/${1231}/analyze`;

    console.log('file upload',file);
  formData.append("document", file);
  formData.append("use_ai", true);


  return http.post(url, formData, {
    headers: {
      "Content-Type":
        "multipart/form-data",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("docplace")).token,
      customerId: JSON.parse(localStorage.getItem("docplace")).customerId,
      userId: JSON.parse(localStorage.getItem("docplace")).userId,
    },
  });
};
