import {
  ContainerIcon,
  ContainerLogo,
  HeaderSidebar,
  LogoImg,
} from "../style/style";
import { IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export const HeaderSidebarComponent = (props: {
  bgColorHeader?: string;
  isHidden?: boolean;
  logoHeader?: string;
  hideDrawer?: () => void;
  iconHeader?: string;
}) => {
  return (
    <HeaderSidebar
      bgColorHeader={props.bgColorHeader}
      isHidden={props.isHidden}
    >
      <ContainerLogo isHidden={props.isHidden}>
        <LogoImg src={props.logoHeader} alt={"logo"} />
      </ContainerLogo>
      {!props.isHidden ? (
        <IconButton onClick={props.hideDrawer} disableRipple={true}>
          <ArrowBackIosIcon fontSize={"small"} sx={{ color: "white" }} />
        </IconButton>
      ) : (
        <ContainerIcon onClick={props.hideDrawer}>
          <LogoImg src={props.iconHeader} alt={"logo"} />
        </ContainerIcon>
      )}
    </HeaderSidebar>
  );
};
