import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	appBar: {
		// Ajustes adicionales si lo deseas
	},
	toolbar: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-between",
		padding: 0,
		marginTop: "24px",
	},
	leftSection: {
		display: "flex",
		alignItems: "center",
	},
	tabsContainer: {
		display: "flex",
		alignItems: "center",
		backgroundColor: "#fff",
		borderRadius: "10px",
	},
	title: {
		marginRight: theme.spacing(2),
	},
	tabs: {
		// marginRight: theme.spacing(4),
		// color: '#006ac6',
	},
	tab: {
		fontSize: "14px",
		textTransform: "none",
		fontWeight: 500,
	},
	rightSection: {
		display: "flex",
		alignItems: "center",
	},
	searchInput: {
		marginLeft: theme.spacing(2),
		backgroundColor: "#fff",
		width: "100%",
		height: "36px",
		textTransform: "none",
	},
	searchButton: {
		marginLeft: 4,
		textTransform: "none",
	},
	title: {
		color: "#006ac6",
		fontSize: "32px",
	},
	newTemplateButton: {
		marginLeft: "20px",
		textTransform: "none",
	},
}));

export default function HeaderViewer({ tabValue, onTabChange, onPublish }) {
	const classes = useStyles();


	const handleChangeTab = (event, newValue) => {
		onTabChange(newValue);
	};

	const { selectedFile } = useSelector((state) => ({
		responseUploadFile: state.fileReducer.uploadResponse,
		selectedFile: state.fileReducer.selectedFile,
	}));

	return (
		<div>
			<h2 className={classes.title}>Templates</h2>
			<header position="static" className={classes.appBar}>
				<div className={classes.toolbar}>
					{/* Sección izquierda: Título y Tabs */}
					<div className={classes.leftSection}>
						<div className={classes.tabsContainer}>
							<Tabs
								value={tabValue}
								onChange={handleChangeTab}
								className={classes.tabs}
								indicatorColor="primary"
								textColor="primary"
							>
								<Tab label="Template Builder" className={classes.tab} />
								<Tab label="Preview" className={classes.tab} />
							</Tabs>
						</div>

						<Button
							variant="contained"
							color="primary"
							className={classes.newTemplateButton}
						>
							Add Field
						</Button>
					</div>

					{/* Sección derecha: Botón y buscador */}
					<div className={classes.rightSection}>
						<Button
							variant="contained"
							color="primary"
							className={classes.searchButton}
							onClick={onPublish}
						>
							Publish
						</Button>
					</div>
				</div>
			</header>
		</div>
	);
}
