import React, { useEffect, useState } from "react";
import { NB_DocPlaceStampSign_props } from "../type/docplace_stamp_sign.types";
import { DPwrapper,DPTitle } from "../../style/docplace_sign_module.styles";
import useAxios from "../hook/hook";
import LoadingComponent from "../../../../components/loading";
import { useNavigate } from "react-router-dom";
import NbStampSignViewerView from "../view/docplace_stamp_sign_viewer.view";
import { de } from "date-fns/locale";
const NbStampSignView: React.FC<NB_DocPlaceStampSign_props> = ({
  ...props
}) => {
  const navigate = useNavigate();
  
  const { 
      callSign,
      activeSign,
      setCallSign,
      setActiveSign,
       } = useAxios();

  const back = () => {
    setActiveSign(false);
    setCallSign(false);
    navigate(`/docplaceSign`);
  };     

  const callSave = () => {
    setCallSign(true);
  };

  return (
    <DPwrapper>
      <LoadingComponent show={false} text={"Loading..."} />
      <DPTitle>{props.title}</DPTitle>
      <NbStampSignViewerView 
        back={back}
        setActiveSign={setActiveSign}
        activeSign={activeSign}
        callSign={callSign}  
        id={props.id}
        setCallSign={callSave}/>
    </DPwrapper>
  );
};

export default NbStampSignView;
