import React, { useEffect } from "react";
import { Wrapper } from "./style";
import CustomizedProgressBars from "../progressBar/index";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";

export function GridDocPlace(props) {
  const selectOption = (op, id, row) => {
    optionSelect({ action: op, id: id, row: row });
  };

  const optionSelect = (event) => {
    props.option(event);
  };

  const selectOption2 = (op, id, row) => {
    optionSelect2({ action: op, id: id, row: row });
  };

  const optionSelect2 = (event) => {
    props.option2(event);
  };

  useEffect(() => {
    if (props.data) {
      console.log(props.data);
    }
  }, [props.data]);

  const headCols = [
    {
      field: "name",
      flex: 1,
      headerClassName: "MuiTableHead-root",
      headerName: "Name",
      renderCell: (params) => {
        console.log(params);
        return (
          <div
            style={{
              width: "100%",
              lineHeight: "1.2",
              padding: "4px 0",
            }}
          >
            <div
              style={{
                width: "100%",
                whiteSpace: "normal",
                wordBreak: "break-word",
                fontWeight: 600,
              }}
            >
              {params.row.name}
            </div>
            <div
              style={{
                fontSize: "0.7rem",
                width: "100%",
                color: "#666",
                whiteSpace: "normal",
                marginTop: "4px",
                wordBreak: "break-word",
                lineHeight: "1.1",
              }}
            >
              To: {params.row.signersList}
            </div>
          </div>
        );
      },
    },
    {
      field: "lastUpdated",
      headerClassName: "MuiTableHead-root",
      headerName: "Last Updated",
      minWidth: 180,
      width: 180,
      disableColumnResizable: true,
    },
    {
      field: "status",
      headerClassName: "MuiTableHead-root",
      headerName: "Status",
      minWidth: 180,
      width: 180,
      disableColumnResizable: true,
      renderCell: (params) => {
        const signedSigners = params?.row?.signers.filter(
          (signer) => signer.have_signed === 1
        );
        const progress =
          (signedSigners.length / params?.row?.signers.length) * 100;
        return (
          <div
            style={{
              width: "100%",
              lineHeight: "1.2",
              padding: "4px 0",
            }}
          >
            <div
              style={{
                width: "100%",
                whiteSpace: "normal",
                wordBreak: "break-word",
                fontWeight: 600,
              }}
            >
              <CustomizedProgressBars progress={progress} />
            </div>
            <div
              style={{
                fontSize: "0.7rem",
                width: "100%",
                color: "#666",
                whiteSpace: "normal",
                marginTop: "4px",
                wordBreak: "break-word",
                lineHeight: "1.1",
              }}
            >
              {params.row.nextSigner && `Next: ${params.row.nextSigner}`}
            </div>
          </div>
        );
      },
    },
    {
      field: "actions",  // Fixed typo in field name
      headerName: "Action",
      sortable: false,
      headerClassName: "MuiTableHead-root",
      minWidth: 100,
      width: 100,
      disableColumnResizable: true,
      renderCell: (params) => (
        <>
          {params.row.isStamp === 'signed' ? (
            <Button onClick={() => selectOption2("edit", params.id, params)}>
            View
          </Button>
          ) : (
            <Button onClick={() => selectOption("edit", params.id, params)}>
              Sign
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <Wrapper>
      <DataGrid
        rows={props.data}
        columns={headCols}
        pageSize={10}
        density="compact"
        rowsPerPageOptions={[5]}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            minHeight: "30px !important",
            maxHeight: "30px !important",
          },
          "& .MuiDataGrid-columnHeader": {
            minHeight: "30px !important",
            maxHeight: "30px !important",
            lineHeight: "30px !important",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            lineHeight: "normal",
            whiteSpace: "normal",
            fontSize: "0.875rem",
          },
          "& .MuiDataGrid-virtualScroller": {
            marginTop: "30px !important",
          },
          "& .MuiDataGrid-row": {
            backgroundColor: "#fff !important",
            "&:nth-of-type(even)": {
              backgroundColor: "#f5f5f5 !important",
            },
            minHeight: "auto !important",
            maxHeight: "auto !important",
          },
          "& .MuiDataGrid-cell": {
            padding: "4px !important",
            fontSize: "14px",
            backgroundColor: "inherit !important",
          },
        }}
        sortingOrder={["asc", "desc"]}
        getRowClassName={() => `MuiTableCell-row`}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
      />
    </Wrapper>
  );
}

export default GridDocPlace;
