import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
  LinearProgress,
} from "@material-ui/core";
// Podrías usar `<CircularProgress>` en lugar de `<LinearProgress>` si prefieres

import CloudUploadIcon from "@material-ui/icons/CloudUpload";
// O importa algún ícono personalizado, según tu diseño
import LoaderGift from "../../../../assets/templateV2/loader-ai.gif";

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    minWidth: 300,
    padding: theme.spacing(2),
  },
  icon: {
    fontSize: 60,
    color: "#48D598", // color que quieras para tu ícono
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginTop: 4,
    width: "100%",
  },
  processContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  textProgress: {
    textAlign: 'left'
  }
}));

export default function ProcessingDialog({ open }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      // onClose={...}   // Opcional, si no quieres que el usuario lo cierre
      aria-labelledby="processing-dialog-title"
      PaperProps={{
        style: {
          // Esto ajusta la anchura y altura
          padding: 16,
          borderRadius: 8,
        },
      }}
    >
      <DialogTitle id="processing-dialog-title">
        Docplace AI is working on your PDF
      </DialogTitle>

      <DialogContent className={classes.container}>
        {/* Ícono en el centro */}
        <div>
          <img src={LoaderGift} alt="logo" />
        </div>

        {/* Texto de estado */}
        <div className={classes.processContainer}>
          <span className={classes.textProgress}>Detecting fields...</span>

          {/* Barra de progreso (lineal) */}
          <LinearProgress color="secondary" className={classes.progress} />
        </div>
      </DialogContent>
    </Dialog>
  );
}
