import styled from "styled-components";
import { NB_sidebar_props } from "../interface";

interface ExtendedSidebarProps extends NB_sidebar_props {
  isHidden?: boolean;
  bgColorHeader?: string;
  isHiddenList?: boolean;
  isActive?: boolean;
}

const filterProps = (prop: string) =>
  ![
    "isHidden",
    "bgColorHeader",
    "backgroundColor",
    "isHiddenList",
    "isActive",
  ].includes(prop);

export const Container = styled.section.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSidebarProps>`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 64px;
`;

export const HeaderSidebar = styled.article.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSidebarProps>`
  width: ${(props) => (props.isHidden ? "calc(72px + 1px)" : "240px")};
  height: 64px;
  left: 0;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: ${(props) => (props.isHidden ? "center" : "space-between")};
  background-color: ${(props) => props.bgColorHeader || "#FFFFFF"};
  transition:
    width 0.7s ease-in-out,
    justify-content 0.7s ease-in-out;
  box-sizing: inherit;
  border-right: 2px solid #d9d9d9;
`;

export const SidebarContainer = styled.article.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSidebarProps>`
  width: ${(props) => (props.isHidden ? "calc(72px + 1px)" : "240px")};
  height: calc(100vh - 64px);
  margin-top: 64px;
  left: 0;
  transition: width 0.7s ease-in-out;
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: ${(props) => props.backgroundColor || "#FFFFFF"};
  overflow: hidden;
  border-right: 2px solid #d9d9d9;
`;

export const ContainerLogo = styled.div.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSidebarProps>`
  width: ${(props) => (props.isHidden ? "0" : "183px")};
  height: 57px;
  overflow: hidden;
  transition: width 0.7s ease-in-out;
`;

export const ContainerIcon = styled.div.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSidebarProps>`
  width: 35px;
  height: 35px;
  cursor: pointer;
`;

export const ContainerIconList = styled.div.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSidebarProps>`
  width: 25px;
  height: 25px;
`;

export const LogoImg = styled.img.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSidebarProps>`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Ul = styled.ul.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSidebarProps>`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const LiItem = styled.li.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSidebarProps>`
  width: 100%;
  height: 48px;
  background-color: ${(props) => (props.isActive ? "#D8E6F6" : "none")};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(props) => (props.isHidden ? "0" : "8px")};
  cursor: pointer;
  &:hover {
    background-color: ${(props) => (props.isActive ? "none" : "#f5f5f5")};
  }
  transition: gap 0.7s ease-in-out;
`;

export const TitleIconHeaderContainer = styled.div.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSidebarProps>`
  width: ${(props) => (props.isHidden ? "0" : "185px")};
  display: flex;
  align-items: center;
  transition: width 0.7s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
`;

export const TitleItemHeader = styled.p.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSidebarProps>`
  width: 155px;
  margin: 0;
  padding: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #006ac6;
  white-space: nowrap;
  overflow: hidden;
`;

export const ChildListItemContainer = styled.div.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSidebarProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: ${(props) => (props.isHiddenList ? "0" : "auto")};
  opacity: ${(props) => (props.isHiddenList ? "0" : "1")};
  overflow: hidden;
  transition:
    max-height 0.7s ease-in-out,
    opacity 0.7s ease-in-out;
`;

export const TitleItem = styled.p.withConfig({
  shouldForwardProp: filterProps,
})<ExtendedSidebarProps>`
  width: ${(props) => (props.isHidden ? "0" : "185px")};
  margin: 0;
  padding: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #006ac6;
  transition: width 0.7s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
`;
