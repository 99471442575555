import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	Button,
	Typography,
	makeStyles,
} from "@material-ui/core";

// (Opcional) Si usas Dropzone u otro:
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { uploadTemplateV2File } from "../../../../services/templateV2Service";

const useStyles = makeStyles((theme) => ({
	dialogContent: {
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(2),
	},
	dropArea: {
		border: "2px dashed #ccc",
		borderRadius: 8,
		textAlign: "center",
		padding: theme.spacing(4),
		color: "#555",
		cursor: "pointer",
	},
	uploadIcon: {
		fontSize: 40,
		color: theme.palette.primary.main,
	},
    hiddenInput: {
        display: 'none', // para ocultar el input file
      },
}));

export default function CreateTemplateDialog({ open, onClose, onUpload }) {
	const classes = useStyles();

	const [templateName, setTemplateName] = React.useState("");
	const [templateDesc, setTemplateDesc] = React.useState("");
	const [selectedFile, setSelectedFile] = React.useState(null);

	const handleNameChange = (e) => {
		setTemplateName(e.target.value);
	};

	const handleDescChange = (e) => {
		setTemplateDesc(e.target.value);
	};

	const handleFileChange = (e) => {
        console.log(e.target);
		if (e.target.files && e.target.files.length > 0) {
			setSelectedFile(e.target.files[0]);
		}
	};

	const handleClickBrowse = () => {
		document.getElementById("upload-file-input").click();
	};

	const handleUploadClick = () => {
		// 1. Valida datos (por ejemplo, templateName no vacío).
		// 2. Envía la información al padre o haz la lógica de subida.
		// Ejemplo, llamando a la prop onUpload si existe:
		if (onUpload) {
			onUpload({
				name: templateName,
				description: templateDesc,
				file: selectedFile,
			});
		}
		// 3. Cierra el diálogo si gustas
		onClose();
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			maxWidth="sm"
			fullWidth
			aria-labelledby="create-template-dialog"
		>
			<DialogTitle id="create-template-dialog">Create New Template</DialogTitle>

			<DialogContent dividers className={classes.dialogContent}>
				<Typography variant="body2" color="textSecondary">
					Upload a PDF, and let our AI do the work. We'll automatically detect
					the fields, so you can quickly create your template.
				</Typography>

				<TextField
					label="Template Name"
					variant="outlined"
					fullWidth
					value={templateName}
					onChange={handleNameChange}
				/>

				<TextField
					label="Template Description"
					variant="outlined"
					fullWidth
					value={templateDesc}
					onChange={handleDescChange}
					multiline
					rows={2}
					placeholder="Description (optional)"
				/>

				{/* Zona de drop/drag (estilo manual) */}
				<div className={classes.dropArea} onClick={handleClickBrowse}>
					<CloudUploadIcon className={classes.uploadIcon} />
					<Typography variant="body1">
						{selectedFile
							? `File selected: ${selectedFile.name}`
							: "Drop a document or Click to browse"}
					</Typography>
				</div>
				<input
					id="upload-file-input"
					type="file"
					accept="application/pdf"
					className={classes.hiddenInput}
					onChange={handleFileChange}
				/>
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button
					onClick={handleUploadClick}
					color="primary"
					variant="contained"
					disabled={!templateName || !selectedFile} // Habilitado si hay nombre y archivo
				>
					Upload
				</Button>
			</DialogActions>
		</Dialog>
	);
}
