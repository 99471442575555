import { MenuItem, NB_HorizontalMenuProps } from "./types/horizontalMenu.type";
import { HorizontalMenuView } from "./view/jsxView";
import { useEffect, useState } from "react";

export const Nb_HorizontalMenu = ({
  onClickMenu = () => {},
  ...props
}: NB_HorizontalMenuProps) => {
  const [menu, setMenu] = useState(props.menus);

  useEffect(() => {
    setMenu(props.menus);
  }, [props.menus]);

  const onClickMenuFn = (item: MenuItem) => {
    const updatedMenu = menu?.map((menuItem) => {
      return {
        ...menuItem,
        active: menuItem.id === item.id,
      };
    });

    setMenu(updatedMenu);

    onClickMenu(item);
  };

  return (
    <HorizontalMenuView {...props} menu={menu} onClickMenuFn={onClickMenuFn} />
  );
};
