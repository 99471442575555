import styled from "styled-components";
import { colors } from "../../theme";

export const NotificationWrapper = styled("article")({
  width: "440px",
  height: "auto",
  backgroundColor: colors.white,
  borderRadius: "5px",
  border: "1px solid #ebebeb",
  boxShadow: "1px 2px 4px #c0c0c0",
  minHeight: "450px",
});

export const NotificationWrapperNotNews = styled("article")({
  width: "100%",
  height: "362px",
  backgroundColor: "#fff",
  alignItems: "center",
  display: "flex",
  padding: "0px 54px",
  color: "#757575",
  fontSize: "18px",
});

export const NotificationTitle = styled.article`
  width: 100%;
  font-size: 20px;
  color: #1e1e1e;
  height: 41px;
  border-bottom: 1px solid #dcdcdc;
  padding: 5px 0px 1px 12px;
  font-weight: 700;
`;

export const NotificationDate = styled.div`
  position: absolute;
  right: 7px;
  top: 10px;
  font-size: 10px;
  font-weight: 400;
`;

export const NotificationUL = styled.ul`
  padding: 0;
  margin: -1px 0 0 0;
  list-style: none;
  max-height: 450px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const NotificationLI = styled.li`
  padding: 12px;
  border-top: 1px solid #dcdcdc;
  cursor: pointer;
  position: relative;
  &.NoReadNotification {
    background-color: #ecf5fe;
    color: #006ac6;
  }
`;

export const NotificationLITitle = styled.div`
  font-weight: 600;
  font-size: 16px;
`;
export const NotificationLIDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #757575;
`;
