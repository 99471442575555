import {call, put, takeEvery} from "redux-saga/effects";
import {
  DOC_ATTRIBUTE_PUT,
  DOC_COMMENT_POST,
  DOC_DELETE_POST,
  DOC_DOWNLOAD_POST,
  DOC_MOVE_POST,
  DOC_RECOVER_POST,
  DOC_RENAME_POST,
  DOC_REPROCESS_POST,
  DOC_SHARE_POST,
  FOLDER_SHARE_POST,
  EMPTY_TRASH_BIN,
  FOLDER_DOWNLOAD_ZIP_POST,
  FOLDER_DOWNLOAD_ZIP_POST_CLEAR_DATA,
  FOLDER_NEW_POST,
} from "./actionTypes";
import {
  apiErrorDeleteDoc,
  apiErrorDocAttribute,
  apiErrorDocComment,
  apiErrorDocDownload,
  apiErrorEmptyTrashBin,
  apiErrorMoveDoc,
  apiErrorNewFolder,
  apiErrorRecoverDoc,
  apiErrorRenameDoc,
  apiErrorReprocessDoc,
  apiErrorShareDoc,
  apiErrorShareFolder,
  folderDownloadZipPostError,
  postDeleteDocSuccess,
  postDocCommentSuccess,
  postDocDownloadSuccess,
  postEmptyTrashBinSuccess,
  postFolderDownloadZipSuccess,
  postMoveDocSuccess,
  postNewFolderSuccess,
  postRecoverDocSuccess,
  postRenameDocSuccess,
  postReprocessDocSuccess,
  postShareDocSuccess,
  postShareFolderSuccess,
  putDocAttributeSuccess
} from "./actions";

import {
  postCommentDocAPI,
  postDeleteDocAPI,
  postDownloadDocAPI,
  postDownloadFolderZipAPI,
  postEmptyTrashbin,
  postMoveDocAPI,
  postNewFolderAPI,
  postRecoverDocAPI,
  postRenameDocAPI,
  postReprocessDocAPI,
  postSharedDocAPI,
  postSharedFolderAPI,
  putAttributeDocAPI,
} from "../../helpers/call_api";
import {toast} from "react-toastify";

function* postDocDownload({ payload: { download, navigate } }) {
  try {
    const response = yield call(
      postDownloadDocAPI,
      download.archiveId,
      download.fileName,
      download.type
    );
    toast.success("Document downloaded");
    yield put(postDocDownloadSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorDocDownload(error));
  }
}

function* postDocComment({ payload: { comment, navigate } }) {
  try {
    const response = yield call(postCommentDocAPI, comment.data, comment.id);
    toast.success("Comment was saved");
    yield put(postDocCommentSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorDocComment(error));
  }
}

function* putDocAttribute({ payload: { attribute, navigate } }) {
  try {
    const response = yield call(
      putAttributeDocAPI,
      attribute.data,
      attribute.id
    );
    toast.success("Attribute was updated");
    yield put(putDocAttributeSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorDocAttribute(error));
  }
}

function* postNewFolder({ payload: { newFolder, navigate } }) {
  try {
    const response = yield call(postNewFolderAPI, newFolder);
    toast.success("Folder was created");
    yield put(postNewFolderSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorNewFolder(error));
  }
}

function* postDeleteDoc({ payload: { deleteDoc, navigate } }) {
  try {
    const response = yield call(postDeleteDocAPI, deleteDoc);
    yield put(postDeleteDocSuccess(response));
    toast.success("Item was deleted");
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorDeleteDoc(error));
  }
}

 /**DOC-1441 empty trashBin */
 function* postEmptyTrash({ payload: { navigate } }) {
  try {
    const response = yield call(postEmptyTrashbin);
    yield put(postEmptyTrashBinSuccess(response));
    toast.success("Trash bin emptied");
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorEmptyTrashBin(error));
  }
}

function* postRenameDoc({ payload: { renameDoc, navigate } }) {
  try {
    const response = yield call(postRenameDocAPI, renameDoc.data, renameDoc.id);
    yield put(postRenameDocSuccess(response));
    toast.success("Item was renamed");
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorRenameDoc(error));
  }
}

function* postReprocessDoc({ payload: { reprocessDoc, navigate } }) {
  try {

    const response = yield call(postReprocessDocAPI, reprocessDoc);
    yield put(postReprocessDocSuccess(response));
    toast.success("Document was reprocessed");
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorReprocessDoc(error));
  }
}
function* postRecoverDoc({ payload: { recoverDoc, navigate } }) {
  try {

    const response = yield call(postRecoverDocAPI, recoverDoc);
    yield put(postRecoverDocSuccess(response));
    toast.success("Document was recovered");
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorRecoverDoc(error));
  }
}
function* postMoveDoc({ payload: { moveDoc, navigate } }) {
  try {
    const response = yield call(postMoveDocAPI, moveDoc);
    yield put(postMoveDocSuccess(response));
    toast.success("Item was moved");
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorMoveDoc(error));
  }
}

function* postShareDoc({ payload: { shareDoc, navigate } }) {
  try {
    const response = yield call(postSharedDocAPI, shareDoc.data, shareDoc.id);
    toast.success("Document was shared");
    yield put(postShareDocSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorShareDoc(error));
  }
}

function* postShareFolder({ payload: { shareDoc, navigate } }) {
  try {
    const response = yield call(postSharedFolderAPI, shareDoc.data, shareDoc.id);
    toast.success("Folder was shared");
    yield put(postShareFolderSuccess(response));
  } catch (error) {
    if (error.response.status) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(apiErrorShareFolder(error));
  }
}

function* postFolderDownloadZip({ payload: {name, data} }) {
  try {
    const response = yield call(postDownloadFolderZipAPI, name, data);
    toast.success("Folder downloaded");
    yield put(postFolderDownloadZipSuccess(response));
  } catch (error) {
    toast.error(error?.response?.data?.error?.messages[0].message);
    yield put(folderDownloadZipPostError(error));
  }
}
function* folderDownloadZipPostClearData() {
  try {
    yield put(folderDownloadZipPostClearData(true))
  } catch (error) {
    yield put(folderDownloadZipPostError(error))
  }
}

function* authSaga() {
  yield takeEvery(FOLDER_NEW_POST, postNewFolder);
  yield takeEvery(DOC_DELETE_POST, postDeleteDoc);
  yield takeEvery(DOC_RENAME_POST, postRenameDoc);
  yield takeEvery(DOC_MOVE_POST, postMoveDoc);
  yield takeEvery(DOC_SHARE_POST, postShareDoc);
  yield takeEvery(FOLDER_SHARE_POST, postShareFolder);
  yield takeEvery(DOC_ATTRIBUTE_PUT, putDocAttribute);
  yield takeEvery(DOC_COMMENT_POST, postDocComment);
  yield takeEvery(DOC_DOWNLOAD_POST, postDocDownload);
  yield takeEvery(DOC_REPROCESS_POST, postReprocessDoc);
  yield takeEvery(EMPTY_TRASH_BIN, postEmptyTrash);
  yield takeEvery(DOC_RECOVER_POST, postRecoverDoc);
  yield takeEvery(FOLDER_DOWNLOAD_ZIP_POST, postFolderDownloadZip);
  yield takeEvery(FOLDER_DOWNLOAD_ZIP_POST_CLEAR_DATA, folderDownloadZipPostClearData);
}

export default authSaga;
