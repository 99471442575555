import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NB_DocPlaceSignModule_props } from "../type/docplace_tree_file.types";
import useAxios from "./hook/hook";
import {
  DPwrapperTree,
  DPwrapperTreeHeader,
  DPwrapperTreeBodyR,
  DPwrapperTreeBodyL,
  DPwrapperTreeBodyRTitle,
  DPwrapperTreeBodyRLI,
  DPwrapperTreeBodyRUL,
} from "./style/docplace_tree_file.styles";
import { Nb_HorizontalMenu } from "../../../components/horizontalMenu/horizontalMenu";
import TreeItems from "./tree";
import { getTreeList } from "../../../store/tree/actions";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../../components/loading";
import closeSmallIcon from "../../../assets/svg/CloseSmallIcon.svg";
import fileIconTree from "../../../assets/svg/fileIconTree.svg";
import Button from "@mui/material/Button";

const NbTreeFile: React.FC<NB_DocPlaceSignModule_props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataSign, setDataSign] = useState([]);
  const [pageActive, setPageActive] = useState("1");
  const [parentArchiveId, setParentArchiveId] = useState("");
  const [mapPath, setMapPath] = useState<any>([]);
  const [filesSign, setFilesSign] = useState<any>([]);
  const { fetchTree, dataTree, setParentFolderId, treeLoading } = useAxios();

  useEffect(() => {
    fetchTree(
      "/automation/api/v1.0/Archives/TreeResource?hideReservedFolder=false&parentArchiveId=&direction="
    );
  }, []);

  const onClickTree = (item: any) => {
    if (item.type === "Folder") {
      setParentFolderId(item.value);
      fetchTree(
        `/automation/api/v1.0/Archives/TreeResource?hideReservedFolder=false&parentArchiveId=${item.value}&direction=`
      );
      let m = mapPath;
      m.push({
        name: item.label,
        id: item.value,
        parent: !item.label ? "" : item.parent ? item.parent.id : "",
        type: item.type,
      });
      setMapPath(m);
    } else {
      setFilesSign([...filesSign, { id: item.value, label: item.label }]);
    }
  };

  const onClickBackTree = (item: any) => {
    let newMap: any = [];
    let end = mapPath.length;
    let origin = mapPath[mapPath.length - 1].parent;
    mapPath.map((map: any, idx: any) => {
      if (map.id !== mapPath[end - 1].id) {
        newMap.push(map);
      }
    });
    setMapPath(newMap);
    setParentFolderId(
      newMap.length > 0 ? newMap[newMap.length - 1].id : origin
    );
    fetchTree(
      `/automation/api/v1.0/Archives/TreeResource?hideReservedFolder=false&parentArchiveId=${item.value}&direction=UP`
    );
  };

  const removeFile = (fileId: string) => {
    setFilesSign((prevFiles: { id: string; label: string }[]) =>
      prevFiles.filter((file) => file.id !== fileId)
    );
  };

  return (
    <DPwrapperTree>
      <LoadingComponent show={treeLoading} text={"Loading..."} />
      <DPwrapperTreeHeader>Select Files for Signing</DPwrapperTreeHeader>
      <DPwrapperTreeBodyL>
        <div style={{ width: "90%", height: "36px", margin: "12px 0 0 14px" }}>
          <Nb_HorizontalMenu
            menus={[{ id: "1", name: "File Manager", active: true, route: "" }]}
            onClickMenu={props.onClickMenu}
          />
        </div>
        <TreeItems
          click={onClickTree}
          backTree={onClickBackTree}
          tree={dataTree || []}
          treePrevius={mapPath}
          hideTrash={true}
        />
      </DPwrapperTreeBodyL>
      <DPwrapperTreeBodyR>
        <DPwrapperTreeBodyRTitle>Files Selected</DPwrapperTreeBodyRTitle>
        <DPwrapperTreeBodyRUL>
          {filesSign.map((file: any, idx: number) => {
            return (
              <DPwrapperTreeBodyRLI key={idx}>
                <img
                  src={fileIconTree}
                  style={{
                    left: "0px",
                    top: "-4px",
                    margin: "10px 12px 0px 0px",
                    position: "absolute",
                  }}
                  alt=""
                />
                <span
                  style={{
                    left: "20px",
                    top: "-4px",
                    margin: "6px 12px 0px 0px",
                    position: "absolute",
                  }}
                >
                  {file.label}
                </span>
                <img
                  onClick={() => removeFile(file.id)}
                  src={closeSmallIcon}
                  style={{
                    right: "0",
                    top: "-4px",
                    margin: "7px 12px 0px 0px",
                    position: "absolute",
                  }}
                  alt=""
                />
              </DPwrapperTreeBodyRLI>
            );
          })}
        </DPwrapperTreeBodyRUL>
        <div style={{ position: "absolute", bottom: "15px", right: "115px" }}>
          <Button
            onClick={props.onClickClose}
            size="small"
            color="primary"
            variant="outlined"
          >
            <span>Cancel</span>
          </Button>
        </div>
        <div style={{ position: "absolute", bottom: "15px", right: "10px" }}>
          <Button
            disabled={filesSign.length === 0}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => {
              props.onClickRequest?.(filesSign);
            }}
          >
            <span>Continue</span>
          </Button>
        </div>
      </DPwrapperTreeBodyR>
    </DPwrapperTree>
  );
};

export default NbTreeFile;
