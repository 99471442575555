import React from "react";
import { Nb_HorizontalMenu } from "../../../components/horizontalMenu/horizontalMenu";
import { NB_DocPlaceFormsModule_props } from "../type/docplace_forms_module.types";
import Button from "@mui/material/Button";
import {
  DPwrapper,
  DPTitle,
  DPheader,
  DPbody,
  DPfooter,
  DPHeaderFilter,
} from "../style/docplace_forms_module.styles";

const NbFormsModuleView: React.FC<NB_DocPlaceFormsModule_props> = ({
  ...props
}) => {
  return (
    <DPwrapper>
      <DPTitle>DocPlace Forms</DPTitle>
      <DPheader>
        <DPHeaderFilter>
          <Nb_HorizontalMenu
            menus={[
              { id: "1", name: "Inbox", active: true, route: "" },
              { id: "2", name: "Sent", active: false, route: "" },
            ]}
          />
        </DPHeaderFilter>
        <div style={{ paddingTop: "1px" }}>
          <Button
            onClick={() => {}}
            size="small"
            color="primary"
            variant="contained"
          >
            <span>New Template</span>
          </Button>
        </div>
      </DPheader>
      <DPbody>
        <DPfooter> footer </DPfooter>
      </DPbody>
    </DPwrapper>
  );
};

export default NbFormsModuleView;
