import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import PrivateRoute from "./routes/privateRoute";
import Login from "./DocPlace/LoginSAML/index";
import ExplorerDocPlace from "./DocPlace/Explorer/index";
import SearchPageDocPlace from "./DocPlace/search/index";
import DocumentTypeDocPlace from "./DocPlace/DocumentType/index";
import MasterTemplateDocPlace from "./DocPlace/MasterTemplate/index";
import DocumentTypeDetailDocPlace from "./DocPlace/DocumentType/detail";
import DocViewerDocPlace from "./DocPlace/DocViewer/index";
import InputMethodDocPlace from "./DocPlace/inputMethod/index";
import RolDocPlace from "./DocPlace/role/index";
import UserDocPlace from "./DocPlace/user/index";
import ResetPassword from "./DocPlace/newPassword/index";
import ForgotPassword from "./DocPlace/forgotPassword/index";
import DocumentsSentDocPlace from "./DocPlace/DocumentsSentForSignature/index";
import Signature from "./DocPlace/Signature/index";
import PlaceSignature from "./DocPlace/PlaceSignature/labeling";
import StampSignature from "./DocPlace/StampSignature/labeling";
import { ApprovaProcess } from "./DocPlace/ApprovalProcess/labeling";
import RegisterSteps from "./DocPlace/EsignatureRegister/index";
import MailBoxSignatures from "./DocPlace/MailBoxSignatures";
import DashboardSignatures from "./DocPlace/DashboardEsignature";
import { authAccess } from "./services/permissionsConfig";
import FormsInbox from "./DocPlace/FormsInbox";
import Templates from "./DocPlace/Templates";
import AssignedForms from "./DocPlace/AssignedForms";
import Shipping from "./DocPlace//Templates/components/Shipment/index";
import TrashBin from "./DocPlace/TrashBin";
import ComponentForms from "./DocPlace/Components/ComponentForms";
import FormTemplate from "./DocPlace/FormTemplate/labeling";
import DocplaceFormsModule from "./DocPlace/DocplaceForms_Module/docplace_forms_module.tsx";
import DocplaceSignModule from "./DocPlace/DocplaceSign_Module/docplace_sign_module.tsx";

import { FormViewer } from "./DocPlace/AssignedForms/formsViewer/labeling";
import { SeeCompletedForm } from "./DocPlace/AssignedForms/seeCompletedForm/labeling";
import { RequestedWorkflow } from "./DocPlace/workflow/requested";
import { EsignatureRequests } from "./DocPlace/EsignatureRequests";
import  NbRequestSign  from "./DocPlace/DocplaceSign_Module/stampSigner/docplace_stamp_sign.tsx";
import TemplatesV2 from "./DocPlace/TemplatesV2";
import TemplateBuilder from "./DocPlace/TemplateBuilder";

export default function App() {
  return (
    <Router>
      {authAccess().authAccess.length === 0 && <Navigate to="/login" />}
      <Fragment>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route exact path="/" element={<ExplorerDocPlace />} />
            {authAccess().authAccess.find((i) => i.name === "explorer") && (
              <Route path="/explorer">
                <Route path=":id" element={<ExplorerDocPlace />} />
                <Route path="" element={<ExplorerDocPlace />} />
              </Route>
            )}

            {/*  {authAccess().authAccess.find((i) => i.name === "dashboard") && (
              <Route exact path="/dashboard" element={<DashBoardDocPlace />} />
            )}
            */}
            {authAccess().authAccess.find((i) => i.name === "search") && (
              <Route exact path="/search" element={<SearchPageDocPlace />} />
            )}

            {authAccess().authAccess.find((i) => i.name === "inputMethod") && (
              <Route
                exact
                path="/inputMethod"
                element={<InputMethodDocPlace />}
              />
            )}

            {authAccess().authAccess.find((i) => i.name === "documentType") && (
              <Route
                exact
                path="/documentType"
                element={<DocumentTypeDocPlace />}
              />
            )}

            {authAccess().authAccess.find(
              (i) => i.name === "masterTemplate"
            ) && (
              <Route
                exact
                path="/masterTemplate"
                element={<MasterTemplateDocPlace />}
              />
            )}

            {authAccess().authAccess.find((i) => i.name === "docViewer") && (
              <Route
                exact
                path="/docViewer/:id/:isSignerRequests?"
                element={<DocViewerDocPlace />}
              />
            )}

            {authAccess().authAccess.find(
              (i) => i.name === "documentType/detail"
            ) && (
              <Route
                exact
                path="/documentType/detail/:id/:ocr/:newVersion"
                element={<DocumentTypeDetailDocPlace />}
              />
            )}

            {authAccess().authAccess.find((i) => i.name === "rol") && (
              <Route exact path="/rol" element={<RolDocPlace />} />
            )}

            {authAccess().authAccess.find((i) => i.name === "user") && (
              <Route exact path="/user" element={<UserDocPlace />} />
            )}

            {authAccess().authAccess.find(
              (i) => i.name === "eSignature" || i.name === "eSignatureE"
            ) && (
              <Route
                exact
                path="/eSignatureRequested"
                element={<DocumentsSentDocPlace />}
              />
            )}

            {authAccess().authAccess.find(
              (i) => i.name === "eSignature" || i.name === "eSignatureE"
            ) && (
              <Route
                exact
                path="/docplaceSign"
                element={<DocplaceSignModule />}
              />
            )}

            {authAccess().authAccess.find((i) => i.name === "signature") && (
              <Route exact path="/signature" element={<Signature />} />
            )}

            {authAccess().authAccess.find(
              (i) => i.name === "placeSignature"
            ) && (
              <Route
                exact
                path="/placeSignature/:id"
                element={<PlaceSignature />}
              />
            )}
            {authAccess().authAccess.find(
              (i) => i.name === "approvalProcess"
            ) && (
              <Route
                exact
                path="/approvalProcess/:id"
                element={<ApprovaProcess />}
              />
            )}
            {authAccess().authAccess.find(
              (i) => i.name === "stampSignature"
            ) && (
              <Route
                exact
                path="/stampSignature/:id/:isWorkFlow?"
                element={<StampSignature />}
              />
            )}

{authAccess().authAccess.find(
              (i) => i.name === "stampSignature"
            ) && (
              <Route
                exact
                path="/stampSign/:id"
                element={<NbRequestSign />}
              />
            )}



            {authAccess().authAccess.find(
              (i) => i.name === "esignatureRegister"
            ) && (
              <Route
                exact
                path="/esignatureRegister"
                element={<RegisterSteps />}
              />
            )}

            {authAccess().authAccess.find(
              (i) => i.name === "eSignatureInbox"
            ) && (
              <Route
                exact
                path="/eSignatureInbox"
                element={<MailBoxSignatures />}
              />
            )}

            {authAccess().authAccess.find(
              (i) => i.name === "DashboardSignature"
            ) && (
              <Route
                exact
                path="/DashboardSignature"
                element={<DashboardSignatures />}
              />
            )}
            {authAccess().authAccess.find((i) => i.name === "FormsInbox") && (
              <Route exact path="/forms-inbox" element={<FormsInbox />} />
            )}
            {authAccess().authAccess.find(
              (i) => i.name === "FormsTemplate"
            ) && (
              <Route
                exact
                path="/formsTemplate/:id"
                element={<FormTemplate />}
              />
            )}
            {authAccess().authAccess.find(
              (i) => i.name === "FormsTemplate"
            ) && (
              <Route
                exact
                path="/docplaceForms"
                element={<DocplaceFormsModule />}
              />
            )}
            {authAccess().authAccess.find((i) => i.name === "FormViewer") && (
              <Route
                exact
                path="/formsViewer/:id/:idRequest"
                element={<FormViewer />}
              />
            )}
            {authAccess().authAccess.find(
              (i) => i.name === "CompletedForm"
            ) && (
              <Route
                exact
                path="/completedForm/:id"
                element={<SeeCompletedForm />}
              />
            )}
            {authAccess().authAccess.find((i) => i.name === "Templates") && (
              <Route exact path="/Templates" element={<Templates />} />
            )}
            {authAccess().authAccess.find((i) => i.name === "Templates") && (
              <Route exact path="/templates-v2" element={<TemplatesV2 />} />
            )}
            {authAccess().authAccess.find((i) => i.name === "Templates") && (
              <Route exact path="/templates-v2/template-builder" element={<TemplateBuilder />} />
            )}
            {authAccess().authAccess.find((i) => i.name === "Templates") && (
              <Route exact path="/shipping-forms/:id" element={<Shipping />} />
            )}
            {authAccess().authAccess.find(
              (i) => i.name === "Assigned Forms"
            ) && (
              <Route exact path="/assigned-forms" element={<AssignedForms />} />
            )}
            {authAccess().authAccess.find((i) => i.name === "Trash-Bin") && (
              <Route exact path="/trash-bin" element={<TrashBin />} />
            )}
            {authAccess().authAccess.find(
              (i) => i.name === "EsignatureRequests"
            ) && (
              <Route
                exact
                path="/Esignature-requests"
                element={<EsignatureRequests />}
              />
            )}
            {authAccess().authAccess.find(
              (i) => i.name === "requested-process"
            ) && (
              <Route
                exact
                path="/requested-process"
                element={<RequestedWorkflow />}
              />
            )}
            {authAccess().authAccess.find(
              (i) => i.name === "forms-components"
            ) && (
              <Route
                exact
                path="/forms-components"
                element={<ComponentForms />}
              />
            )}
          </Route>

          <Route exact path="/login" element={<Login />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
      </Fragment>
    </Router>
  );
}
