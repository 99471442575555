//LOGIN
export const POST_LOGIN = "/sso/Api/Login";
export const FORGET_PASSWORD = "/billing/Api/V1/Public/SendResetPasswordLink"; //forgot password
export const RESET_PASSWORD = "/billing/Api/V1/Public/ResetPasswordHash"; //reset password with hash
export const HASH_VALIDATE = "/billing/Api/V1/Public/ValidateHashResetPassword"; //reset password with hash validation
export const GET_USER = "security/api/v1.0/SystemUser/All";
export const GET_CUSTOMER = "security/api/v1.0/SystemUser/GetCustomers";
export const GET_BILLING_CUSTOMERS_NOT_FULL =
  "security/api/v1.0/SystemUser/GetBillingCustomers?fullInfo=false";
export const GET_USER2 = "security/api/v1.0/SystemUser";
export const GET_ROLE = "security/api/v1.0/SystemRole/All";
export const CHANGE_PASSWORD = "Api/V1/Users/SendResetPasswordLink";

export const USER_ROL = "automation/api/v1.0/SystemUserRoles";

//Rols
export const ROLS = "automation/api/v1.0/ArchiveRole";

//Input Method
export const GET_INPUT_METHOD =
  "automation/api/v1.0/InputMethods/Search?page=1&pageSize=100";
//export const GET_INPUT_METHOD = "automation/api/v1.0/InputMethods/All";
export const POST_INPUT_METHOD = "automation/api/v1.0/InputMethods";
export const POST_INPUT_METHOD_SEARCH =
  "/automation/api/v1.0/InputMethods/Search?page=1&pageSize=100";
//DocType
export const GET_DOCTYPE = "automation/api/v1.0/Doctype/All";
export const GET_DOCTYPE1 = "automation/api/v1.0/Doctype/json/All";
export const POST_DOCTYPE_HEADER =
  "automation/api/v1.0/Doctype/CreateHeaderDoctype";
export const DELETE_DOCTYPE_HEADER = "automation/api/v1.0/Doctype";
export const UPDATE_DOCTYPE_HEADER = "automation/api/v2/Doctype";
//Tree
export const GET_TREE = "automation/api/v1.0/Archives/TreeResource";
//Dashboard
export const GET_REVIEWAL = "automation/api/v1.0/Documents/DocumentsInReviewal";
export const GET_DOC_PERCENTAGE =
  "automation/api/v1.0/Documents/DocumentsCountPerMonth";
export const GET_DOC_SHARED = "automation/api/v1.0/Documents/DocumentsShared";
export const GET_DOC_PD =
  "automation/api/v1.0/Documents/DocumentsPercentageDistribution";
//Document
export const GET_DETAIL_FOLDER =
  "automation/api/v1.0/Documents/ByDetailFolderResource";
export const GET_SEARCH_ARCHIVES =
  "automation/api/v1.0/AdvancedSearch/SearchArchives";
export const GET_DOCUMENT_PAGES = "automation/api/v1.0/Documents/RequestPages";
export const GET_DOCUMENT_PERMISSIONS =
  "automation/api/v1.0/Archives/ArchivePermissions";

export const GET_DOCUMENT_VERSIONS = "automation/api/v1.0/DocumentsEditVersion";

export const SET_DOCUMENT_VERSION =
  "automation/api/v1.0/DocumentsEditVersion/Restore";

//Explorer
export const POST_NEW_FOLDER = "automation/api/v1.0/Archives";
export const POST_DELETE_DOC = "automation/api/v1.0/Archives/Delete";
export const POST_REPROCESS_DOC = "automation/api/v1.0/Documents/ChangeDoctype";
export const POST_RENAME_DOC = "automation/api/v1.0/Archives/Rename";
export const POST_RECOVER_DOC = "automation/api/v1.0/Archives/Recover";
export const POST_DOWNLOAD_DOC_Zip =
  "automation/api/v1.0/Documents/DownloadZip";

export const POST_MOVE_DOC = "automation/api/v1.0/Archives/Move";
export const POST_SHARED_DOC = "automation/api/v1.0/Documents/Share";
export const POST_SHARED_FOLDER = "automation/api/v1.0/Documents/ShareFolder";

export const PUT_ATTRIBUTE_DOC = "automation/api/v1.0/Documents/Content";
export const POST_COMMENT_DOC = "automation/api/v1.0/Documents/AddComment";
export const POST_DOWNLOAD_DOC = "automation/api/v1.0/Archives";
export const POST_EMPTY_TRASH_BIN =
  "automation/api/v1.0/Archives/EmptyTrashBin";
//Search
export const SEARCH_DOCUMENT_DOC =
  "automation/api/v1.0/AdvancedSearch/SearchDocuments";
//Search form
export const SEARCH_FORM_DOC = "automation/api/v1.0/Forms/";

//Document Type
export const POST_DOCUMENT_TYPE =
  "/automation/api/v1.0/Doctype/CreateHeaderDoctype";
export const POST_DOCUMENT_TYPE_TEMPLATE =
  "/automation/api/v1.0/Doctype/GetSampleFiles";
export const GET_DOCUMENT_TYPE = "/automation/api/v1.0/Doctype/json/All";

/**Register esignature user */
export const REGISTER_USER_ESIGNATURE =
  "security/api/v1.0/Esignature/ActiveEsignature";
/**END esignature user */

// Require Signature for the document
export const LIST_REQUIRE_SIGNATURE_DOCUMENT_API =
  "automation/api/v1.0/RequiredEsignature/DocumentSigners";
export const VALIDATE_USER_SIGNATURE =
  "automation/api/v1.0/Signature/User/ValidateUser";
export const GET_REQUIRED_SIGNATURE_API =
  "automation/api/v1.0/RequiredEsignature/Documents";
export const RESEND_SIGNATURE_REQUEST_EMAIL =
  "automation/api/v1/RequiredEsignature/SendEmailRequest";
export const E_SIGNATURE_REQUESTS =
  "automation/api/v1.0/RequiredEsignature/DocumentsCustomer";
//END

//AllFormsRequested
export const GET_ALL_FORMS_REQUESTED_API =
  "automation/api/v1.0/Forms/GetAllRequestDocumentUser";
//END AllFormsRequested

//_AllWorkFlow_//
//Get_Attachment
export const GET_ALL_DOCUMENT_ATTACHMENT_API =
  "automation/api/v1.0/DocumentsAttach/All";
export const DELETE_DOCUMENT_ATTACHMENT_API =
  "automation/api/v1.0/DocumentsAttach";
//Get_Approval_Process
export const GET_REQUIRED_WORK_FLOW_PROCESS =
  "automation/api/v1.0/RequiredWorkflowProcessAttend/Documents";
//Get_Metadata
export const GET_DOCUMENT_METADATA =
  "automation/api/v1.0/DocumentsContentMeta/All";
export const POST_DOCUMENT_METADATA =
  "automation/api/v1.0/DocumentsContentMeta/Add";

export const DELETE_METADATA_API = "automation/api/v1.0/DocumentsContentMeta/";
