import React, { useEffect, useState } from "react";
import { NB_DocPlaceRequestSign_props } from "./type/docplace_request_sign.types";
import NbRequestSignView from "./view/docplace_request_sign.view";
import { useDispatch, useSelector } from "react-redux";

const NbRequestSign: React.FC<NB_DocPlaceRequestSign_props & {setShowViewRequest:(id: any) => void }> = (props) => {
  const dispatch = useDispatch();
  const [dataSign, setDataSign] = useState([]);
  const [pageActive, setPageActive] = useState("1");

  useEffect(() => {}, []);

  const callSetShowOrder = (e: any) => {
    props.setShowViewRequest(e);
  };



  return (
    <NbRequestSignView
      setShowViewRequest={callSetShowOrder}
      title="Request eSignature"
      listDocuments={props.listDocuments}
    />
  );
};

export default NbRequestSign;
