import React, { useMemo } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Box,
	IconButton,
	TextField,
	Typography,
} from "@material-ui/core";
// En MUI v5: import from '@mui/material'
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";

export default function PublishDialog({
	open,
	onClose,
	onPublish,
	recipients,
	setRecipients,
}) {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	const handleRemove = (index) => {
		const updated = [...recipients];
		updated.splice(index, 1);
		setRecipients(updated);
	};

	const handleReorder = (index, direction) => {
		const updated = [...recipients];
		if (direction === "up" && index > 0) {
			[updated[index - 1], updated[index]] = [
				updated[index],
				updated[index - 1],
			];
		}
		if (direction === "down" && index < updated.length - 1) {
			[updated[index + 1], updated[index]] = [
				updated[index],
				updated[index + 1],
			];
		}
		setRecipients(updated);
	};

	const handleAddRecipient = () => {
		const updated = [...recipients, { name: "", email: "", order: recipients.length + 1 }];
		setRecipients(updated);
	};

	const handleChangeName = (index, newName) => {
		const updated = [...recipients];
		updated[index].name = newName;
		setRecipients(updated);
	};
	const handleChangeEmail = (index, newEmail) => {
		const updated = [...recipients];
		updated[index].email = newEmail;
		setRecipients(updated);
	};
    
	const isFormValid = useMemo(() => {
		return (
		  recipients.length > 0 &&
		  recipients.every(
			(r) =>
			  r.name.trim() !== "" &&
			  emailRegex.test(r.email.trim())
		  )
		);
	  }, [recipients]);

	const handlePublishClick = () => {
		onPublish();
	};

	return (
		<Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
			<DialogTitle>Publish</DialogTitle>

			<DialogContent dividers>
				<Typography variant="body2" gutterBottom>
					Add the information of the recipients. You can arrange the order in
					which the forms must be collected. When all recipients have been
					added, click on the Publish button.
				</Typography>

				{/* Listado de recipients */}
				<Box mt={2}>
					{recipients.map((r, index) => (
						<Box
							key={index}
							display="flex"
							alignItems="center"
							justifyContent={"flex-end"}
							mb={2}
						>
							<Box
								width={30}
								textAlign="center"
								mr={1}
								border={1}
								borderColor={"#D9D9D9"}
                                marginTop={2}
							>
								<Typography variant="subtitle1">{index + 1}</Typography>
							</Box>

							<Box
								flex={1}
								mr={1}
								flexDirection={"row"}
								display={"flex"}
								gridGap={16}
							>
								<div style={{ flex: 1 }}>
									<Typography
										variant="subtitle2"
										sx={{ fontWeight: "bold", mb: 1 }}
									>
										Name
									</Typography>
									<TextField
										variant="outlined"
										size="small"
										fullWidth
										value={r.name}
										onChange={(e) => handleChangeName(index, e.target.value)}
										style={{ marginBottom: 8 }}
									/>
								</div>
								<div style={{ flex: 1 }}>
									<Typography
										variant="subtitle2"
										sx={{ fontWeight: "bold", mb: 1 }}
									>
										Email
									</Typography>
									<TextField
										variant="outlined"
										size="small"
										fullWidth
										value={r.email}
										onChange={(e) => handleChangeEmail(index, e.target.value)}
									/>
								</div>
							</Box>

							{/* Botón de eliminar */}
							<IconButton size="medium" style={{
                                marginTop: 8,
                            }} onClick={() => handleRemove(index)}>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						</Box>
					))}
				</Box>

				<Button onClick={handleAddRecipient} variant="outlined" color="primary">
					Add Recipient
				</Button>
			</DialogContent>

			<DialogActions>
				<Button
					onClick={onClose}
					color="default"
					style={{
						textTransform: "none",
					}}
				>
					Cancel
				</Button>
				<Button
					onClick={handlePublishClick}
					color="primary"
					variant="contained"
					style={{
						textTransform: "none",
					}}
					disabled={!isFormValid} // Deshabilita si no es válido
				>
					Publish
				</Button>
			</DialogActions>
		</Dialog>
	);
}
