import React, { useEffect, useState } from "react";
import PdfViewerWithFields from "./components/PdfViewerWithFields/PdfViewerWithFields";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../components/theme";
import { ThemeProvider } from "@material-ui/core/styles";

import HeaderViewer from "./components/HeaderViewer/HeaderViewer";
import { createMuiTheme } from "@material-ui/core/styles";
import FieldsDnDList from "./components/FieldsDnList/FieldsDnList";
import PublishDialog from "./components/PublishDialog/PublishDialog";
import Loading from "../../components/loading";
import { useNavigate } from "react-router-dom";
import { uploadTemplate } from "../../services/FileUploadService";

const theme = createMuiTheme({
	palette: {
		primary: {
			main: colors.blue,
		},
		secondary: {
			main: "#dc004e",
		},
	},
	typography: {
		fontFamily: ["Roboto", "Helvetica", "sans-serif"].join(","),
	},
});

const useStyles = makeStyles((theme) => ({
	templateBuilderContainer: {
		marginTop: 24,
		display: "flex",
		justifyContent: "space-between",
		gap: 20,
	},
	pdfViewerContainer: {
		width: "60%",
		height: "100vh",
		backgroundColor: "#f0f0f0",
	},
	fieldsPreviewContainer: {
		width: "40%",
		backgroundColor: "#ffffff",
		overflowY: "auto",
		paddingTop: 16,
		paddingLeft: 58,
		paddingRight: 58,
		borderRadius: 8,
		maxHeight: "100vh",
	},
	fieldsPreviewTitle: {
		fontSize: 32,
		fontWeight: "bold",
		marginBottom: 16,
	},
}));
export default function TemplateBuilder() {
	const [tabValue, setTabValue] = useState(0);
	const classes = useStyles();
	const [fields, setFields] = useState([]);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const { responseUploadFile, selectedFile } = useSelector((state) => ({
		responseUploadFile: state.fileReducer.uploadResponse,
		selectedFile: state.fileReducer.selectedFile,
	}));
	console.log("responseUploadFile", responseUploadFile);
	console.log("fileSelected", selectedFile);
	useEffect(() => {
		if (responseUploadFile?.fields) {
			setFields(responseUploadFile.fields);
		}
	}, [responseUploadFile]);

	const onTabChange = (newValue) => {
		setTabValue(newValue);
	};


	const handleDragEnd = (result) => {
		const { source, destination } = result;
		if (!destination || destination.index === source.index) {
			return;
		}
		const updated = Array.from(fields);
		const [moved] = updated.splice(source.index, 1);
		updated.splice(destination.index, 0, moved);
		setFields(updated);
	};

	const handleRemoveField = (index) => {
		const updated = [...fields];
		updated.splice(index, 1);
		setFields(updated);
	};

	const publishTemplate = async () => {
		setLoading(true);
		try {
			const response = await uploadTemplate(
				selectedFile,
				null,
				null,
				null,
				false
			);
			console.log("Response publishTemplate", response);
			if (response.status === 200) {
				console.log("Template published successfully:", response.data);
				navigate("/templates-v2");
			} else {
				console.error("Error publishing template:", response);
			}
		} catch (error) {
			console.error("Error publishing template:", error);
		} finally {
			setLoading(false);
		}
	};

	const pdfUrl = URL.createObjectURL(selectedFile);
	return (
		<div>
			<ThemeProvider theme={theme}>
				<HeaderViewer
					tabValue={tabValue}
					onTabChange={onTabChange}
					onPublish={publishTemplate}
				/>
				<div className={classes.templateBuilderContainer}>
					<div
						className={classes.pdfViewerContainer}
						style={{ width: tabValue === 1 ? "60%" : "100%" }}
					>
						{pdfUrl && responseUploadFile?.fields && (
							<PdfViewerWithFields
								pdfUrl={responseUploadFile.highlighted_pdf_url}
								fields={[responseUploadFile.fields]}
								pageDimensions={responseUploadFile.pages}
							/>
						)}
					</div>

					{tabValue === 1 && (
						<div className={classes.fieldsPreviewContainer}>
							<h2 className={classes.fieldsPreviewTitle}>Preview Fields</h2>
							<FieldsDnDList
								fields={fields}
								onFieldsChange={setFields}
								onDragEnd={handleDragEnd}
								onRemoveField={handleRemoveField}
							/>
						</div>
					)}
				</div>
				<Loading show={loading} text={"Processing..."} />
			</ThemeProvider>
		</div>
	);
}
