import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  paginationContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  pageButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  pageButton: {
    margin: theme.spacing(0, 0.5),
  },
}));

export default function Pagination({
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
}) {
  const classes = useStyles();
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const firstItemNumber = (currentPage - 1) * itemsPerPage + 1;
  const lastItemNumber = Math.min(currentPage * itemsPerPage, totalItems);

  const handlePrev = () => {
    if (currentPage > 1) onPageChange(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) onPageChange(currentPage + 1);
  };

  return (
    <div className={classes.paginationContainer}>
      <Typography>
        {firstItemNumber} - {lastItemNumber} de {totalItems}
      </Typography>
      <div className={classes.pageButtons}>
        <Button
          variant="outlined"
          size="small"
          onClick={handlePrev}
          disabled={currentPage === 1}
          className={classes.pageButton}
        >
          &lt;
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={handleNext}
          disabled={currentPage === totalPages}
          className={classes.pageButton}
        >
          &gt;
        </Button>
      </div>
    </div>
  );
}
