import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TemplateCard from "./../TemplateCard/TemplateCard";
import Pagination from "./../Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import PublishDialog from "../../../TemplateBuilder/components/PublishDialog/PublishDialog";
import { changeStatus, publishForm } from "../../../../services/form";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: "#fff",
		marginTop: "16px",
		padding: "14px 16px",
		height: "100%",
		flex: 1,
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	},
}));

export default function TemplateGrid({ setLoading, reloadTemplates }) {
	const classes = useStyles();

	const [templates, setTemplates] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [templatesPerPage] = useState(10);
	const [listDocuments, setListDocuments] = useState([]);
	const [listDocumentsTotal, setListDocumentsTotal] = useState([]);
	const [openPublish, setOpenPublish] = useState(false);
	const [selectedTemplate, setSelectedTemplate] = useState(null);

	const [recipients, setRecipients] = useState([
		{ name: "", email: "", order: 1 },
	]);

	const { responseSearchForm, errorSearchForm, loading } = useSelector(
		(state) => ({
			responseSearchForm: state.Search.responseSearchForm,
			errorSearchForm: state.Search.errorSearchForm,
			loading: state.Search.loadingSearchForm,
		})
	);

	useEffect(() => {
		if (responseSearchForm?.data) {
			console.log("responseSearchForm", responseSearchForm);
			setListDocuments(responseSearchForm.data);
			setListDocumentsTotal(responseSearchForm.header.totalRecords);
		}
	}, [responseSearchForm]);

	useEffect(() => {
		const mockTemplates = Array.from({ length: 100 }).map((_, i) => ({
			id: i + 1,
			name: `Template ${i + 1}`,
		}));
		setTemplates(mockTemplates);
	}, []);

	const indexOfLastTemplate = currentPage * templatesPerPage;
	const indexOfFirstTemplate = indexOfLastTemplate - templatesPerPage;
	const currentTemplates = templates.slice(
		indexOfFirstTemplate,
		indexOfLastTemplate
	);

	const handleOpenPublish = () => {
		setOpenPublish(true);
	};
	const handleClosePublish = () => {
		setOpenPublish(false);
	};

	const handleSend = () => {
		// Acciones para “publicar” => por ejemplo, mandar recipients al backend
		console.log("Publicando con recipients:", recipients);
		console.log("selectedTemplate:", selectedTemplate);
		updateRecipients(selectedTemplate.templateName, selectedTemplate.id);
	};

	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};

	const handleClickMenuItem = (menu, template) => {
		setSelectedTemplate(template);
		switch (menu) {
			case "Shipping":
				break;
			case "Send":
				// Handle delete action
				setOpenPublish(true);
				break;
			case "EditTemplate":
				break;

			case "Delete":
				// Handle delete action
				handleDeleteTemplate(template.id);

				break;
			default:
				break;
		}
	};

	const updateRecipients = async (documentName, documentId) => {
		//sfd
		// if (false == true) {
		setOpenPublish(false);
		let signers = recipients.map((item) => ({
			name: item.name,
			email: item.email,
			order: item.order.toString(),
		}));

		let body = {
			document_name: documentName,
			form_template_id: documentId,
			user_requested: signers,
		};
		console.log("body", body);
		setLoading(true);
		try {
			const response = await publishForm(body, (event) => {});
			console.log(response);

			toast.success("The form has been published successfully.");
		} catch (error) {
			toast.error("An error has occurred. Please try again later.");
		} finally {
			setLoading(false);
			setOpenPublish(false);
		}
		// } else {
		// 	toast.error(
		// 		"Please check the values. The email must be valid and the name should contain more at least 3 characters."
		// 	);
		// }
	};

	const handleDeleteTemplate = (id) => {
		console.log("Deleting template with ID:", id);
		Swal.fire({
			title: "Are you sure?",
			text: "You are about to delete this template. This action cannot be undone.",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#d33",
			cancelButtonColor: "#3085d6",
			confirmButtonText: "Delete",
			cancelButtonText: "Cancel",
		}).then((result) => {
			if (result.isConfirmed) {
				// Perform the delete operation here
				// You can call an API or update the state accordingly
				// Example: deleteTemplate(templateId)
				setLoading(true);
				changeStatus(id, 1, (event) => {})
					.then((response) => {
						console.log('response delete',response);
						Swal.fire("Deleted!", "The template has been deleted.", "success");
						reloadTemplates(true);
					})
					.catch((error) => {
						toast.error("An error has occurred. Please try again later.");
					})
					.finally(() => {
						setLoading(false);
					});
			}
		});
	};

	return (
		<div className={classes.container}>
			<Grid container spacing={6}>
				{listDocuments.map((template) => (
					<Grid key={template.id} item xs={12} sm={6} md={3} lg={2}>
						<TemplateCard
							template={template}
							onClickMenu={handleClickMenuItem}
						/>
					</Grid>
				))}
			</Grid>

			<Pagination
				currentPage={currentPage}
				itemsPerPage={templatesPerPage}
				totalItems={templates.length}
				onPageChange={handlePageChange}
			/>
			<PublishDialog
				open={openPublish}
				onClose={handleClosePublish}
				recipients={recipients}
				setRecipients={setRecipients}
				onPublish={handleSend}
			/>
		</div>
	);
}
