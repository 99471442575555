import React from "react";
import { NB_notificationIcon_props } from "./type/notificationIcon.types";
import NbNotificationIconView from "./view/notificationIcon.view";

const NbNotificationIcon: React.FC<NB_notificationIcon_props> = (props) => {
  return (
    <NbNotificationIconView
      title={props.title}
      list={props.list}
      haveNotification={props.haveNotification}
      onClick={props.onClick}
      onFocus={props.onFocus}
    />
  );
};

export default NbNotificationIcon;
